import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import ClipLoader from "react-spinners/ClipLoader";
import { ExternalLink, Key, Users, Star, Calendar, FileText } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClock, faTrash, faExclamation, faCheck, faPen, faShield } from '@fortawesome/free-solid-svg-icons';
import CommunityHikeModal from './CommunityHikeModal'; // Import the hikes modal
import TrailNotesModal from './TrailNotesModal'; // Import the trail notes modal
import StrandedAndShakenModal from './StrandedAndShakenModal';// Import the stranded  modal

const AccessDeniedPopup = ({ onClose, product }) => {
  const isMemberAccess = product?.memberOnly;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
      }}
    >
      <div className="card" style={{
        maxWidth: '400px',
        margin: '20px',
        borderRadius: '12px',
        boxShadow: '0 10px 25px rgba(0,0,0,0.1)'
      }}>
        <div className="card-body p-4">
          <div className="d-flex align-items-center mb-3">
            {isMemberAccess ? (
              <Users size={32} color="#22C55E" className="me-3" />
            ) : (
              <Key size={32} color="#4A90E2" className="me-3" />
            )}
            <h2 className="card-title mb-0" style={{ color: '#333' }}>
              {isMemberAccess ? 'Members Only' : 'Paid Resource'}
            </h2>
          </div>
          <p className="card-text text-muted mb-4">
            {isMemberAccess
              ? "Please become a member to access this resource."
              : "Please purchase the workshop to access it."}
          </p>
          <div className="d-flex justify-content-between">
            <button
              onClick={onClose}
              className="btn btn-outline-secondary me-2"
              style={{ flex: 1 }}
            >
              Cancel
            </button>
            <a
              href={isMemberAccess ? "https://manav.in/join" : "https://manav.in/workshop"}
              className="btn btn-primary"
              style={{
                flex: 1,
                backgroundColor: isMemberAccess ? '#22C55E' : '#4A90E2',
                borderColor: isMemberAccess ? '#22C55E' : '#4A90E2',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem'
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {isMemberAccess ? 'Become Member' : 'Buy here'} <ExternalLink size={16} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductSelection = () => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['userId']);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [products, setProducts] = useState([]);
  const [showAccessDenied, setShowAccessDenied] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showHikesModal, setShowHikesModal] = useState(false); // State for hikes modal
  const [showNotesModal, setShowNotesModal] = useState(false); // State for trail notes modal
  const [showStrandedModal, setshowStrandedModal] = useState(false);
  const [expandedCards, setExpandedCards] = useState({});
  const db = getFirestore();

  const toggleCardExpansion = (e, productId) => {
    e.stopPropagation(); // Prevent card click event
    setExpandedCards(prev => ({
      ...prev,
      [productId]: !prev[productId]
    }));
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsRef = collection(db, "Products");
        const q = query(productsRef, where("isDeleted", "==", false));
        const querySnapshot = await getDocs(q);
        const fetchedProducts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
          // Sort products by serialNo if exists, otherwise keep original order
          .sort((a, b) => {
            if (a.serialNo !== undefined && b.serialNo !== undefined) {
              return a.serialNo - b.serialNo;
            }
            return 0;
          });
        setProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    const fetchData = async () => {
      setLoading(true);
      try {
        const userIdFromLocalStorage = localStorage.getItem('userId');
        const userIdFromCookies = cookies.userId;
        const userId = userIdFromLocalStorage || userIdFromCookies;

        if (userId) {
          const docRef = doc(db, "UserTable", userId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setUserData(docSnap.data());
          } else {
            handleLogout();
          }
        } else {
          navigate('/login');
        }

        await fetchProducts();
      } catch (error) {
        console.error("Error fetching user data:", error);
        handleLogout();
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [cookies, navigate, db]);

  const handleLogout = () => {
    removeCookie("userId", { path: "/" });
    localStorage.removeItem("userId");
    sessionStorage.clear();
    localStorage.removeItem("accessToken");
    navigate("/login", { replace: true });
  };

  const handleProfileClick = () => {
    navigate("../ProfileForm");
  };

  const handleProductSelect = (product) => {
    // Check if this is the community hikes product
    if (product.title === 'Community Hikes') {
      // Check if it's member-only and if user is a member
      if (product.memberOnly && !userData?.isMember) {
        setSelectedProduct(product);
        setShowAccessDenied(true);
      } else {
        // Show the upcoming hikes modal
        setShowHikesModal(true);
      }
      return;
    }

    // Check if this is the trail notes product
    if (product.title === 'Trail Notes') {
      // Check if it's member-only and if user is a member
      if (product.memberOnly && !userData?.isMember) {
        setSelectedProduct(product);
        setShowAccessDenied(true);
      } else {
        // Show the trail notes modal
        setShowNotesModal(true);
      }
      return;
    }

    if (product.title === 'Stranded-&-Shaken') {
      // Check if it's member-only and if user is a member
      if (product.memberOnly && !userData?.isMember) {
        setSelectedProduct(product);
        setShowAccessDenied(true);
      } else {
        // Show the upcoming hikes modal
        setshowStrandedModal(true);
      }
      return;
    }

    // Handle other products as before
    if (product.requiresAccess || product.memberOnly) {
      const hasAccess = (product.requiresAccess && userData?.workshopAccess) ||
        (product.memberOnly && userData?.isMember);

      if (hasAccess) {
        if (product.path) {
          navigate(product.path);
        } else if (product.externalLink) {
          window.open(product.externalLink, '_blank');
        }
      } else {
        setSelectedProduct(product);
        setShowAccessDenied(true);
      }
    } else {
      if (product.path) {
        navigate(product.path);
      } else if (product.externalLink) {
        window.open(product.externalLink, '_blank');
      }
    }
  };

  if (loading) {
    return (
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        //backgroundColor: 'rgba(0, 0, 0, 0.3)',
        backdropFilter: 'blur(8px)',
        zIndex: 9999,
      }}>
        <ClipLoader color="#ff6e1e" size={50} />
      </div>
    );
  }

  return (
    <div className="container-fluid d-flex flex-column"
      style={{ minHeight: '100vh', backgroundColor: '#f8f9fa', position: 'relative', paddingTop: '2rem' }}>

      <div className="row flex-grow-1">
        <div className="col-12 col-md-10 col-lg-8 mx-auto d-flex flex-column justify-content-center">
          <div className="welcome-section text-center mb-3">
            <div className="welcome-text">MEMBER PORTAL</div>

            <div className="d-flex align-items-center justify-content-center gap-2 mt-1 mb-1">
              <button
                className="fw-bold profile-button"
                onClick={handleProfileClick}
                style={{
                  color: '#ff6b35',
                  textDecoration: 'underline',
                  fontSize: '1.1rem',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '0'
                }}
              >
                {userData.FullName}
              </button>


              <div className="d-flex gap-1">
                <button
                  onClick={handleProfileClick}
                  className="btn p-1 d-flex align-items-center justify-content-center"
                  style={{
                    color: '#ff6b35',
                    backgroundColor: 'transparent',
                    minWidth: '10px'
                  }}
                >
                  <FontAwesomeIcon icon={faPen}
                    style={{
                      fontSize: '0.8rem',
                      position: 'relative',
                      top: '4px' // Adjust this value as needed
                    }} />
                </button>

                {userData?.isAdmin && (
                  <button
                    onClick={() => navigate("../Dashboard")}
                    className="btn p-1 d-flex align-items-center justify-content-center"
                    style={{
                      color: '#ff6b35',
                      backgroundColor: 'transparent',
                      minWidth: '10px'
                    }}
                  >
                    <FontAwesomeIcon icon={faUser}
                      style={{
                        fontSize: '0.8rem',
                        position: 'relative',
                        top: '4px' // Adjust this value as needed
                      }} />
                  </button>
                )}
              </div>
            </div>

            {userData?.Email && (
              <p className="text-muted mt-1 mb-1" style={{ fontSize: '0.9rem' }}>
                ({userData.Email})
              </p>
            )}

            {userData?.isMember && (
              <div className="member-badge mt-1">
                <Star size={16} className="me-1" />MEMBER
              </div>
            )}
          </div>


          <div className="row g-4 product-grid" style={{ marginTop: '-40px' }}>
            {products.map((product) => (
              <div key={product.id} className="col-12 col-md-6 col-lg-4 mb-4">
                <div
                  className="product-card"
                  onClick={() => handleProductSelect(product)}
                >
                  <div className="product-card-header">
                    <div className="product-icon">{product.icon}</div>
                    <div
                      className="product-access-badge"
                      style={{
                        color: product.memberOnly ? '#ff6b35' :
                          product.requiresAccess ? '#4A90E2' : '#42aaf5',
                        backgroundColor: 'transparent',  // Remove fill
                        border: `2px solid ${product.memberOnly ? '#ff6b35' :
                          product.requiresAccess ? '#4A90E2' : '#42aaf5'
                          }`
                      }}
                    >
                      {product.memberOnly ? 'Members Only' :
                        product.requiresAccess ? 'Paid' : 'Open to All'}
                    </div>

                  </div>

                  <div className="product-card-body">
                    <h5 className="product-title">{product.title}</h5>
                    <div className={`product-description ${expandedCards[product.id] ? 'expanded' : ''}`}>
                      {product.description || "No description available"}
                    </div>
                    {product.description && product.description.length > 100 && (
                      <button
                        className="read-more-btn"
                        onClick={(e) => toggleCardExpansion(e, product.id)}
                      >
                        {expandedCards[product.id] ? 'Read less' : 'Read more'}
                      </button>
                    )}
                  </div>

                </div>
              </div>
            ))}
          </div>

          <div className="text-center mt-4">
            <button
              onClick={handleLogout}
              className="btn btn-link text-danger text-decoration-none"
              style={{
                fontSize: '1.25rem',
                fontFamily: "'Dela Gothic One'",
                marginBottom: '0.3rem'
              }}
            >
              Logout
            </button>
          </div>
        </div>
      </div>

      <div
        className="w-100 text-center py-3"
        style={{
          color: '#6B7280',
          fontSize: '0.875rem',
          marginTop: 'auto',
          backgroundColor: 'transparent'
        }}
      >
        Copyright © Rush Labs
        | <a href="https://docs.google.com/document/d/e/2PACX-1vQ0Jtw8bX3Uc7iPkMQc-494wfPC24FxnE48SAGW61Ld6wskhFngri_SRGzc6_BIfUSTsSSWr__v-vwX/pub" target='_blank' style={{ color: '#6B7280', textDecoration: 'underline' }}>
          Terms & Member Guidelines
        </a> |
        Support : <a href="mailto:hi@manav.in" style={{ color: '#6B7280', textDecoration: 'underline' }}>
          hi@manav.in
        </a>
      </div>

      {showAccessDenied && (
        <AccessDeniedPopup
          onClose={() => setShowAccessDenied(false)}
          product={selectedProduct}
        />
      )}

      {/* Upcoming Hikes Modal */}
      {showHikesModal && (
        <CommunityHikeModal
          onClose={() => setShowHikesModal(false)}
          userData={userData}
        />
      )}

      {/* Trail Notes Modal */}
      {showNotesModal && (
        <TrailNotesModal onClose={() => setShowNotesModal(false)} />
      )}

      {/* Stranded and Shaken Modal */}
      {showStrandedModal && (
        <StrandedAndShakenModal onClose={() => setshowStrandedModal(false)} />
      )}

      <style>
        {`
          /* General styles */
          body {
            font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
          }
          
          /* Hide scrollbar for Chrome, Safari and Opera */
          .hide-scrollbar::-webkit-scrollbar {
            display: none;
          }

          /* Hide scrollbar for IE, Edge and Firefox */
          .hide-scrollbar {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
          }
          
          /* Welcome section */
          .welcome-section {
            margin-bottom: 2rem !important;
          }
          
          .welcome-text {
            font-size: 1.45rem;
            font-family: 'Dela Gothic One', system-ui;
            margin-bottom: 0.5rem;
            color: #222;
          }
          
          .member-badge {
            background-color: transparent; /* Remove fill */
            color: #ff6b35; /* Change text color */
            padding: 0.25rem 0.75rem;
            border: 2px solid #ff6b35; /* Add border */
            border-radius: 20px;
            font-size: 0.9rem;
            display: inline-flex;
            align-items: center;
            font-weight: 600;
            font-family: 'Dela Gothic One';
        }

          
          /* Product cards */
          .product-card {
            background-color: #ffffff;
            border-radius: 12px;
            overflow: hidden;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
            transition: all 0.3s ease;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            height: 100%;
            position: relative;
            border: 1px solid rgba(0, 0, 0, 0.06);
          }
          
          .product-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
          }
          
          .product-card-header {
            padding: 1.25rem 1.25rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          
          .product-icon {
            font-size: 2rem;
            color: #333;
            margin-right: 1rem;
          }
          
          .product-access-badge {
            font-size: 0.7rem;
            font-weight: 600;
            padding: 0.25rem 0.75rem;
            border-radius: 20px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            display: inline-flex;
            align-items: center;
        }

          
          .product-card-body {
            padding: 0.75rem 1.25rem;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
          }
          
          .product-title {
            font-size: 1.1rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
            color: #111;
          }
          
          .product-description {
            color: #666;
            font-size: 0.9rem;
            line-height: 1.5;
            margin-bottom: 0.5rem;
            overflow: hidden;
            max-height: 4.5em;
            transition: max-height 0.3s ease;
          }
          
          .product-description.expanded {
            max-height: 1000px; /* Large value to accommodate any text length */
          }
          
          .read-more-btn {
            align-self: flex-start;
            background: none;
            border: none;
            color: #4A90E2;
            font-size: 0.8rem;
            padding: 0;
            margin-top: 0.3rem;
            cursor: pointer;
            font-weight: 600;
          }
          
          .read-more-btn:hover {
            text-decoration: underline;
          }
          
          .product-card-footer {
            padding: 0.75rem 1.25rem;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: #777;
          }
          
          
          /* Footer */
          .footer {
            width: 100%;
            text-align: center;
            padding: 1rem 0;
            color: #6B7280;
            font-size: 0.875rem;
            margin-top: auto;
          }
          
          .shadow-hover:hover {
            transform: translateY(-5px);
            box-shadow: 0 4px 15px rgba(0,0,0,0.1) !important;
          }
          
          /* Responsive adjustments */
          @media (max-width: 768px) {
            .w-100.text-center.py-3 {
              padding-bottom: 1rem !important;
              padding-top: 1rem !important;
              font-size: 0.8rem;
            }
            .button-container {
              right: 1rem;
            }
          }
          
          @media (min-width: 769px) {
            .button-container {
              right: 20%;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ProductSelection;