import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import ClipLoader from "react-spinners/ClipLoader";
import { ExternalLink, Key, Users, Star } from 'lucide-react';

const ProductDisplay = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsRef = collection(db, "Products");
        const q = query(productsRef, where("isDeleted", "==", false));
        const querySnapshot = await getDocs(q);
        const fetchedProducts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
          .sort((a, b) => {
            if (a.serialNo !== undefined && b.serialNo !== undefined) {
              return a.serialNo - b.serialNo;
            }
            return 0;
          });
        setProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [db]);

  const handleProductSelect = (product) => {
    navigate('/login', {
      state: {
        intendedProduct: product
      }
    });
  };

  if (loading) {
    return (
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        //backgroundColor: 'rgba(0, 0, 0, 0.3)',
        backdropFilter: 'blur(8px)',
        zIndex: 9999,
      }}>
        <ClipLoader color="#ff6e1e" size={50} />
      </div>
    );
  }

  return (
    <div className="container-fluid d-flex flex-column"
      style={{ minHeight: '100vh', backgroundColor: '#f0f1f3', position: 'relative', paddingTop: '2rem' }}>
      <div className="row flex-grow-1">
        <div className="col-12 col-md-8 col-lg-6 mx-auto d-flex flex-column justify-content-center">
          <div className="text-center mb-5">
            <div style={{
              fontSize: '1.45rem',
              fontFamily: "'Dela Gothic One'",
              marginBottom: '0.5rem'
            }}>
              WELCOME
            </div>
            <p className="text-muted mt-2">Explore Our Products</p>
          </div>

          <div className="row g-4" style={{ marginTop: '-60px' }}>
            {products.map((product) => (
              <div key={product.id} className="col-12 col-md-6">
                <div
                  className="card h-100 shadow-hover"
                  onClick={() => handleProductSelect(product)}
                  style={{
                    cursor: 'pointer',
                    transition: 'all 0.3s ease'
                  }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <span style={{ fontSize: '2rem', marginRight: '1rem' }}>{product.icon}</span>
                      <div>
                        <h5 className="card-title mb-0">{product.title}</h5>
                        <div className="mt-1">
                          {product.memberOnly && (
                            <span
                              className="badge me-1"
                              style={{
                                backgroundColor: '#22C55E',
                                color: 'white'
                              }}
                            >
                              Members Only
                            </span>
                          )}
                          {product.requiresAccess && (
                            <span
                              className="badge me-1"
                              style={{
                                backgroundColor: '#4A90E2',
                                color: 'white'
                              }}
                            >
                              Paid
                            </span>
                          )}
                          {!product.memberOnly && !product.requiresAccess && (
                            <span
                              className="badge"
                              style={{
                                backgroundColor: '#10B981',
                                color: 'white'
                              }}
                            >
                              Free
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <p className="card-text text-muted">{product.description}</p>
                    <div className="text-end">
                      <i className="bi bi-chevron-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="text-center mt-4">
            <button
              onClick={() => navigate('/login')}
              className="btn btn-link text-primary text-decoration-none"
              style={{
                fontSize: '1.25rem',
                fontFamily: "'Dela Gothic One'",
                marginBottom: '0.3rem'
              }}
            >
              Login
            </button>
          </div>
        </div>
      </div>

      <div
        className="w-100 text-center py-3"
        style={{
          color: '#6B7280',
          fontSize: '0.875rem',
          marginTop: 'auto',
          backgroundColor: 'transparent'
        }}
      >
        Copyright © Rush Labs
        | <a href="https://docs.google.com/document/d/e/2PACX-1vQ0Jtw8bX3Uc7iPkMQc-494wfPC24FxnE48SAGW61Ld6wskhFngri_SRGzc6_BIfUSTsSSWr__v-vwX/pub"  target='_blank' style={{ color: '#6B7280', textDecoration: 'underline' }}>
          Terms & Member Guidelines
        </a> |
        Support : <a href="mailto:hi@manav.in" style={{ color: '#6B7280', textDecoration: 'underline' }}>
          hi@manav.in
        </a>
      </div>

      <style>
        {`
          .shadow-hover:hover {
            transform: translateY(-5px);
            box-shadow: 0 4px 15px rgba(0,0,0,0.1) !important;
          }
          @media (max-width: 768px) {
            .w-100.text-center.py-3 {
              padding-bottom: 1rem !important;
              padding-top: 1rem !important;
              font-size: 0.8rem;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ProductDisplay;