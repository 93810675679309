import React from 'react';
import { AlertTriangle, ExternalLink } from 'lucide-react';

const StrandedAndShakenModal = ({ onClose }) => {
  // Placeholder story data (currently coming soon)
  const stories = [
    {
      id: 1,
      title: "Story 1",
      status: "Coming Soon"
    },
    {
      id: 2,
      title: "Story 2",
      status: "Coming Soon"
    },
    {
      id: 3,
      title: "Story 3",
      status: "Coming Soon"
    }
  ];

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
      }}
    >
      <div className="card" style={{
        maxWidth: '500px',
        width: '90%',
        margin: '20px',
        borderRadius: '12px',
        boxShadow: '0 10px 25px rgba(0,0,0,0.1)',
        maxHeight: '80vh',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <div className="card-body p-4 overflow-auto">
          <div className="d-flex align-items-center mb-3">
            <AlertTriangle size={32} color="#E25C4A" className="me-3" />
            <h2 className="card-title mb-0" style={{ color: '#333' }}>
              Stranded-&-Shaken Stories
            </h2>
          </div>
          
          <div className="card-text mb-4">
            <p className="text-muted mb-3">
              Real-stories of hikers who were left <em>stranded-&-shaken</em> — and what we can learn from their experience.
            </p>
            
            <ul className="list-unstyled">
              {stories.map(story => (
                <li key={story.id} className="mb-3 p-2 border-bottom">
                  <div className="d-flex align-items-start">
                    <div>
                      <div style={{ color: '#E25C4A', fontWeight: '600' }}>
                        <strong>{story.title}</strong>
                      </div>
                      <div className="text-muted small">{story.status}</div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            
            <div className="mt-4 p-3 bg-light rounded">
              <p className="mb-2 text-muted small">
                If you have a story where you were left stranded or shaken on a hike (or any other incident that happened with you) — we want to capture your story.
              </p>
              <p className="mb-2 text-muted small">
                We will document the incident & share lessons in the community. Your story will be shared <em>anonymously</em> and your identity will be protected (the focus is on the lessons, not the person).
              </p>
              <p className="mb-0 text-muted small">
                Please DM Shubrank if you would like to share, we'll do the rest. 🙏
              </p>
            </div>
          </div>
          
          <div className="d-flex justify-content-end mt-3">
            <button
              onClick={onClose}
              className="btn btn-primary"
              style={{
                backgroundColor: '#E25C4A',
                borderColor: '#E25C4A',
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrandedAndShakenModal;