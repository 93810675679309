import React, { useState, useEffect } from 'react';
import { Calendar, Loader } from 'lucide-react';
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore';

const CommunityHikeModal = ({ onClose, userData }) => {
  const [hikes, setHikes] = useState([]);
  const [loading, setLoading] = useState(true);
  const db = getFirestore();

  useEffect(() => {
    const fetchHikes = async () => {
      try {
        setLoading(true);
        const hikesRef = collection(db, "CommunityHikes");
        const q = query(hikesRef, where("isDeleted", "==", false));
        const querySnapshot = await getDocs(q);
        
        const fetchedHikes = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })).sort((a, b) => {
          // If both have date fields, sort by date
          if (a.date && b.date) {
            return new Date(a.date) - new Date(b.date);
          }
          return 0;
        });
        
        setHikes(fetchedHikes);
      } catch (error) {
        console.error("Error fetching community hikes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHikes();
  }, [db]);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
      }}
    >
      <div className="card" style={{
        maxWidth: '400px',
        margin: '20px',
        borderRadius: '12px',
        boxShadow: '0 10px 25px rgba(0,0,0,0.1)'
      }}>
        <div className="card-body p-4">
          <div className="d-flex align-items-center mb-3">
            <Calendar size={32} color="#22C55E" className="me-3" />
            <h2 className="card-title mb-0" style={{ color: '#333' }}>
              Upcoming Community Hikes
            </h2>
          </div>
          
          <div className="card-text text-muted mb-4">
            {loading ? (
              <div className="d-flex justify-content-center my-4">
                <Loader size={24} className="animate-spin" />
              </div>
            ) : hikes.length > 0 ? (
              <>
                <ul className="ps-3 mb-3">
                  {hikes.map(hike => (
                    <li key={hike.id} className="mb-2">
                      {hike.date} - {hike.location} 
                      {hike.tentative ? " (Tentative)" : ""}
                    </li>
                  ))}
                </ul>
                <p>RSVP will open once the dates and details are finalized. Announcement will be made in the community group.</p>
              </>
            ) : (
              <p>No upcoming hikes available at the moment. Check back later!</p>
            )}
          </div>
          
          <div className="d-flex justify-content-end">
            <button
              onClick={onClose}
              className="btn btn-primary"
              style={{
                backgroundColor: '#22C55E',
                borderColor: '#22C55E',
              }}
            >
              Got it
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityHikeModal;