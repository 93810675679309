import React, { useState, useEffect } from 'react';
import { useNavigate ,useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { getFirestore, collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { ClipLoader } from 'react-spinners';
import { ArrowLeft, Search, MapPin, X, Check, Briefcase } from 'lucide-react';
import MemberProfileModal from './MemberProfileModal';

const MemberDirectory = () => {
    const navigate = useNavigate();
    const [cookies] = useCookies(['userId']);
    const [loading, setLoading] = useState(true);
    const [members, setMembers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [cityFilter, setCityFilter] = useState('');
    const [uniqueCities, setUniqueCities] = useState([]);
    const [currentUserData, setCurrentUserData] = useState(null);
    const [selectedMemberId, setSelectedMemberId] = useState(null);
    const db = getFirestore();

    const location = useLocation();

    useEffect(() => {
        // Check for member ID in location state when component mounts
        const state = location.state;
        if (state && state.memberId) {
            setSelectedMemberId(state.memberId);
        }
    }, [location.state]);

    // Calculate trek experience level
    const calculateExperienceLevel = (treksDone) => {
        if (!treksDone) return { level: 'Beginner', count: 0 };
        const count = treksDone.length;
        if (count === 0) return { level: 'Beginner', count };
        if (count <= 3) return { level: 'Explorer', count };
        if (count <= 7) return { level: 'Experienced', count };
        return { level: 'Expert', count };
    };

    // Function to format name with proper capitalization
    const formatName = (name) => {
        if (!name) return '';
        return name.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    useEffect(() => {
        const fetchMembers = async () => {
            setLoading(true);
            try {
                const userIdFromLocalStorage = localStorage.getItem('userId');
                const userIdFromCookies = cookies.userId;
                const userId = userIdFromLocalStorage || userIdFromCookies;

                if (!userId) {
                    navigate('/login');
                    return;
                }

                // Fetch current user's data
                const userDocRef = doc(db, "UserTable", userId);
                const userDocSnap = await getDoc(userDocRef);

                if (!userDocSnap.exists()) {
                    navigate('/login');
                    return;
                }

                const userData = userDocSnap.data();

                // Check isMember status
                if (userData.isMember !== true) {
                    navigate('/products');
                    return;
                }

                // Check isDirectoryVisible status
                if (!userData.isDirectoryVisible) {
                    setCurrentUserData(null);
                } else {
                    setCurrentUserData(userData);
                }

                // Fetch all visible members
                const q = query(
                    collection(db, "UserTable"),
                    where("isDirectoryVisible", "==", true)
                );

                const querySnapshot = await getDocs(q);
                const membersData = [];
                const cities = new Set();

                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.City) cities.add(data.City);
                    membersData.push({
                        id: doc.id,
                        ...data,
                        formattedName: formatName(data.FullName),
                        experienceLevel: calculateExperienceLevel(data.TreksDone)
                    });
                });

                // Sort members alphabetically by formatted name
                membersData.sort((a, b) => a.formattedName.localeCompare(b.formattedName));

                // Move current user to the top if they're visible in the directory
                if (userData.isDirectoryVisible) {
                    const currentUserIndex = membersData.findIndex(member => member.id === userId);
                    if (currentUserIndex !== -1) {
                        const currentUser = membersData.splice(currentUserIndex, 1)[0];
                        membersData.unshift(currentUser);
                    }
                }

                setMembers(membersData);
                setUniqueCities(Array.from(cities).sort());
            } catch (error) {
                console.error("Error fetching members:", error);
                navigate('/products'); // Redirect on error
            } finally {
                setLoading(false);
            }
        };

        fetchMembers();
    }, [cookies, navigate, db]);

    const filteredMembers = members.filter(member => {
        const matchesSearch =
            member.FullName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            member.City?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            member.Profession?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            member.Email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            member.WhatsAppNo?.includes(searchQuery);
        const matchesCity = !cityFilter || member.City === cityFilter;
        return matchesSearch && matchesCity;
    });

    const handleMemberCardClick = (memberId) => {
        setSelectedMemberId(memberId);
    };

    const handleCloseModal = () => {
        setSelectedMemberId(null);
    };

    if (loading) {
        return (
            <div className="loading-container" style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <ClipLoader color="#ff6e1e" size={50} />
            </div>
        );
    }

    // If user is not visible in directory, show message
    if (!currentUserData) {
        return (
            <div className="container-fluid" style={{
                maxWidth: '1200px',
                padding: '20px',
                margin: '0 auto'
            }}>
                <div className="back-button-container" style={{ marginBottom: '20px' }}>
                    <button
                        onClick={() => navigate('/products')}
                        className="back-button"
                        style={{
                            border: 'none',
                            background: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                            color: '#333',
                            padding: '0',
                            cursor: 'pointer'
                        }}
                    >
                        <ArrowLeft size={16} />
                        <span>Back</span>
                    </button>
                </div>

                <h1 className="text-center mb-4" style={{
                    fontSize: '1.45rem',
                    fontFamily: "'Dela Gothic One'",
                }}>MEMBER DIRECTORY</h1>

                <div className="alert alert-warning" role="alert">
                    <p className="mb-0">Hi, in order to view other member's profiles, you must have a complete profile.</p>
                </div>

                <button
                    onClick={() => navigate('/ProfileForm')}
                    className="btn w-100"
                    style={{
                        backgroundColor: '#ff6e1e',
                        color: 'white',
                        fontWeight: '600'
                    }}
                >
                    Update Your Profile
                </button>
            </div>
        );
    }

    return (
        <div className="container-fluid" style={{
            maxWidth: '1200px',
            padding: '20px',
            margin: '0 auto'
        }}>
            {/* Back button and header */}
            <div className="back-button-container" style={{ marginBottom: '20px' }}>
                <button
                    onClick={() => navigate('/products')}
                    className="back-button"
                    style={{
                        border: 'none',
                        background: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        color: '#333',
                        padding: '0',
                        cursor: 'pointer'
                    }}
                >
                    <ArrowLeft size={16} />
                    <span>Back</span>
                </button>
            </div>

            <h1 className="text-center mb-4" style={{
                fontSize: '1.45rem',
                fontFamily: "'Dela Gothic One'",
            }}>MEMBER DIRECTORY</h1>

            {/* Improved Search and Filter - Made search bar 1.5x wider */}
            <div className="mb-4" style={{
                maxWidth: '720px', // Increased from 480px to 720px (1.5x)
                margin: '0 auto'
            }}>
                <div className="d-flex flex-column flex-md-row gap-3">
                    <div className="position-relative flex-grow-1">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by name, city, profession, or contact..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            style={{
                                paddingLeft: '40px',
                                paddingRight: searchQuery ? '40px' : '12px',
                                height: '44px',
                                fontSize: '0.95rem',
                                borderRadius: '8px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.05)'
                            }}
                        />
                        <Search size={20} style={{
                            position: 'absolute',
                            left: '12px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: '#666'
                        }} />
                        {searchQuery && (
                            <button
                                className="btn btn-link position-absolute p-0"
                                style={{
                                    right: '12px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    color: '#666',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '24px',
                                    height: '24px'
                                }}
                                onClick={() => setSearchQuery('')}
                            >
                                <X size={16} />
                            </button>
                        )}
                    </div>

                    <select
                        className="form-select"
                        value={cityFilter}
                        onChange={(e) => setCityFilter(e.target.value)}
                        style={{
                            height: '44px',
                            fontSize: '0.95rem',
                            minWidth: '140px',
                            width: 'auto',
                            borderRadius: '8px',
                            boxShadow: '0 2px 5px rgba(0,0,0,0.05)'
                        }}
                    >
                        <option value="">All Cities</option>
                        {uniqueCities.map(city => (
                            <option key={city} value={city}>{city}</option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Members count */}
            <div className="mb-3" style={{
                fontSize: '0.9rem',
                color: '#666',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                flexWrap: 'wrap',
                gap: '4px'
            }}>
                <span>{filteredMembers.length} members</span>
                <span className="flex items-center">
                    | Members with a
                    <img
                        src="/verification-badge.png"
                        alt="shield"
                        className="inline-block mx-1"
                        style={{
                            height: '1.5rem',
                            width: 'auto',
                            verticalAlign: 'middle'
                        }}
                    />
                    has been ID-verified
                </span>
            </div>

            {/* Members Grid - With requested changes */}
            <div className="row g-3">
                {filteredMembers.map(member => (
                    <div key={member.id} className="col-12 col-md-6 col-lg-4 col-xl-3">
                        <div
                            className="card h-100"
                            style={{
                                border: '1px solid #e8e8e8',
                                borderRadius: '12px',
                                boxShadow: '0 2px 8px rgba(0,0,0,0.04)',
                                overflow: 'hidden',
                                cursor: 'pointer',
                                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '16px'
                            }}
                            onClick={() => handleMemberCardClick(member.id)}
                            onMouseOver={(e) => {
                                e.currentTarget.style.transform = 'translateY(-3px)';
                                e.currentTarget.style.boxShadow = '0 8px 16px rgba(0,0,0,0.08)';
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.transform = 'translateY(0)';
                                e.currentTarget.style.boxShadow = '0 2px 8px rgba(0,0,0,0.04)';
                            }}
                        >
                            {/* Profile Header with Photo - Center aligned horizontally */}
                            <div className="d-flex justify-content-left mb-3">
                                {/* Profile Photo with orange ring */}
                                <div style={{
                                    width: '66px',
                                    height: '66px',
                                    position: 'relative',
                                    marginRight: '12px',
                                    flexShrink: 0
                                }}>
                                    <div style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '50%',
                                        overflow: 'hidden',
                                        backgroundColor: '#f8f9fa',
                                        border: '3px solid #ff6e1e', // Orange ring restored
                                        boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        {member.ProfilePhotoURL ? (
                                            <img
                                                src={member.ProfilePhotoURL}
                                                alt={member.formattedName}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                        ) : (
                                            <span style={{
                                                fontSize: '1.8rem',
                                                color: '#aaa',
                                                background: '#f0f0f0',
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>👤</span>
                                        )}
                                    </div>

                                    {/* Verified badge - Made larger */}
                                    {member.isMember && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                bottom: '0',
                                                right: '0',
                                                width: '28px',
                                                height: '28px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <img
                                                src="/verification-badge.png"
                                                alt="Verified Member"
                                                width="40"  // Increased from 40 to 50
                                                height="30"  // Increased from 30 to 40
                                            />
                                        </div>
                                    )}
                                </div>

                                {/* Name - Side by side with photo */}
                                <div className="d-flex align-items-center">
                                    <h5 style={{
                                        margin: '0',
                                        fontSize: '1.05rem',
                                        fontWeight: '600',
                                        color: '#2d3748',
                                        lineHeight: '1.2'
                                    }}>{member.formattedName}</h5>
                                </div>
                            </div>

                            {/* Details section with icons and text */}
                            <div className="mb-2" style={{ paddingLeft: '2px' }}>
                                {member.City && (
                                    <div className="d-flex align-items-center mb-2">
                                        <MapPin size={14} style={{ color: '#718096', marginRight: '8px', flexShrink: 0 }} />
                                        <span style={{
                                            fontSize: '0.85rem',
                                            color: '#4a5568',
                                            fontWeight: '400'
                                        }}>{member.City}</span>
                                    </div>
                                )}

                                {member.Profession && (
                                    <div className="d-flex align-items-center mb-2">
                                        <Briefcase size={14} style={{ color: '#718096', marginRight: '8px', flexShrink: 0 }} />
                                        <span style={{
                                            fontSize: '0.85rem',
                                            color: '#4a5568',
                                            fontWeight: '400'
                                        }}>{member.Profession}</span>
                                    </div>
                                )}
                            </div>

                            {/* Bio Section - No divider and consistent spacing */}
                            {member.Bio && (
                                <div className="bio-section mb-2">
                                    {/* Removed the divider/hr here */}
                                    <p style={{
                                        fontSize: '0.85rem',
                                        lineHeight: '1.5',
                                        color: '#718096',
                                        margin: '0',
                                        whiteSpace: 'pre-line',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '3',
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {member.Bio}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            {
                filteredMembers.length === 0 && (
                    <div className="text-center py-5" style={{ color: '#718096' }}>
                        <p>No members found matching your criteria.</p>
                        {(searchQuery || cityFilter) && (
                            <button
                                onClick={() => {
                                    setSearchQuery('');
                                    setCityFilter('');
                                }}
                                className="btn btn-outline-secondary btn-sm"
                            >
                                Clear filters
                            </button>
                        )}
                    </div>
                )
            }

            {/* Profile Modal */}
            {
                selectedMemberId && (
                    <MemberProfileModal
                        memberId={selectedMemberId}
                        onClose={handleCloseModal}
                        memberData={members.find(m => m.id === selectedMemberId)}
                    />
                )
            }
        </div >
    );
};

export default MemberDirectory;