import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { initializeApp } from "firebase/app";
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import ClipLoader from "react-spinners/ClipLoader";

function CreateAlertForm() {
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
    };
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // Changed to false initially
    const [cookies] = useCookies(['userId']);
    const [tripName, setTripName] = useState('');
    const [userId, setUserId] = useState('');
    const [location, setLocation] = useState(''); // Changed from nearestTown
    const [groupSize, setGroupSize] = useState('');
    const [routeDetails, setRouteDetails] = useState('');
    const [returnDate, setReturnDate] = useState('');
    const [returnTime, setReturnTime] = useState('');
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [selectedActivities, setSelectedActivities] = useState('');
    const [errors, setErrors] = useState({});
    const [error, setError] = useState({});
    const hours = [
        '12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
        '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM'
    ];

    const toggleActivity = (act) => {
        let activitiesArray = selectedActivities ? selectedActivities.split(', ') : [];

        if (activitiesArray.includes(act)) {
            activitiesArray = activitiesArray.filter((activity) => activity !== act);
        } else {
            activitiesArray.push(act);
        }

        setSelectedActivities(activitiesArray.join(', '));
    };

    useEffect(() => {
        const userIdCookie = cookies.userId;
        if (userIdCookie) {
            setUserId(userIdCookie);
        } else {
            navigate("../login");
            console.error("UserID not found in cookies!");
        }
    }, [cookies, navigate]);

    const validateForm = () => {
        let formErrors = {};
        const [year, month, day] = returnDate.split('-').map(Number);
        const minutes = 0;
        let hours = 0;
        if (returnTime.endsWith('AM')) {
            hours = parseInt(returnTime.replace(' AM', ''), 10);
            if (hours === 12) {
                hours = 0;
            }
        } else if (returnTime.endsWith('PM')) {
            hours = parseInt(returnTime.replace(' PM', ''), 10);
            if (hours !== 12) {
                hours += 12;
            }
        }
        const returnTimestamp = new Date(year, month - 1, day, hours, minutes);
        const currentDate = new Date();

        if (!tripName.trim()) {
            formErrors.tripName = "Trip Name is required.";
        } else if (tripName.length < 2) {
            formErrors.tripName = "Trip Name must be at least 2 characters.";
        } else if (tripName.length > 100) {
            formErrors.tripName = "Trip Name cannot exceed 100 characters.";
        }
        if (!selectedActivities.trim()) formErrors.activity = "Activity is required.";
        if (!location.trim()) formErrors.location = "Location is required.";
        if (!groupSize.trim()) formErrors.groupSize = "Group Size is required.";
        if (!returnDate) formErrors.returnDate = "Return Date is required.";
        if (!returnTime) {
            formErrors.returnTime = "Return Time is required.";
        } else {
            if (returnTimestamp < currentDate) {
                formErrors.returnDate = "Return Date and Time must be in the future.";
            } else if (returnTimestamp - currentDate < 3 * 60 * 60 * 1000) {
                formErrors.returnTime = "Return Time must be at least 3 hours from now.";
            }
        }
        if (!agreeToTerms) formErrors.agreeToTerms = "You must agree to the terms.";
        setErrors(formErrors);
        return formErrors;
    };

    // const handleSubmit = async (e) => {
    //     try {
    //         e.preventDefault();
    //         const errors = validateForm();
    //         if (Object.keys(errors).length > 0) {
    //             return;
    //         }
            
    //         setLoading(true);
    //         const [year, month, day] = returnDate.split('-').map(Number);
    //         const minutes = 0;
    //         let hours = 0;
    //         if (returnTime.endsWith('AM')) {
    //             hours = parseInt(returnTime.replace(' AM', ''), 10);
    //             if (hours === 12) {
    //                 hours = 0;
    //             }
    //         } else if (returnTime.endsWith('PM')) {
    //             hours = parseInt(returnTime.replace(' PM', ''), 10);
    //             if (hours !== 12) {
    //                 hours += 12;
    //             }
    //         }
    //         const returnTimestamp = new Date(year, month - 1, day, hours, minutes);

    //         const docRef = await addDoc(collection(db, "AlertTable"), {
    //             "Activity": selectedActivities,
    //             "IsTripCompleted": false,
    //             "isAlertSent": false,
    //             "Location": location,
    //             "ReturnTimestamp": returnTimestamp,
    //             "GroupSize": groupSize || '',
    //             "CreatedOn": new Date(),
    //             "RouteDetails": routeDetails,
    //             "TripName": tripName,
    //             "UserId": userId,
    //             "AgreedToTermsAndCondition": agreeToTerms
    //         });
            
    //         if (docRef.id) {
    //             window.location.href = `https://alerts.creedaz.in/alerts`;
    //         }
    //     } catch (error) {
    //         console.error("Error adding document: ", error);
    //         setLoading(false);
    //     }
    // };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const errors = validateForm();
            if (Object.keys(errors).length > 0) {
                return;
            }
            
            setLoading(true);
            
            // Parse date and time
            const [year, month, day] = returnDate.split('-').map(Number);
            const minutes = 0;
            let hours = 0;
            if (returnTime.endsWith('AM')) {
                hours = parseInt(returnTime.replace(' AM', ''), 10);
                if (hours === 12) {
                    hours = 0;
                }
            } else if (returnTime.endsWith('PM')) {
                hours = parseInt(returnTime.replace(' PM', ''), 10);
                if (hours !== 12) {
                    hours += 12;
                }
            }
            const returnTimestamp = new Date(year, month - 1, day, hours, minutes);
    
            // Create new alert document
            const newAlert = {
                "Activity": selectedActivities,
                "IsTripCompleted": false,
                "isAlertSent": false,
                "Location": location,
                "ReturnTimestamp": returnTimestamp,
                "GroupSize": groupSize || '',
                "CreatedOn": new Date(),
                "RouteDetails": routeDetails,
                "TripName": tripName,
                "UserId": userId,
                "AgreedToTermsAndCondition": agreeToTerms,
                "isVisibleFlag": true
            };
    
            const docRef = await addDoc(collection(db, "AlertTable"), newAlert);
            
            if (docRef.id) {
                // Use navigate instead of window.location
                navigate('/alerts', { 
                    replace: true,
                    state: { 
                        newAlert: true,
                        alertId: docRef.id 
                    }
                });
            }
        } catch (error) {
            console.error("Error adding document: ", error);
            // Show error message to user
            setError("Failed to create alert. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (field, value) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: ''
        }));
        switch (field) {
            case 'tripName':
                setTripName(value);
                break;
            case 'location':
                setLocation(value);
                break;
            case 'groupSize':
                setGroupSize(value);
                break;
            case 'routeDetails':
                setRouteDetails(value);
                break;
            case 'returnDate':
                setReturnDate(value);
                break;
            case 'returnTime':
                setReturnTime(value);
                break;
            case 'agreeToTerms':
                setAgreeToTerms(value);
                break;
            default:
                break;
        }
    };

    const onBackCreateAlert = () => {
        navigate(-1);
    };

    return (
        <div className={loading ? 'overflow-hidden' : ''} style={{ position: 'relative', height: '100vh', overflowY: loading ? 'hidden' : 'auto', backgroundColor: '#f0f1f3', overflowX: 'hidden' }}>
            <div className="container-fluid d-flex flex-column" style={{ maxWidth: '480px', backgroundColor: '#f0f1f3', height: '100%' }}>
                <div className="col d-flex justify-content-center align-items-center">
                <h1
                    className="mb-0"
                    style={{
                        fontSize: '1.65rem',
                        //fontWeight: 'bolder',
                        //fontStyle: 'italic',
                        textAlign: 'center',
                        fontFamily: "'Dela Gothic One'",
                    }}
                    >
                    ALERTS
                    </h1>
                </div>
                <header className="row py-3">
                    <div className="col">
                        <a className="text-blue text-decoration-none" onClick={onBackCreateAlert}>← Back</a>
                    </div>
                </header>
                <div className='bg-white p-3 rounded'>
                    <div className="row flex-grow-1">
                        <div className="col px-4 py-3">
                            <h1 className="h5 mb-4">Create a new alert</h1>
                            <div>
                                <div className="mb-3">
                                    <label htmlFor="tripName" className="form-label text-uppercase small fw-bold text-secondary">Trip Name</label><br />
                                    <small className="text-muted">An easy recognizable name like 'Hampta Pass'</small>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="tripName"
                                        value={tripName}
                                        onChange={(e) => handleInputChange('tripName', e.target.value)}
                                        placeholder=""
                                        maxLength={100}
                                        pattern="^[a-zA-Z0-9]{2,100}$"
                                        minLength={2}
                                    />
                                    {errors.tripName && <div className="text-danger">{errors.tripName}</div>}
                                </div>

                                <div className="mb-3">
                                    <label className="form-label text-uppercase small fw-bold text-secondary">Activity</label>
                                    <div className="d-flex flex-wrap gap-2">
                                        {['Hike / Trek', 'Trail Run', 'Climb', 'MTB / Cycle', 'Mountain Expedition', 'Others'].map((act) => (
                                            <button
                                                key={act}
                                                type="button"
                                                className={`btn ${selectedActivities.includes(act) ? 'btn-selected' : 'btn-outline-secondary'}`}
                                                onClick={() => toggleActivity(act)}
                                                style={{
                                                    flex: '1 0 30%',
                                                    maxWidth: 'calc(33.333% - 0.5rem)',
                                                    textAlign: 'center',
                                                    padding: '0.5rem',
                                                    fontSize: '0.875rem',
                                                    color: selectedActivities.includes(act) ? '#000' : '#495057',
                                                    fontWeight: selectedActivities.includes(act) ? 'bold' : 'normal',
                                                    border: selectedActivities.includes(act) ? '2px solid black' : '1px solid #ced4da',
                                                    transition: 'all 0.2s ease',
                                                }}
                                                onMouseEnter={(e) => {
                                                    e.target.style.backgroundColor = selectedActivities.includes(act) ? '#fff' : '#fff';
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.target.style.backgroundColor = selectedActivities.includes(act) ? '#fff' : '#fff';
                                                }}
                                            >
                                                {act}
                                            </button>
                                        ))}
                                    </div>
                                    {errors.activity && <div className="text-danger">{errors.activity}</div>}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="location" className="form-label text-uppercase small fw-bold text-secondary">Location</label>
                                    <br />
                                    <small className="text-muted">Add nearest town, state & country. This will help coordinate with the right jurisdictional authorities in case of an emergency.</small>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="location"
                                        value={location}
                                        onChange={(e) => handleInputChange('location', e.target.value)}
                                        maxLength={100}
                                    />
                                    {errors.location && <div className="text-danger">{errors.location}</div>}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="groupSize" className="form-label text-uppercase small fw-bold text-secondary">Group Size</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="groupSize"
                                        value={groupSize}
                                        maxLength={100}
                                        pattern="^[a-zA-Z0-9]{2,100}$"
                                        onChange={(e) => handleInputChange('groupSize', e.target.value)}
                                    />
                                    {errors.groupSize && <div className="text-danger">{errors.groupSize}</div>}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="routeDetails" className="form-label text-uppercase small fw-bold text-secondary">Route Details</label>
                                    <br />
                                    <small className="text-muted">This will help search teams locate you faster and save critical time during a rescue.</small>
                                    <textarea
                                        className="form-control"
                                        id="routeDetails"
                                        rows="4"
                                        value={routeDetails}
                                        onChange={(e) => handleInputChange('routeDetails', e.target.value)}
                                        maxLength={1000}
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label text-uppercase small fw-bold text-secondary">Return Date & Time (IST)</label><br />
                                    <small className="text-muted">Must be at least 3 hours in the future</small>
                                    <div className="d-flex gap-2">
                                        <div className="flex-grow-1">
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="returnDate"
                                                value={returnDate}
                                                onChange={(e) => handleInputChange('returnDate', e.target.value)}

                                            />
                                        </div>
                                        <div>
                                            <select
                                                className="form-control"
                                                id="returnTime"
                                                value={returnTime}
                                                onChange={(e) => handleInputChange('returnTime', e.target.value)}
                                            >
                                                <option value="">Select return time</option>
                                                {hours.map((hour, index) => (
                                                    <option key={index} value={hour}>
                                                        {hour}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                    </div>
                                    {errors.returnDate && <div className="text-danger">{errors.returnDate}</div>}
                                    {errors.returnTime && <div className="text-danger">{errors.returnTime}</div>}
                                </div>

                                <div className="mb-4 form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="agreeToTerms"
                                        checked={agreeToTerms}
                                        onChange={(e) => handleInputChange('agreeToTerms', e.target.checked)}
                                    />
                                    <label className="form-check-label small" htmlFor="agreeToTerms">
                                        I understand that I will receive a check-in WhatsApp message at this time. If I don't respond to it within 60 mins, an alert will be sent to my emergency contacts.
                                    </label>
                                    {errors.agreeToTerms && <div className="text-danger">{errors.agreeToTerms}</div>}
                                </div>

                                <button
                                    type="submit"
                                    className="btn btn-primary w-100 py-2 text-uppercase fw-bold"
                                    style={{ backgroundColor: '#ff6e1e', borderColor: '#0000FF' }}
                                    onClick={handleSubmit}
                                    disabled={!agreeToTerms}
                                >
                                    Create Alert
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {loading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        //backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        backdropFilter: 'blur(8px)',
                        zIndex: 9999,
                    }}
                >
                    <ClipLoader color="#ff6e1e" size={50} />
                </div>
            )}
        </div>
    );
}


export default CreateAlertForm;