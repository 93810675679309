// MemberProfileModal.js
import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { X, MapPin, Calendar, Briefcase, Instagram, Facebook,Globe, Linkedin, Heart, CheckCircle, Check } from 'lucide-react';
import { ClipLoader } from 'react-spinners';

const MemberProfileModal = ({ memberId, onClose, memberData }) => { // Accept memberData prop
    const [member, setMember] = useState(memberData || null); // Initialize member state with prop
    const [loading, setLoading] = useState(!memberData); // Set loading to true only if memberData is not provided initially
    const [treksDoneData, setTreksDoneData] = useState([]);
    const [treksWishlistData, setTreksWishlistData] = useState([]);
    const [showAllTreksDone, setShowAllTreksDone] = useState(false);
    const [showAllWishlist, setShowAllWishlist] = useState(false);
    const db = getFirestore();

    // Number of treks to show initially
    const TREKS_TO_SHOW = 4;

    useEffect(() => {
        const fetchMemberDetails = async () => {
            setLoading(true);
            try {
                // Fetch member data ONLY if not already passed as prop
                let fetchedMemberData = memberData;
                if (!fetchedMemberData) { // Fetch only if memberData prop is not provided
                    const memberDoc = await getDoc(doc(db, "UserTable", memberId));
                    if (memberDoc.exists()) {
                        fetchedMemberData = memberDoc.data();
                    }
                }

                if (fetchedMemberData) {
                    setMember(fetchedMemberData); // Set member state here (either from prop or fetched)

                    // Fetch treks done data
                    if (fetchedMemberData.TreksDone && fetchedMemberData.TreksDone.length > 0) {
                        const trekPromises = fetchedMemberData.TreksDone.map(trekId =>
                            getDoc(doc(db, "trek_db", trekId))
                        );
                        const trekResults = await Promise.all(trekPromises);
                        const treksData = trekResults.map(doc => {
                            if (doc.exists()) {
                                return { id: doc.id, ...doc.data() };
                            }
                            return null;
                        }).filter(trek => trek !== null);

                        setTreksDoneData(treksData);
                    }

                    // Fetch treks wishlist data
                    if (fetchedMemberData.TreksWishlist && fetchedMemberData.TreksWishlist.length > 0) {
                        const wishlistPromises = fetchedMemberData.TreksWishlist.map(trekId =>
                            getDoc(doc(db, "trek_db", trekId))
                        );
                        const wishlistResults = await Promise.all(wishlistPromises);
                        const wishlistData = wishlistResults.map(doc => {
                            if (doc.exists()) {
                                return { id: doc.id, ...doc.data() };
                            }
                            return null;
                        }).filter(trek => trek !== null);

                        setTreksWishlistData(wishlistData);
                    }
                }
            } catch (error) {
                console.error("Error fetching member details:", error);
            } finally {
                setLoading(false);
            }
        };

        if (memberId && !memberData) { // Fetch member details only if memberId is present and memberData is not provided
            fetchMemberDetails();
        } else if (memberId && memberData) { // If memberData is provided, still fetch treks
            const fetchTreks = async () => {
                setLoading(true); // Keep loading for trek data
                try {
                    // Fetch treks done data
                    if (memberData.TreksDone && memberData.TreksDone.length > 0) { // Use memberData prop here
                        const trekPromises = memberData.TreksDone.map(trekId =>
                            getDoc(doc(db, "trek_db", trekId))
                        );
                        const trekResults = await Promise.all(trekPromises);
                        const treksData = trekResults.map(doc => {
                            if (doc.exists()) {
                                return { id: doc.id, ...doc.data() };
                            }
                            return null;
                        }).filter(trek => trek !== null);

                        setTreksDoneData(treksData);
                    }

                    // Fetch treks wishlist data
                    if (memberData.TreksWishlist && memberData.TreksWishlist.length > 0) { // Use memberData prop here
                        const wishlistPromises = memberData.TreksWishlist.map(trekId =>
                            getDoc(doc(db, "trek_db", trekId))
                        );
                        const wishlistResults = await Promise.all(wishlistPromises);
                        const wishlistData = wishlistResults.map(doc => {
                            if (doc.exists()) {
                                return { id: doc.id, ...doc.data() };
                            }
                            return null;
                        }).filter(trek => trek !== null);

                        setTreksWishlistData(wishlistData);
                    }
                } catch (error) {
                    console.error("Error fetching trek details:", error);
                } finally {
                    setLoading(false);
                }
            };
            fetchTreks();
        }
    }, [memberId, db, memberData]); // Add memberData to dependency array

    // Calculate age from date of birth
    const calculateAge = (dob) => {
        if (!dob) return null;
        const birthDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    // Calculate trek experience level
    const calculateExperienceLevel = (treksDone) => {
        if (!treksDone) return { level: 'Beginner', count: 0 };
        const count = treksDone.length;
        if (count === 0) return { level: 'Beginner', count };
        if (count <= 3) return { level: 'Explorer', count };
        if (count <= 7) return { level: 'Experienced', count };
        return { level: 'Expert', count };
    };

    // Format name with first letter capitalization
    const formatName = (name) => {
        if (!name) return '';
        return name.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    if (!member) {
        return (
            <div className="loading-container" style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <ClipLoader color="#ff6e1e" size={50} />
            </div>
        );
    }

    const experienceLevel = calculateExperienceLevel(member.TreksDone);
    const age = calculateAge(member.DateOfBirth);

    // Get limited treks for display
    const visibleTreksDone = showAllTreksDone ? treksDoneData : treksDoneData.slice(0, TREKS_TO_SHOW);
    const visibleWishlist = showAllWishlist ? treksWishlistData : treksWishlistData.slice(0, TREKS_TO_SHOW);

    return (
        <div className="modal-backdrop"
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1050,
                padding: '20px'
            }}
            onClick={onClose}
        >
            <div className="modal-content"
                style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    width: '100%',
                    maxWidth: '700px',
                    maxHeight: '90vh',
                    overflow: 'auto',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
                    position: 'relative'
                }}
                onClick={e => e.stopPropagation()}
            >
                {/* Close button */}
                <button
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        right: '16px',
                        top: '16px',
                        background: 'white',
                        border: '1px solid #e0e0e0',
                        borderRadius: '50%',
                        width: '32px',
                        height: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        zIndex: 5
                    }}
                >
                    <X size={18} />
                </button>

                <div className="p-md-4 p-sm-0" >
                    {/* Header with profile photo and basic info */}
                    <div className="d-flex flex-column flex-md-row">
                        <div
                            style={{
                                width: '120px',
                                height: '120px',
                                flexShrink: 0,
                                marginRight: '24px',
                                marginBottom: '20px',
                                position: 'relative'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                    backgroundColor: '#f8f9fa',
                                    border: '3px solid #ff6e1e',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                {member.ProfilePhotoURL ? (
                                    <img
                                        src={member.ProfilePhotoURL}
                                        alt={formatName(member.FullName)}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                ) : (
                                    <span className="fs-2 text-secondary">👤</span>
                                )}
                            </div>

                            {/* Verified badge */}
                            {member.isMember && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '0',
                                        right: '0',
                                        width: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img
                                        src="/verification-badge.png"
                                        alt="Verified Member"
                                        width="57"
                                        height="47"
                                    />
                                </div>
                            )}
                        </div>

                        <div className="flex-grow-1">
                            <h2 className="mb-2" style={{ fontSize: '1.75rem', fontWeight: '600' }}>
                                {formatName(member.FullName)}
                            </h2>

                            {/* <div className="mb-3">
                                <span
                                    className="badge"
                                    style={{
                                        backgroundColor: '#fff9f5',
                                        color: '#ff6e1e',
                                        border: '1px solid #ffceb0',
                                        padding: '6px 12px',
                                        fontSize: '0.875rem',
                                        fontWeight: '500',
                                        marginRight: '10px'
                                    }}
                                >
                                    {experienceLevel.level}
                                    {experienceLevel.count > 0 &&
                                        ` • ${experienceLevel.count} ${experienceLevel.count === 1 ? 'trek' : 'treks'}`}
                                </span>
                            </div> */}


                            <div className="d-flex flex-wrap gap-3 mb-3">
                                {member.City && (
                                    <div className="d-flex align-items-center text-muted">
                                        <MapPin size={16} className="me-1" />
                                        <span>{formatName(member.City)}</span>
                                    </div>
                                )}

                                {/* {age && (
                                    <div className="d-flex align-items-center text-muted">
                                        <Calendar size={16} className="me-1" />
                                        <span>{age} years</span>
                                    </div>
                                )} */}

                                {member.Profession && (
                                    <div className="d-flex align-items-center text-muted">
                                        <Briefcase size={16} className="me-1" />
                                        <span>{formatName(member.Profession)}</span>
                                    </div>
                                )}
                            </div>


                            {/* Social media links */}
                            {(member.InstagramLink || member.FacebookLink || member.LinkedinLink) && (
                                <div className="d-flex gap-2 mb-3">
                                    {member.InstagramLink && (
                                        <a
                                            href={member.InstagramLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-sm btn-outline-secondary"
                                            style={{
                                                borderRadius: '50%',
                                                width: '36px',
                                                height: '36px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: '#E1306C',
                                                borderColor: '#E1306C'
                                            }}
                                        >
                                            <Instagram size={18} />
                                        </a>
                                    )}

                                    {member.FacebookLink && (
                                        <a
                                            href={member.FacebookLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-sm btn-outline-secondary"
                                            style={{
                                                borderRadius: '50%',
                                                width: '36px',
                                                height: '36px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: '#1877F2',
                                                borderColor: '#1877F2'
                                            }}
                                        >
                                            <Globe size={18} />
                                        </a>
                                    )}

                                    {member.LinkedinLink && (
                                        <a
                                            href={member.LinkedinLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-sm btn-outline-secondary"
                                            style={{
                                                borderRadius: '50%',
                                                width: '36px',
                                                height: '36px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: '#0A66C2',
                                                borderColor: '#0A66C2'
                                            }}
                                        >
                                            <Linkedin size={18} />
                                        </a>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Bio section */}
                    {member.Bio && (
                        <div className="mt-4">
                            <h3 style={{ fontSize: '1.1rem', fontWeight: '600' }}>About</h3>
                            <p
                                style={{
                                    whiteSpace: 'pre-line',
                                    color: '#4a5568',
                                    fontSize: '0.95rem',
                                    lineHeight: '1.6'
                                }}
                            >
                                {member.Bio}
                            </p>
                        </div>
                    )}

                    {member.OutdoorGoals && (
                        <div className="mt-4">
                            <h3 style={{ fontSize: '1.1rem', fontWeight: '600' }}>
                            {`Outdoor Goals for ${new Date().getFullYear()}`}</h3>
                            <p
                                style={{
                                    whiteSpace: 'pre-line',
                                    color: '#4a5568',
                                    fontSize: '0.95rem',
                                    lineHeight: '1.6'
                                }}
                            >
                                {member.OutdoorGoals}
                            </p>
                        </div>
                    )}

                    {/* Treks completed */}
                    {treksDoneData.length > 0 && (
                        <div className="mt-4">
                            <h3 style={{ fontSize: '1.1rem', fontWeight: '600' }}>
                                <CheckCircle size={18} className="me-2" style={{ color: '#22c55e' }} />
                                Treks Completed
                            </h3>
                            <div className="row g-2 mt-1">
                                {visibleTreksDone.map(trek => (
                                    <div key={trek.id} className="col-md-6">
                                        <div
                                            className="p-2"
                                            style={{
                                                backgroundColor: '#f0fdf4',
                                                borderRadius: '6px',
                                                border: '1px solid #dcfce7',
                                                fontSize: '0.9rem'
                                            }}
                                        >
                                            {trek.trek_name}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {treksDoneData.length > TREKS_TO_SHOW && (
                                <div className="text-center mt-2">
                                    <button
                                        className="btn btn-sm btn-link"
                                        onClick={() => setShowAllTreksDone(!showAllTreksDone)}
                                        style={{ color: '#ff6e1e', textDecoration: 'none' }}
                                    >
                                        {showAllTreksDone ? 'Show Less' : `Show All (${treksDoneData.length})`}
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {/* Trek wishlist */}
                    {treksWishlistData.length > 0 && (
                        <div className="mt-4 mb-3">
                            <h3 style={{ fontSize: '1.1rem', fontWeight: '600' }}>
                                <Heart size={18} className="me-2" style={{ color: '#ec4899' }} />
                                Trek Wishlist
                            </h3>
                            <div className="row g-2 mt-1">
                                {visibleWishlist.map(trek => (
                                    <div key={trek.id} className="col-md-6">
                                        <div
                                            className="p-2"
                                            style={{
                                                backgroundColor: '#fdf2f8',
                                                borderRadius: '6px',
                                                border: '1px solid #fce7f3',
                                                fontSize: '0.9rem'
                                            }}
                                        >
                                            {trek.trek_name}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {treksWishlistData.length > TREKS_TO_SHOW && (
                                <div className="text-center mt-2">
                                    <button
                                        className="btn btn-sm btn-link"
                                        onClick={() => setShowAllWishlist(!showAllWishlist)}
                                        style={{ color: '#ff6e1e', textDecoration: 'none' }}
                                    >
                                        {showAllWishlist ? 'Show Less' : `Show All (${treksWishlistData.length})`}
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MemberProfileModal;