import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { Copy } from 'lucide-react';
import '../Css/DashboardNew.css';

function WorkshopDashboard() {
  const navigate = useNavigate();
  const db = getFirestore();

  // State management
  const [workshopUsers, setWorkshopUsers] = useState([]);
  const [workshopPayments, setWorkshopPayments] = useState([]);
  const [totalWorkshopRevenue, setTotalWorkshopRevenue] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState("users");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

 const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setSuccess(`Copied ${text} to clipboard!`);
    setTimeout(() => setSuccess(''), 3000); // Clear success message after 3 seconds
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const usersCollection = collection(db, "UserTable");
        const paymentsCollection = collection(db, "payments");

        // Query for users with workshop access
        const userQuery = query(
          usersCollection, 
          where("workshopAccess", "==", true)
        );

        // Query for payments
        const paymentsQuery = query(
          paymentsCollection, 
          orderBy("createdAt", "desc")
        );

        const [usersSnapshot, paymentsSnapshot] = await Promise.all([
          getDocs(userQuery),
          getDocs(paymentsQuery)
        ]);

        // Process users data
        const usersData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          Email: doc.data().Email || '',
          FullName: doc.data().FullName || '',
          WhatsAppNo: doc.data().WhatsAppNo || '',
          UserCountryCode: doc.data().UserCountryCode || '',
          AccountStatus: doc.data().AccountStatus || 'Inactive',
          CreatedOn: doc.data().CreatedOn,
          workshopAccess: doc.data().workshopAccess || false,
        }));

        // Process payments data
        const paymentsData = paymentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          email: doc.data().email || '',
          amount: doc.data().metadata.rawResponse.amount || 0,
          status: doc.data().status || '',
          createdAt: doc.data().createdAt,
          productName: doc.data().notes.productName || '',
          paymentId: doc.data().paymentId || '',
          method: doc.data().method || '',
          currency: doc.data().currency || 'INR'
        }));

        // Calculate total revenue from successful payments
        const totalRevenue = paymentsData.reduce((sum, payment) => 
          payment.status === "captured" ? sum + (payment.amount / 100) : sum, 0
        );

        setWorkshopUsers(usersData);
        setWorkshopPayments(paymentsData);
        setTotalWorkshopRevenue(totalRevenue);
      } catch (error) {
        setError('Error fetching data: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Utility function for timestamp formatting
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "N/A";
    return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).toLocaleString();
  };

  // Utility function for currency formatting
  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(amount);
  };

  // Filtering logic
  const filteredUsers = workshopUsers.filter(user => 
    user.Email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.FullName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.id?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredPayments = workshopPayments.filter(payment => 
    payment.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    payment.paymentId?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="dashboard-root">
      <div className="dashboard-container">
        {/* Top Navigation */}
        <div className="top-nav">
          <div className="nav-links">
            <button className="nav-link" onClick={() => navigate('/dashboard')}>Users</button>
            <button className="nav-link" onClick={() => navigate('/dashboard/add-product')}>Products</button>
            <button className="nav-link" onClick={() => navigate('/dashboard/alerts')}>Alerts</button>
            <button className="nav-link active" onClick={() => navigate('/dashboard/workshop')}>
              Payments ({workshopPayments.length})
            </button>
          </div>
          <button className="back-button" onClick={() => navigate('/products')}>Back</button>
        </div>

        <div className="dashboard-content">
          {/* Stats Cards */}
          <div className="tab-container">
            <div className="tab-buttons">
              <button
                className={`tab-button ${activeTab === 'users' ? 'active' : ''}`}
                onClick={() => setActiveTab('users')}
              >
                Workshop Users ({workshopUsers.length})
              </button>
              <button
                className={`tab-button ${activeTab === 'payments' ? 'active' : ''}`}
                onClick={() => setActiveTab('payments')}
              >
                Payments ({workshopPayments.length})
              </button>
            </div>
            <div className="revenue-display">
              Total Revenue: {formatAmount(totalWorkshopRevenue)}
            </div>
          </div>

          {/* Search Bar */}
          <div className="search-container">
            <input
              type="text"
              className="search-input"
              placeholder={activeTab === 'users' ? "Search users by email or name..." : "Search payments by email or payment ID..."}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          {/* Messages */}
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}

          {/* Table */}
          {loading ? (
            <div className="loading">Loading...</div>
          ) : (
            <div className="table-wrapper">
              <div className="table-container">
                {activeTab === 'users' ? (
                  <table className="users-table">
                    <thead>
                      <tr>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>WhatsApp</th>
                        <th>Created On</th>
                        <th>Status</th>
                        <th>Payment Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUsers.map((user) => {
                        const userPayments = workshopPayments.filter(
                          payment => payment.email.toLowerCase() === user.Email.toLowerCase()
                        );
                        const hasValidPayment = userPayments.some(
                          payment => payment.status === "captured"
                        );

                        return (
                          <tr key={user.id}>
                            <td>{user.FullName || 'N/A'}</td>
                            <td>{user.Email}</td>
                            <td>{user.WhatsAppNo ? `${user.UserCountryCode} ${user.WhatsAppNo}` : 'N/A'}</td>
                            <td>{formatTimestamp(user.CreatedOn)}</td>
                            <td>
                              <span className={`status-badge ${user.AccountStatus === 'Active' ? 'status-active' : 'status-inactive'}`}>
                                {user.AccountStatus}
                              </span>
                            </td>
                            <td>
                              <span className={`status-badge ${hasValidPayment ? 'status-active' : 'status-inactive'}`}>
                                {hasValidPayment ? 'Paid' : 'Unpaid'}
                              </span>
                            </td>
                            <td>
                              <button className="copy-button" onClick={() => copyToClipboard(user.Email)}>
                                <Copy size={16} />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <table className="users-table">
                    <thead>
                      <tr>
                        <th>Payment ID</th>
                        <th>Email</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Payment Method</th>
                        <th>Product</th>
                        <th>Created On</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredPayments.map((payment) => (
                        <tr key={payment.id}>
                          <td>{payment.paymentId}</td>
                          <td>{payment.email}</td>
                          <td>{formatAmount(payment.amount / 100)}</td>
                          <td>
                            <span className={`status-badge ${payment.status === 'captured' ? 'status-active' : 'status-inactive'}`}>
                              {payment.status}
                            </span>
                          </td>
                          <td>{payment.method}</td>
                          <td>{payment.productName}</td>
                          <td>{formatTimestamp(payment.createdAt)}</td>
                          <td>
                            <button className="copy-button" onClick={() => copyToClipboard(payment.id)}>
                              <Copy size={16} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {(activeTab === 'users' ? filteredUsers : filteredPayments).length === 0 && (
                  <div className="no-results">No {activeTab} found matching your criteria</div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WorkshopDashboard;