import React, { useState, useEffect } from "react";
import { PlusCircle, Trash } from "lucide-react";
import EmojiPicker from 'emoji-picker-react';
import {
  getFirestore,
  collection,
  addDoc,
  getDoc,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom"
import '../Css/AdminProductManagement.css';

const db = getFirestore();

// Emoji list for selection
const EMOJI_LIST = [
  "📦", "🚀", "💡", "🌟", "🔧", "📊", "🎨", "💻", "📱", "🌐",
  "🤖", "📈", "🛒", "🎮", "📝", "🔍", "💰", "🎳", "🚲", "🎉"
];

const AdminProductManagement = () => {
  const [newProduct, setNewProduct] = useState({
    title: "",
    description: "",
    icon: "",
    path: "",
    externalLink: "",
    requiresAccess: false,
    memberOnly: false,
  });
  const [products, setProducts] = useState([]);
  const [hoveredProduct, setHoveredProduct] = useState(null);
  const [userId, setUserId] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [cookies] = useCookies(["userId"]);
  const navigate = useNavigate();

  // Fetch userId from cookies or localStorage
  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem("userId");
    const userIdFromCookies = cookies.userId;
    const userId = userIdFromLocalStorage || userIdFromCookies;

    if (userId) {
      setUserId(userId);
    } else {
      navigate("/login");
    }
  }, [cookies, navigate]);

  // Fetch products from Firestore
  useEffect(() => {
    if (userId) {
      const unsubscribe = onSnapshot(collection(db, "Products"), (snapshot) => {
        const fetchedProducts = snapshot.docs
          .filter(doc => !doc.data().isDeleted)
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
        setProducts(fetchedProducts);
      });

      return () => unsubscribe();
    }
  }, [userId]);

  // Add product to Firestore
  const addProduct = async () => {
    if (!newProduct.title || !newProduct.description) {
      alert("Please fill in all required fields.");
      return;
    }

    try {
      await addDoc(collection(db, "Products"), {
        ...newProduct,
        userId: userId,
        isDeleted: false,
        createdAt: new Date(),
      });

      setNewProduct({
        title: "",
        description: "",
        icon: "",
        path: "",
        externalLink: "",
        requiresAccess: false,
        memberOnly: false,
      });
      setShowEmojiPicker(false);
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  // Soft delete product
  const deleteProduct = async (id) => {
    try {
      const productRef = doc(db, "Products", id);
      const docSnap = await getDoc(productRef);

      if (docSnap.exists()) {
        await updateDoc(productRef, {
          isDeleted: true,
          deletedAt: new Date()
        });
      } else {
        console.error("Product document does not exist");
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  // Handle product click
  const handleProductClick = (product) => {
    if (product.path) {
      navigate(product.path);
    } else if (product.externalLink) {
      window.open(product.externalLink, '_blank');
    }
  };

  // Emoji selection handler
  const handleEmojiClick = (emojiData) => {
    setNewProduct({
      ...newProduct,
      icon: emojiData.emoji
    });
    setShowEmojiPicker(false);
  };

  return (

    <div className="container-fluid p-4 bg-gray-100">
      <div className="admin-product-management-top-nav">
        <div className="admin-product-management-nav-links">
        <button className="admin-product-management-nav-link" onClick={() => navigate('/dashboard')}>Users</button>
          <button className="admin-product-management-nav-link active" onClick={() => navigate('/dashboard/add-product')}>Products</button>
          <button className="admin-product-management-nav-link" onClick={() => navigate('/dashboard/alerts')}>Alerts</button>
          <button className="admin-product-management-nav-link" onClick={() => navigate('/dashboard/workshop')}>Payments</button>
        </div>
        <button className="admin-product-management-back-button" onClick={() => navigate('/dashboard')}>Back</button>
      </div>
      <style>{`
        .card-header-blue {
          background-color: #2563eb;
          color: white;
        }
        .btn-primary {
          background-color: #2563eb;
          border-color: #2563eb;
        }
        .btn-primary:hover {
          background-color: #1d4ed8;
          border-color: #1d4ed8;
        }
        .badge-blue {
          background-color: #2563eb;
          color: white;
        }
      `}</style>
      <div className="row">
        {/* Product Form */}
        <div className="col-md-4 mb-4">
          <div className="card border-0 shadow-lg rounded-lg h-100">
            <div className="card-header card-header-blue">
              <h4 className="mb-0">Add Product</h4>
            </div>
            <div className="card-body">
              {/* Title */}
              <div className="mb-3">
                <label className="form-label text-gray-800">Title</label>
                <input
                  type="text"
                  className="form-control border-gray-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50"
                  placeholder="Product Title"
                  value={newProduct.title}
                  onChange={(e) =>
                    setNewProduct({ ...newProduct, title: e.target.value })
                  }
                />
              </div>

              {/* Description */}
              <div className="mb-3">
                <label className="form-label text-gray-800">Description</label>
                <textarea
                  className="form-control border-gray-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50"
                  placeholder="Product Description"
                  value={newProduct.description}
                  onChange={(e) =>
                    setNewProduct({ ...newProduct, description: e.target.value })
                  }
                />
              </div>

              {/* Emoji Picker Modification */}
              <div className="mb-3 relative">
                <label className="form-label text-gray-800">Icon (Emoji)</label>
                <div className="flex items-center">
                  <input
                    type="text"
                    className="form-control border-gray-300 rounded-md"
                    placeholder="Select Emoji"
                    value={newProduct.icon}
                    onChange={(e) =>
                      setNewProduct({ ...newProduct, icon: e.target.value })
                    }
                    onFocus={() => setShowEmojiPicker(true)}
                  />
                  <button
                    className="ml-2 p-2 bg-gray-100 rounded-md"
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  >
                    {newProduct.icon || "🙂"}
                  </button>
                </div>
                {showEmojiPicker && (
                  <div className="absolute z-10 mt-1 shadow-lg">
                    <EmojiPicker
                      onEmojiClick={handleEmojiClick}
                      width={300}
                      height={400}
                    />
                  </div>
                )}
              </div>

              {/* Path */}
              <div className="mb-3">
                <label className="form-label text-gray-800">Path</label>
                <input
                  type="text"
                  className="form-control border-gray-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50"
                  placeholder="Product Path"
                  value={newProduct.path}
                  onChange={(e) =>
                    setNewProduct({ ...newProduct, path: e.target.value })
                  }
                />
              </div>

              {/* External Link */}
              <div className="mb-3">
                <label className="form-label text-gray-800">External Link</label>
                <input
                  type="text"
                  className="form-control border-gray-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50"
                  placeholder="External Link"
                  value={newProduct.externalLink}
                  onChange={(e) =>
                    setNewProduct({ ...newProduct, externalLink: e.target.value })
                  }
                />
              </div>

              {/* Premium Access */}
              <div className="mb-3">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-check-input mr-2 text-sky-500"
                    id="requiresAccess"
                    checked={newProduct.requiresAccess}
                    onChange={(e) =>
                      setNewProduct({
                        ...newProduct,
                        requiresAccess: e.target.checked,
                        memberOnly: e.target.checked ? false : newProduct.memberOnly, // Uncheck the other
                      })
                    }
                  />
                  <label className="text-gray-800" htmlFor="requiresAccess">
                    Paid Resource
                  </label>
                </div>
              </div>

              <div className="mb-3">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-check-input mr-2 text-sky-500"
                    id="memberOnly"
                    checked={newProduct.memberOnly}
                    onChange={(e) =>
                      setNewProduct({
                        ...newProduct,
                        memberOnly: e.target.checked,
                        requiresAccess: e.target.checked ? false : newProduct.requiresAccess, // Uncheck the other
                      })
                    }
                  />
                  <label className="text-gray-800" htmlFor="memberOnly">
                    Member Only Access
                  </label>
                </div>
              </div>


              {/* Add Button */}
              <button
                className="w-full py-2 btn btn-primary"
                onClick={addProduct}
              >
                <PlusCircle size={16} className="inline-block mr-2" /> Add Product
              </button>
            </div>
          </div>
        </div>

        {/* Product Preview and Other Products */}
        <div className="col-md-8">
          {/* Product Preview */}
          <div className="card border-0 shadow-lg rounded-lg mb-4">
            <div className="card-header card-header-blue">
              <h4 className="mb-0">Product Preview</h4>
            </div>
            <div className="card-body">
              <div
                className="card border-0 shadow-sm"
                style={{ width: "50%", margin: "0 auto" }}
                onClick={() => navigate('../products')}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center mb-3">
                    <span style={{ fontSize: "2rem", marginRight: "1rem" }}>
                      {newProduct.icon || "📦"}
                    </span>
                    <div>
                      <h5 className="card-title mb-0">
                        {newProduct.title || "Product Title"}
                      </h5>
                      {newProduct.requiresAccess && (
                        <span className="badge badge-blue">Paid</span>
                      )}
                      {newProduct.memberOnly && (
                        <span className="badge" style={{ backgroundColor: "green", color: "white" }}>
                          Member Only
                        </span>
                      )}
                    </div>
                  </div>
                  <p className="card-text text-gray-700">
                    {newProduct.description || "Product Description"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Other Products */}
          <div className="card border-0 shadow-lg rounded-lg">
            <div className="card-header card-header-blue">
              <h4 className="mb-0">Other Products</h4>
            </div>
            <div className="card-body">
              <div className="row g-4">
                {products.map((product) => (
                  <div
                    key={product.id}
                    className="col-md-6"
                    onMouseEnter={() => setHoveredProduct(product.id)}
                    onMouseLeave={() => setHoveredProduct(null)}
                  >
                    <div
                      className="card h-100 shadow-hover transition-all hover:shadow-lg relative cursor-pointer border-0"
                      onClick={() => handleProductClick(product)}
                    >
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-3">
                          <span style={{ fontSize: "2rem", marginRight: "1rem" }}>
                            {product.icon || "📦"}
                          </span>
                          <div>
                            <h5 className="card-title mb-0">{product.title}</h5>
                            {product.requiresAccess && (
                              <span className="badge badge-blue">Paid</span>
                            )}
                            {product.memberOnly && (
                              <span className="badge" style={{ backgroundColor: "green", color: "white" }}>Member Only</span>
                            )}
                          </div>
                        </div>
                        <p className="card-text text-gray-700">
                          {product.description}
                        </p>
                        {hoveredProduct === product.id && (
                          <button
                            className="btn btn-danger btn-sm absolute top-2 right-2 shadow-md"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteProduct(product.id);
                            }}
                          >
                            <Trash size={16} className="me-1" />
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProductManagement;


// // AdminProductManagement.js
// import React, { useState, useEffect } from "react";
// import { PlusCircle, Trash } from "lucide-react";
// import EmojiPicker from 'emoji-picker-react';
// import {
//   getFirestore,
//   collection,
//   addDoc,
//   getDoc,
//   doc,
//   onSnapshot,
//   updateDoc,
// } from "firebase/firestore";
// import { useCookies } from "react-cookie";
// import { useNavigate } from "react-router-dom";
// import '../Css/AdminProductManagement.css'; // Import the new CSS file

// const db = getFirestore();

// // Emoji list for selection
// const EMOJI_LIST = [
//   "📦", "🚀", "💡", "🌟", "🔧", "📊", "🎨", "💻", "📱", "🌐",
//   "🤖", "📈", "🛒", "🎮", "📝", "🔍", "💰", "🎳", "🚲", "🎉"
// ];

// const AdminProductManagement = () => {
//   const [newProduct, setNewProduct] = useState({
//     title: "",
//     description: "",
//     icon: "",
//     path: "",
//     externalLink: "",
//     requiresAccess: false,
//     memberOnly: false,
//   });
//   const [products, setProducts] = useState([]);
//   const [hoveredProduct, setHoveredProduct] = useState(null);
//   const [userId, setUserId] = useState(null);
//   const [showEmojiPicker, setShowEmojiPicker] = useState(false);
//   const [cookies] = useCookies(["userId"]);
//   const navigate = useNavigate();
//   const [success, setSuccess] = useState('');
//   const [error, setError] = useState('');
//   const [loading, setLoading] = useState(false);


//   // Fetch userId from cookies or localStorage
//   useEffect(() => {
//     const userIdFromLocalStorage = localStorage.getItem("userId");
//     const userIdFromCookies = cookies.userId;
//     const userId = userIdFromLocalStorage || userIdFromCookies;

//     if (userId) {
//       setUserId(userId);
//     } else {
//       navigate("/login");
//     }
//   }, [cookies, navigate]);

//   // Fetch products from Firestore
//   useEffect(() => {
//     if (userId) {
//       const unsubscribe = onSnapshot(collection(db, "Products"), (snapshot) => {
//         const fetchedProducts = snapshot.docs
//           .filter(doc => !doc.data().isDeleted)
//           .map((doc) => ({
//             id: doc.id,
//             ...doc.data(),
//           }));
//         setProducts(fetchedProducts);
//       });

//       return () => unsubscribe();
//     }
//   }, [userId]);

//   // Add product to Firestore
//   const addProduct = async () => {
//     if (!newProduct.title || !newProduct.description) {
//       setError("Please fill in all required fields.");
//       return;
//     }
//     setError('');
//     setSuccess('');
//     setLoading(true);

//     try {
//       await addDoc(collection(db, "Products"), {
//         ...newProduct,
//         userId: userId,
//         isDeleted: false,
//         createdAt: new Date(),
//       });

//       setNewProduct({
//         title: "",
//         description: "",
//         icon: "",
//         path: "",
//         externalLink: "",
//         requiresAccess: false,
//         memberOnly: false,
//       });
//       setShowEmojiPicker(false);
//       setSuccess('Product added successfully!');
//     } catch (error) {
//       console.error("Error adding product:", error);
//       setError('Error adding product: ' + error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Soft delete product
//   const deleteProduct = async (id) => {
//     setError('');
//     setSuccess('');
//     setLoading(true);
//     try {
//       const productRef = doc(db, "Products", id);
//       const docSnap = await getDoc(productRef);

//       if (docSnap.exists()) {
//         await updateDoc(productRef, {
//           isDeleted: true,
//           deletedAt: new Date()
//         });
//         setSuccess('Product deleted successfully!');
//       } else {
//         console.error("Product document does not exist");
//         setError('Product document does not exist');
//       }
//     } catch (error) {
//       console.error("Error deleting product:", error);
//       setError('Error deleting product: ' + error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Handle product click
//   const handleProductClick = (product) => {
//     if (product.path) {
//       navigate(product.path);
//     } else if (product.externalLink) {
//       window.open(product.externalLink, '_blank');
//     }
//   };

//   // Emoji selection handler
//   const handleEmojiClick = (emojiData) => {
//     setNewProduct({
//       ...newProduct,
//       icon: emojiData.emoji
//     });
//     setShowEmojiPicker(false);
//   };

//   return (
//     <div className="admin-product-management-root">
//       <div className="admin-product-management-container">
//         {/* Top Navigation */}
// <div className="admin-product-management-top-nav">
//   <div className="admin-product-management-nav-links">
//     <button className="admin-product-management-nav-link active" onClick={() => navigate('/dashboard/add-product')}>Products</button>
//     <button className="admin-product-management-nav-link" onClick={() => navigate('/dashboard/alerts')}>Alerts</button>
//     <button className="admin-product-management-nav-link" onClick={() => navigate('/dashboard/payments')}>Payments</button>
//     <button className="admin-product-management-nav-link" onClick={() => navigate('/dashboard/users')}>Users</button>
//   </div>
//   <button className="admin-product-management-back-button" onClick={() => navigate('/dashboard/users')}>Back</button>
// </div>

//         <div className="admin-product-management-content">
//           {/* Add Product and Preview Section */}
//           <div className="add-product-preview-section">
//             {/* Product Preview - Moved to be FIRST */}
//             <div className="product-preview-card">
//               <h4 className="product-preview-header">Product Preview</h4>
//               <div
//                 className="card border-0 shadow-sm"
//                 style={{ width: "100%", maxWidth: '400px', margin: "0 auto" }}
//                 onClick={() => navigate('../products')}
//               >
//                 <div className="card-body">
//                   <div className="preview-content">
//                     <span className="preview-icon">
//                       {newProduct.icon || "📦"}
//                     </span>
//                     <div className="preview-details">
//                       <h5>
//                         {newProduct.title || "Product Title"}
//                       </h5>
//                       {newProduct.requiresAccess && (
//                         <span className="badge badge-blue">Paid</span>
//                       )}
//                       {newProduct.memberOnly && (
//                         <span className="badge badge-member">
//                           Member Only
//                         </span>
//                       )}
//                     </div>
//                   </div>
//                   <p className="preview-description">
//                     {newProduct.description || "Product Description"}
//                   </p>
//                 </div>
//               </div>
//             </div>

//             {/* Add Product Card - Moved to be SECOND */}
//             <div className="add-product-card">
//               <h4 className="add-product-header">Add Product</h4>
//               {/* Title */}
//               <div className="form-group">
//                 <label className="form-label">Title</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   placeholder="Product Title"
//                   value={newProduct.title}
//                   onChange={(e) =>
//                     setNewProduct({ ...newProduct, title: e.target.value })
//                   }
//                 />
//               </div>

//               {/* Description */}
//               <div className="form-group">
//                 <label className="form-label">Description</label>
//                 <textarea
//                   className="form-control"
//                   placeholder="Product Description"
//                   value={newProduct.description}
//                   onChange={(e) =>
//                     setNewProduct({ ...newProduct, description: e.target.value })
//                   }
//                 />
//               </div>

//               {/* Emoji Picker Modification */}
//               <div className="form-group">
//                 <label className="form-label">Icon (Emoji)</label>
//                 <div className="emoji-picker-input-group">
//                   <input
//                     type="text"
//                     className="form-control"
//                     placeholder="Select Emoji"
//                     value={newProduct.icon}
//                     onChange={(e) =>
//                       setNewProduct({ ...newProduct, icon: e.target.value })
//                     }
//                     onFocus={() => setShowEmojiPicker(true)}
//                   />
//                   <button
//                     className="emoji-button"
//                     onClick={() => setShowEmojiPicker(!showEmojiPicker)}
//                   >
//                     {newProduct.icon || "📦"}
//                   </button>
//                 </div>
//                 {showEmojiPicker && (
//                   <div className="emoji-picker-container">
//                     <EmojiPicker
//                       onEmojiClick={handleEmojiClick}
//                       width={300}
//                       height={400}
//                     />
//                   </div>
//                 )}
//               </div>

//               {/* Path */}
//               <div className="form-group">
//                 <label className="form-label">Path</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   placeholder="Product Path"
//                   value={newProduct.path}
//                   onChange={(e) =>
//                     setNewProduct({ ...newProduct, path: e.target.value })
//                   }
//                 />
//               </div>

//               {/* External Link */}
//               <div className="form-group">
//                 <label className="form-label">External Link</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   placeholder="External Link"
//                   value={newProduct.externalLink}
//                   onChange={(e) =>
//                     setNewProduct({ ...newProduct, externalLink: e.target.value })
//                   }
//                 />
//               </div>

//               {/* Premium Access */}
//               <div className="form-group">
//                 <div className="flex items-center">
//                   <input
//                     type="checkbox"
//                     className="form-check-input mr-2 text-sky-500"
//                     id="requiresAccess"
//                     checked={newProduct.requiresAccess}
//                     onChange={(e) =>
//                       setNewProduct({
//                         ...newProduct,
//                         requiresAccess: e.target.checked,
//                         memberOnly: e.target.checked ? false : newProduct.memberOnly, // Uncheck the other
//                       })
//                     }
//                   />
//                   <label className="text-gray-800" htmlFor="requiresAccess">
//                     Paid Resource
//                   </label>
//                 </div>
//               </div>

//               <div className="form-group">
//                 <div className="flex items-center">
//                   <input
//                     type="checkbox"
//                     className="form-check-input mr-2 text-sky-500"
//                     id="memberOnly"
//                     checked={newProduct.memberOnly}
//                     onChange={(e) =>
//                       setNewProduct({
//                         ...newProduct,
//                         memberOnly: e.target.checked,
//                         requiresAccess: e.target.checked ? false : newProduct.requiresAccess, // Uncheck the other
//                       })
//                     }
//                   />
//                   <label className="text-gray-800" htmlFor="memberOnly">
//                     Member Only Access
//                   </label>
//                 </div>
//               </div>


//               {/* Add Button */}
//               <button
//                 className="add-product-button"
//                 onClick={addProduct}
//                 disabled={loading}
//               >
//                 <PlusCircle size={16} className="inline-block mr-2" /> {loading ? 'Adding...' : 'Add Product'}
//               </button>
//               {error && <div className="error-message">{error}</div>}
//               {success && <div className="success-message">{success}</div>}
//             </div>
//           </div>

//           {/* Products Section */}
//           <h4 className="product-preview-header">Products</h4> {/* Heading here now */}
//           <div className="products-section">
//             {products.map((product) => (
//               <div
//                 key={product.id}
//                 className="product-card"
//                 onMouseEnter={() => setHoveredProduct(product.id)}
//                 onMouseLeave={() => setHoveredProduct(null)}
//                 onClick={() => handleProductClick(product)}
//               >
//                 <span className="product-icon">
//                   {product.icon || "📦"}
//                 </span>
//                 <h5 className="product-title">{product.title}</h5>
//                 <p className="product-description">
//                   {product.description}
//                 </p>
//                 <div className="product-badge">
//                   {product.requiresAccess && (
//                     <span className="badge badge-blue">Paid</span>
//                   )}
//                   {product.memberOnly && (
//                     <span className="badge badge-member">Member Only</span>
//                   )}
//                 </div>
//                 {hoveredProduct === product.id && (
//                   <button
//                     className="delete-product-button"
//                     onClick={(e) => {
//                       e.stopPropagation();
//                       deleteProduct(product.id);
//                     }}
//                     disabled={loading}
//                   >
//                     <Trash size={16} className="me-1" />
//                     Delete
//                   </button>
//                 )}
//               </div>
//             ))}
//             {products.length === 0 && !loading && (
//               <div className="no-results">No products available</div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdminProductManagement;