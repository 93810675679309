import React, { useState, useEffect } from 'react';
import { ArrowLeft, Heart, Plus, X, Edit } from 'lucide-react';
import { ClipLoader } from 'react-spinners';
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
  arrayUnion,
  arrayRemove,
  addDoc
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import '../Css/HikingGearList.css';
import MemberProfileModal from './MemberProfileModal';

const DEFAULT_CATEGORY_IMAGES = {
  '🛌 Sleep System': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJfIAzKZtPfP2dTkVR-7Yz15nrbXTJwHPIUQ&s',
  '👕 Clothing': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFPeptRZXh36TIX7sUf1extYfhNcuzy4HeUw&s',
  '👟 Footwear': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNwpzsFQ6W66yXdjHoGssuJLlRaNZc1Ve2Dw&s',
  '🍳 Cookware': 'https://thumbs.dreamstime.com/b/boiling-water-metal-pot-over-burning-campfire-cooking-nature-cartoon-icon-335537019.jpg',
  '🧭 Navigation & Safety': 'https://st5.depositphotos.com/15430914/74084/v/450/depositphotos_740847272-stock-illustration-industrial-safety-engineer-holding-safety.jpg',
  '🎒 Bagpacks': 'https://static.vecteezy.com/system/resources/previews/030/692/115/non_2x/rucksack-2d-cartoon-vector-illustration-on-white-backgroun-free-photo.jpg',
  '⚡️ Gadgets': 'https://media.istockphoto.com/id/943940436/vector/cartoon-camera-on-tripod.jpg?s=612x612&w=0&k=20&c=CVqdqQvPTB0gUeIxP7vB-SSrAm_OzL8Yti9aMPlEQpE=',
  'Others': 'https://media.istockphoto.com/id/1401812750/vector/climbing-vector-flat-elements.jpg?s=612x612&w=0&k=20&c=PAOQ9BdKJlXS6hVKYmwC10TUIle4FePTiEfRplqwLBA='
};

const getDefaultImageForCategory = (broadCategory) => {
  return DEFAULT_CATEGORY_IMAGES[broadCategory] || DEFAULT_CATEGORY_IMAGES['Others'];
};

const NotesModal = ({ isOpen, onClose, initialNotes, onSave }) => {
  const [notes, setNotes] = useState(initialNotes || '');

  useEffect(() => {
    if (isOpen) {
      setNotes(initialNotes || '');
    }
  }, [isOpen, initialNotes]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          <X size={20} />
        </button>

        <h2>Why do you recommend this gear?</h2>
        <p className="modal-subtitle">Please share why you recommend this gear and who is it useful for?</p>

        <textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          className="notes-edit-input"
          placeholder="Share your thoughts about this product..."
          rows={6}
        />

        <div className="modal-buttons">
          <button
            onClick={() => onSave(notes)}
            className="save-notes-button orange-button"
          >
            Save
          </button>
          <button
            onClick={onClose}
            className="skip-notes-button"
          >
            Skip adding a note
          </button>
        </div>
      </div>
    </div>
  );
};

const GearCard = ({ item, userId, userData, onHeartClick, isUsed, userNames, onNotesUpdate, onMemberProfileClick, onOpenNotesModal }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState(item.ImageLink);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHearted, setIsHearted] = useState(item.UsedBy?.includes(userId) || false);
  const [heartLoading, setHeartLoading] = useState(false);
  const [editedNotes, setEditedNotes] = useState(item.Notes || '');
  const [lastClickTime, setLastClickTime] = useState(0);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const loadImage = async () => {
      try {
        const img = new Image();

        img.onload = () => {
          if (isMounted) {
            setIsLoading(false);
          }
        };

        img.onerror = () => {
          if (isMounted) {
            const defaultSrc = getDefaultImageForCategory(item.Category);
            if (imageSrc !== defaultSrc) {
              setImageSrc(defaultSrc);
            } else {
              setIsLoading(false);
            }
          }
        };

        img.src = imageSrc;
      } catch (error) {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    loadImage();

    return () => {
      isMounted = false;
    };
  }, [imageSrc, item.Category]);

  useEffect(() => {
    setImageSrc(item.ImageLink);
  }, [item.ImageLink]);

  const handleHeartClick = async () => {
    const now = Date.now();
    if (now - lastClickTime < 300) return;
    setLastClickTime(now);

    if (!userId) {
      alert('Please log in to like items');
      return;
    }

    const newHeartedState = !isHearted;
    setIsHearted(newHeartedState);
    setHeartLoading(true);

    try {
      const success = await onHeartClick(item.id, newHeartedState);
      if (success && newHeartedState) {
        onOpenNotesModal(item.id, editedNotes);
      }
    } catch (error) {
      console.error('Error updating heart:', error);
      setIsHearted(!newHeartedState);
    } finally {
      setHeartLoading(false);
    }
  };


  const getDisplayedNotes = () => {
    if (!editedNotes) return [];

    if (isExpanded) return [editedNotes];

    let words = editedNotes.split(" ");
    if (words.length > 20) {
      return [words.slice(0, 20).join(" ") + "..."];
    }
    return [editedNotes];
  };

  const shouldShowReadMore = editedNotes && editedNotes.split(" ").length > 20;

  const getRecommenderNames = () => {
    const allUserIds = [
      ...(item.RecommendedBy || []),
      ...(item.UsedBy || [])
    ];

    const uniqueUserIds = [...new Set(allUserIds)];

    return uniqueUserIds.map(uid => {
      const name = userNames[uid] || 'Unknown';
      const firstName = name.split(' ')[0];
      return {
        id: uid,
        name: firstName,
        isCurrentUser: uid === userId
      };
    });
  };

  return (
    <div className="gear-card">
      <div className="image-section">
        {isLoading && (
          <div className="image-placeholder">
            <ClipLoader color="#ff6e1e" size={50} />
          </div>
        )}
        <img
          src={imageSrc}
          alt={item.ProductName}
          className="product-image"
          style={{
            display: isLoading ? 'none' : 'block',
            objectFit: 'contain'
          }}
        />
      </div>
      <div className="card-content">
        <div className="product-header">
          <a
            href={item.ProductLink}
            target="_blank"
            rel="noopener noreferrer"
            className="product-name-link"
          >
            <h2 className="product-name">{item.ProductName}</h2>
          </a>
          <button
            className={`heart-button ${isHearted ? 'hearted' : ''}`}
            onClick={handleHeartClick}
            disabled={heartLoading || !userId}
          >
            {heartLoading ? (
              <ClipLoader color={isHearted ? "#ff4444" : "#9ca3af"} size={16} />
            ) : (
              <Heart size={20} className="heart-icon" fill={isHearted ? "currentColor" : "none"} />
            )}
          </button>
        </div>

        <div className="recommender">
          <span className="recommender-label">Recommended by:</span>
          {getRecommenderNames().map((user, index, array) => (
            <React.Fragment key={user.id}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onMemberProfileClick(user.id);
                }}
                className="recommender-link"
              >
                {user.name}
              </a>
              {index < array.length - 1 ? ', ' : ''}
            </React.Fragment>
          ))}
        </div>

        <div className="notes-section">
          {getDisplayedNotes().map((note, index) => (
            <div key={index} className="note-item">
              <span>{note}</span>
            </div>
          ))}
          {isHearted && editedNotes && (
            <button
              className="edit-notes-button"
              onClick={() => onOpenNotesModal(item.id, editedNotes)}
            >
              <Edit size={14} /> Update your note
            </button>
          )}
          {shouldShowReadMore && (
            <button
              className="read-more-button"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? "Show less" : "Read more"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const AddGearModal = ({ isOpen, onClose, onSubmit, categories, userName, userId }) => {
  const [formData, setFormData] = useState({
    Category: '',
    ProductName: '',
    ProductLink: '',
    ImageLink: '',
    Notes: '',
    RecommendedBy: [userId],
    UsedBy: [userId]
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.Category) {
      setError('Category is required');
      return;
    }

    if (!formData['ProductName'] || formData['ProductName'].trim() === '') {
      setError('Product Name is required');
      return;
    }

    setLoading(true);
    try {
      await onSubmit({
        ...formData,
        RecommendedBy: [userId],
        UsedBy: [userId]
      });
      onClose();
    } catch (err) {
      setError('Failed to add gear. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          <X size={20} />
        </button>

        <h2>Add New Gear</h2>
        {error && <p className="error-message">{error}</p>}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Category*</label>
            <select
              name="Category"
              value={formData.Category}
              onChange={handleChange}
              required
            >
              <option value="">Select a category</option>
              {categories.filter(cat => cat !== 'All').map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Product Name*</label>
            <input
              type="text"
              name="ProductName"
              value={formData.ProductName}
              onChange={handleChange}
              required
              maxLength={100}
            />
          </div>

          <div className="form-group">
            <label>Product Link (URL)</label>
            <input
              type="url"
              name="ProductLink"
              value={formData.ProductLink}
              onChange={handleChange}
              placeholder="https://example.com/product"
            />
          </div>

          <div className="form-group">
            <label>Image URL</label>
            <div className="image-url-note">
              To copy the image address link, simply right-click on the image and select "Copy image address" or "Copy image URL," depending on your browser. Once copied, you can paste the link here. Also, you can skip this step will add it later.
            </div>
            <input
              type="url"
              name="ImageLink"
              value={formData.ImageLink}
              onChange={handleChange}
              placeholder="https://example.com/image.jpg"
            />
          </div>

          <div className="form-group">
            <label>Please share why you recommend this gear and who is it useful for?</label>
            <textarea
              name="Notes"
              value={formData.Notes}
              onChange={handleChange}
              rows={3}
              maxLength={500}
            />
          </div>

          <div className="form-group">
            <label>Recommended By</label>
            <input
              type="text"
              value={userName}
              readOnly
              disabled
            />
          </div>

          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? <ClipLoader color="#ff6e1e" size={16} /> : 'Submit'}
          </button>
        </form>
      </div>
    </div>
  );
};

const HikingGearList = () => {
  const navigate = useNavigate();
  const [gearData, setGearData] = useState({});
  const [cookies] = useCookies(['userId']);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [showAddModal, setShowAddModal] = useState(false);
  const [usedGear, setUsedGear] = useState([]);
  const [userNames, setUserNames] = useState({});
  const [userId, setUserId] = useState(null);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const db = getFirestore();

  const [showNotesModal, setShowNotesModal] = useState(false);
  const [currentGearItem, setCurrentGearItem] = useState(null);
  const [editedNotes, setEditedNotes] = useState('');

  const handleOpenNotesModal = (itemId, currentNotes) => {
    setCurrentGearItem(itemId);
    setEditedNotes(currentNotes || '');
    setShowNotesModal(true);
  };

  const handleNotesUpdate = async (newNotes) => {
    if (!userData || !currentGearItem) return;

    try {
      const gearRef = doc(db, "Gear", currentGearItem);
      await updateDoc(gearRef, {
        Notes: newNotes
      });

      // Update local state
      setGearData(prev => {
        const updated = { ...prev };
        for (const category in updated) {
          updated[category] = updated[category].map(item => {
            if (item.id === currentGearItem) {
              return {
                ...item,
                Notes: newNotes
              };
            }
            return item;
          });
        }
        return updated;
      });

      setShowNotesModal(false);
    } catch (error) {
      console.error('Error updating notes:', error);
      throw error;
    }
  };

  useEffect(() => {
    const checkAccess = async () => {
      setLoading(true);
      try {
        const userIdFromLocalStorage = localStorage.getItem('userId');
        const userIdFromCookies = cookies.userId;
        const userId = userIdFromLocalStorage || userIdFromCookies;
        setUserId(userId);

        if (!userId) {
          navigate('/login');
          return;
        }

        const docRef = doc(db, "UserTable", userId);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          navigate('/login');
          return;
        }

        const userData = docSnap.data();

        if (userData.isMember !== true) {
          navigate('/products');
          return;
        }

        setUserData(userData);
        setUsedGear(userData.usedGear || []);
      } catch (error) {
        console.error("Error checking access:", error);
        navigate('/products');
      } finally {
        setLoading(false);
      }
    };

    checkAccess();
  }, [cookies, navigate, db]);

  useEffect(() => {
    const fetchGearData = async () => {
      try {
        setLoading(true);
        const gearCollection = collection(db, 'Gear');
        const querySnapshot = await getDocs(gearCollection);

        const categorizedData = {};

        querySnapshot.forEach((doc) => {
          const item = doc.data();
          const category = item.Category;

          if (!categorizedData[category]) {
            categorizedData[category] = [];
          }

          categorizedData[category].push({
            id: doc.id,
            ...item
          });
        });

        setGearData(categorizedData);
        setError(null);
      } catch (err) {
        console.error("Error fetching gear data:", err);
        setError("Failed to load gear recommendations. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchGearData();
  }, [db]);

  useEffect(() => {
    const fetchUserNames = async () => {
      try {
        const allUserIds = new Set();

        Object.values(gearData).forEach(items => {
          items.forEach(item => {
            if (item.RecommendedBy) {
              item.RecommendedBy.forEach(uid => allUserIds.add(uid));
            }
            if (item.UsedBy) {
              item.UsedBy.forEach(uid => allUserIds.add(uid));
            }
          });
        });

        if (allUserIds.size === 0) return;

        const usersCollection = collection(db, 'UserTable');
        const userDocs = await Promise.all(
          Array.from(allUserIds).map(uid => getDoc(doc(usersCollection, uid)))
        );

        const names = {};
        userDocs.forEach(userDoc => {
          if (userDoc.exists()) {
            names[userDoc.id] = userDoc.data().FullName;
          }
        });

        setUserNames(names);
      } catch (error) {
        console.error('Error fetching user names:', error);
      }
    };

    if (Object.keys(gearData).length > 0) {
      fetchUserNames();
    }
  }, [gearData, db]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleHeartClick = async (itemId, isAdding) => {
    if (!userId) return false;

    try {
      const gearRef = doc(db, "Gear", itemId);

      const updatePromise = updateDoc(gearRef, {
        UsedBy: isAdding ? arrayUnion(userId) : arrayRemove(userId)
      });

      setGearData(prev => {
        const updated = { ...prev };
        for (const category in updated) {
          updated[category] = updated[category].map(item => {
            if (item.id === itemId) {
              let newUsedBy = [...(item.UsedBy || [])];
              if (isAdding) {
                if (!newUsedBy.includes(userId)) {
                  newUsedBy.push(userId);
                }
              } else {
                newUsedBy = newUsedBy.filter(id => id !== userId);
              }
              return {
                ...item,
                UsedBy: newUsedBy
              };
            }
            return item;
          });
        }
        return updated;
      });

      await updatePromise;
      return true;
    } catch (error) {
      console.error('Error updating gear usage:', error);

      setGearData(prev => {
        const updated = { ...prev };
        for (const category in updated) {
          updated[category] = updated[category].map(item => {
            if (item.id === itemId) {
              return {
                ...item,
                UsedBy: item.UsedBy || []
              };
            }
            return item;
          });
        }
        return updated;
      });

      return false;
    }
  };

  // const handleNotesUpdate = async (itemId, newNotes) => {
  //   if (!userData) return;

  //   try {
  //     const gearRef = doc(db, "Gear", itemId);
  //     await updateDoc(gearRef, {
  //       Notes: newNotes
  //     });

  //     await fetchGearData();
  //   } catch (error) {
  //     console.error('Error updating notes:', error);
  //     throw error;
  //   }
  // };

  const handleAddGear = async (gearData) => {
    if (!userId) {
      console.error("No user ID available");
      return false;
    }
  
    try {
      const gearCollection = collection(db, 'Gear');
  
      const newGear = {
        Category: gearData.Category,
        ProductName: gearData.ProductName,
        ProductLink: gearData.ProductLink || '',
        ImageLink: gearData.ImageLink|| '',
        Notes: gearData.Notes?.trim() || '',
        RecommendedBy: [userId],
        UsedBy: [userId],
        createdAt: new Date()
      };
  
      // Add the gear item first
      const docRef = await addDoc(gearCollection, newGear);
  
      // Create changedFields for logging - similar to profile component
      // Since this is a new entry, we'll show all fields as new
      const changedFields = {};
      Object.entries(newGear).forEach(([key, value]) => {
        changedFields[key] = {
          old: "",  // Empty since it's a new item
          new: value // The new value being added
        };
      });
      
      // Add the new ID as well
      changedFields["id"] = {
        old: "",
        new: docRef.id
      };
  
      // Create a log entry for this action
      const logEntry = {
        userId: userId,
        userName: userData?.FullName || '',
        userEmail: userData?.Email || '',
        timestamp: new Date(),
        action: `${userData?.FullName || 'User'} Added new Gear`,
        gearId: docRef.id,
        changedFields: changedFields,
        actionType: 'gear_added'
      };
  
      // Add log to the ProfileChangeLogs collection
      await addDoc(collection(db, "ProfileChangeLogs"), logEntry);
      console.log("Gear addition logged successfully");
  
      // Update local state with the new gear
      setGearData(prev => {
        const updated = { ...prev };
        const category = newGear.Category;
  
        if (!updated[category]) {
          updated[category] = [];
        }
  
        updated[category] = [
          ...updated[category],
          {
            id: docRef.id,
            ...newGear
          }
        ];
  
        return updated;
      });
  
      return true;
    } catch (error) {
      console.error("Error adding gear:", error);
      
      // Log the error too
      try {
        await addDoc(collection(db, "ProfileChangeLogs"), {
          userId: userId,
          userName: userData?.FullName || '',
          userEmail: userData?.email || '',
          timestamp: new Date(),
          error: error.message,
          action: "gear_add_error",
          attemptedGear: gearData
        });
      } catch (logError) {
        console.error("Error logging the error:", logError);
      }
      
      throw error;
    }
  };

  const handleMemberProfileClick = (memberId) => {
    setSelectedMemberId(memberId);
  };

  const handleCloseModal = () => {
    setSelectedMemberId(null);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        const userRef = doc(db, "UserTable", userId);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserData(userSnap.data());
        }
      }
    };
    fetchUserData();
  }, [userId]);

  const fetchGearData = async () => {
    try {
      setLoading(true);
      const gearCollection = collection(db, 'Gear');
      const querySnapshot = await getDocs(gearCollection);

      const categorizedData = {};

      querySnapshot.forEach((doc) => {
        const item = doc.data();
        const category = item.Category;

        if (!categorizedData[category]) {
          categorizedData[category] = [];
        }

        categorizedData[category].push({
          id: doc.id,
          ...item
        });
      });

      setGearData(categorizedData);
      setError(null);
    } catch (err) {
      console.error("Error fetching gear data:", err);
      setError("Failed to load gear recommendations. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const getDisplayedGear = () => {
    if (selectedCategory === 'All') {
      return Object.values(gearData).flat();
    } else if (gearData[selectedCategory]) {
      return gearData[selectedCategory];
    }
    return [];
  };

  if (loading) {
    return (
      <div className="loading-container">
        <ClipLoader color="#ff6e1e" size={50} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p className="error-message">{error}</p>
        <button onClick={() => window.location.reload()} className="retry-button">
          Retry
        </button>
      </div>
    );
  }

  const displayedGear = getDisplayedGear();
  const categories = ['All', ...Object.keys(gearData)];

  return (
    <div className="hiking-gear-page">
      <AddGearModal
        isOpen={showAddModal}
        onClose={() => setShowAddModal(false)}
        onSubmit={async (data) => {
          try {
            const success = await handleAddGear(data);
            if (success) {
              setShowAddModal(false);
            }
          } catch (error) {
            console.error('Failed to add gear:', error);
          }
        }}
        categories={categories}
        userName={userData?.FullName || ''}
        userId={userId}
      />

      <div className="hiking-gear-container">
        <div className="back-button-container">
          <button onClick={() => navigate('/products')} className="back-button">
            <ArrowLeft size={16} />
            <span>Back</span>
          </button>
        </div>

        <div className="header-section">
          <div>
            <h1 className="page-title">GEAR RECOMMENDATIONS</h1>
            <p className="subtitle">
              Curated list of gear used and recommended by community members.
            </p>
          </div>
          <button
            className="add-gear-button-large"
            onClick={() => setShowAddModal(true)}
          >
            <Plus size={16} />
            <span>Add Your Recommendation</span>
          </button>
        </div>

        <p className="instructions-text">
          Click the <span className="heart-emoji">❤️</span> icon to add gear that you Recommend!
        </p>

        <div className="category-navigation">
          {categories.map((category) => (
            <button
              key={category}
              className={`category-button ${selectedCategory === category ? 'active' : ''}`}
              onClick={() => handleCategorySelect(category)}
            >
              {category}
            </button>
          ))}
        </div>

        <div className="product-count">
          {displayedGear.length} products
        </div>

        <div className="gear-grid">
          {displayedGear.map((item) => (
            <GearCard
              key={item.id}
              item={item}
              userId={userId}
              userData={userData}
              onHeartClick={handleHeartClick}
              isUsed={usedGear.includes(item.id)}
              userNames={userNames}
              onNotesUpdate={handleNotesUpdate}
              onMemberProfileClick={handleMemberProfileClick}
              onOpenNotesModal={handleOpenNotesModal}
            />
          ))}
        </div>

        <NotesModal
          isOpen={showNotesModal}
          onClose={() => setShowNotesModal(false)}
          initialNotes={editedNotes}
          onSave={handleNotesUpdate}
        />
        {selectedMemberId && (
          <MemberProfileModal
            memberId={selectedMemberId}
            onClose={handleCloseModal}
          />
        )}

        <div className="footer">
          Copyright © Rush Labs
          | <a href="https://docs.google.com/document/d/e/2PACX-1vQ0Jtw8bX3Uc7iPkMQc-494wfPC24FxnE48SAGW61Ld6wskhFngri_SRGzc6_BIfUSTsSSWr__v-vwX/pub" target="_blank" rel="noopener noreferrer">
            Terms & Member Guidelines
          </a> |
          Support : <a href="mailto:hi@manav.in">
            hi@manav.in
          </a>
        </div>
      </div>
    </div>
  );
};

export default HikingGearList;