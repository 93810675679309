import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, addDoc, getDocs, doc, getDoc, deleteDoc, updateDoc, orderBy, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import phoneCodes from '../Json/phoneCodes.json';
import indianCities from '../Json/indianCities.json';
import ClipLoader from "react-spinners/ClipLoader";
import { FaChevronDown, FaChevronUp, FaSearch, FaInfoCircle, FaEdit } from 'react-icons/fa'; // Import icons from react-icons/fa
import { Info as InfoIcon } from 'lucide-react';
import heic2any from 'heic2any';
import '../Css/TrekModal.css';

// TrekSelectionModal Component (No changes needed in this component)
const TrekSelectionModal = ({
    isOpen,
    title,
    modalType, // Add modalType prop to distinguish between wishlist and done
    selectedTrekIds,
    availableTreks,
    onClose,
    onSave,
    onChange,
    onAddNewTrek
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredTreks, setFilteredTreks] = useState(availableTreks);
    const [showAddForm, setShowAddForm] = useState(false);
    const [newTrekName, setNewTrekName] = useState('');
    const [newTrekState, setNewTrekState] = useState('');
    const [formErrors, setFormErrors] = useState({ name: false, state: false });

    useEffect(() => {
        if (!searchQuery.trim()) {
            setFilteredTreks(availableTreks);
        } else {
            const query = searchQuery.toLowerCase().trim();
            const filtered = availableTreks.filter(trek =>
                trek.trek_name.toLowerCase().includes(query) ||
                (trek.state && trek.state.toLowerCase().includes(query))
            );
            setFilteredTreks(filtered);
            // Initialize new trek name with search query when showing add form
            if (filtered.length === 0 && query.length > 0) {
                setNewTrekName(searchQuery);
            }
        }
    }, [searchQuery, availableTreks]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleTrekSelection = (trekId) => {
        const isCurrentlySelected = selectedTrekIds.includes(trekId);
        onChange(trekId, !isCurrentlySelected);
    };

    const clearSearch = () => {
        setSearchQuery('');
        setShowAddForm(false);
        setNewTrekName('');
        setNewTrekState('');
        setFormErrors({ name: false, state: false });
    };

    const handleShowAddForm = () => {
        setShowAddForm(true);
    };

    const handleAddNewTrek = () => {
        // Validate form fields
        const errors = {
            name: !newTrekName.trim(),
            state: !newTrekState.trim()
        };

        setFormErrors(errors);

        // Check if both fields have values
        if (!errors.name && !errors.state && onAddNewTrek) {
            onAddNewTrek(newTrekName.trim(), newTrekState.trim(), modalType);
            setShowAddForm(false);
            clearSearch();
        }
    };

    const handleCancelAddForm = () => {
        setShowAddForm(false);
        setNewTrekName('');
        setNewTrekState('');
        setFormErrors({ name: false, state: false });
    };

    if (!isOpen) return null;

    return (
        <div className="trek-modal-overlay">
            <div className="trek-modal-container">
                <div className="trek-modal-content">
                    <div className="trek-modal-header">
                        <h5 className="trek-modal-title">{title}</h5>
                        <button
                            type="button"
                            onClick={onClose}
                            className="trek-modal-close-button"
                        >×</button>
                    </div>

                    <div className="trek-modal-body">
                        <div className="trek-modal-search-container">
                            <input
                                type="text"
                                placeholder="Search by trek name or state"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className="trek-modal-search-input"
                            />
                            {searchQuery && (
                                <button
                                    type="button"
                                    onClick={clearSearch}
                                    className="trek-modal-search-clear"
                                >×</button>
                            )}
                        </div>

                        <div className="trek-modal-selected-count">
                            {selectedTrekIds.length > 0 && (
                                <span>{selectedTrekIds.length} selected</span>
                            )}
                        </div>

                        {showAddForm ? (
                            <div className="trek-modal-add-form">
                                <div className="trek-modal-add-form-header">
                                    <h5>Add the missing hike</h5>
                                    <button
                                        type="button"
                                        onClick={handleCancelAddForm}
                                        className="trek-modal-add-form-close"
                                    >×</button>
                                </div>
                                <p className="trek-modal-add-form-subtitle">
                                    Please double check to ensure this hike is actually missing before adding it.
                                </p>

                                <div className="trek-modal-form-field">
                                    <label className="trek-modal-form-label">HIKE NAME</label>
                                    <input
                                        type="text"
                                        value={newTrekName}
                                        onChange={(e) => setNewTrekName(e.target.value)}
                                        className={`trek-modal-input ${formErrors.name ? 'error' : ''}`}
                                        placeholder="Enter hike name"
                                    />
                                    {formErrors.name && <span className="trek-modal-error">Hike name is required</span>}
                                </div>

                                <div className="trek-modal-form-field">
                                    <label className="trek-modal-form-label">STATE</label>
                                    <input
                                        type="text"
                                        value={newTrekState}
                                        onChange={(e) => setNewTrekState(e.target.value)}
                                        className={`trek-modal-input ${formErrors.state ? 'error' : ''}`}
                                        placeholder="Enter state"
                                    />
                                    {formErrors.state && <span className="trek-modal-error">State is required</span>}
                                </div>

                                <button
                                    type="button"
                                    onClick={handleAddNewTrek}
                                    className="trek-modal-add-button"
                                >
                                    Add
                                </button>
                            </div>
                        ) : (
                            <div className="trek-modal-list">
                                {filteredTreks.length > 0 ? (
                                    filteredTreks.map((trek) => {
                                        const isSelected = selectedTrekIds.includes(trek.trek_id);
                                        return (
                                            <div
                                                key={trek.trek_id}
                                                onClick={() => handleTrekSelection(trek.trek_id)}
                                                className={`trek-modal-list-item ${isSelected ? 'selected' : ''}`}
                                            >
                                                <span className={`trek-modal-list-item-name ${isSelected ? 'selected' : ''}`}>
                                                    {trek.trek_name} {trek.state ? `(${trek.state})` : ''}
                                                </span>

                                                {isSelected && (
                                                    <span className="trek-modal-list-item-remove">×</span>
                                                )}
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="trek-modal-empty">
                                        <p style={{ margin: 0 }}>No matches found.</p>
                                        {searchQuery.trim() !== '' && (
                                            <div className="trek-modal-add-new">
                                                <p style={{ margin: '10px 0' }}>
                                                    <a
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleShowAddForm();
                                                        }}
                                                        className="trek-modal-add-link"
                                                    >
                                                        {modalType === 'wishlist'
                                                            ? `Click here to add "${searchQuery}" to your Hikes Wishlist.`
                                                            : `Click here to add "${searchQuery}" to your Hikes Done list.`
                                                        }
                                                    </a>
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="trek-modal-footer">
                        <button
                            type="button"
                            onClick={onClose}
                            className="trek-modal-cancel-button"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={onSave}
                            className="trek-modal-save-button"
                        >
                            Save {selectedTrekIds.length > 0 ? `(${selectedTrekIds.length})` : ''}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};


function ProfileForm() {
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
    };
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const storage = getStorage(app);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [cookies, setCookie, removeCookie] = useCookies(['userId']);
    const [userId, setUserId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [emergencyContact1Name, setemergencyContact1Name] = useState('');
    const [emergencyContact2Name, setemergencyContact2Name] = useState('');
    const [lastName, setLastName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userWhatsAppNo, setUserWhatsAppNo] = useState('');
    const [userCountryCode, setuserCountryCode] = useState('+91');
    const [userGender, setUserGender] = useState('');
    const [userDOB, setUserDOB] = useState('');
    const [userEmergencyContact1, setUserEmergencyContact1] = useState('');
    const [emergencyContact1CountryCode, setemergencyContact1CountryCode] = useState('+91');
    const [emergencyContact2CountryCode, setemergencyContact2CountryCode] = useState('+91');
    const [userEmergencyContact2, setUserEmergencyContact2] = useState('');
    const [userTableId, setUserTableId] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [errors, setErrors] = useState({});
    const [profileInfo, setProfileInfo] = useState({});
    const [emergencyInfo, setemergencyInfo] = useState({});

    const [IsEditButtonEnabled, setIsEditButtonEnabled] = useState(false);
    const [cameFromAlertsRoute, setCameFromAlertsRoute] = useState(false);

    const [countries, setCountries] = useState([]);
    const [fullName, setFullName] = useState('');
    const [alreadyExistingNumberList, setAlreadyExistingNumberList] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const [profilePhoto, setProfilePhoto] = useState(null);
    const [profilePhotoURL, setProfilePhotoURL] = useState('');

    const [compressionProgress, setCompressionProgress] = useState(0);
    const [originalSize, setOriginalSize] = useState(null);
    const [compressedSize, setCompressedSize] = useState(null);
    const [processingHeic, setProcessingHeic] = useState(false);

    const [isMember, setIsMember] = useState(false);
    const [isDirectoryVisible, setIsDirectoryVisible] = useState(true);
    const [photoUploading, setPhotoUploading] = useState(false);

    const [city, setCity] = useState('');
    const [citySearchTerm, setCitySearchTerm] = useState('');
    const [filteredCities, setFilteredCities] = useState([]);
    const [showCityDropdown, setShowCityDropdown] = useState(false);
    const cityDropdownRef = useRef(null);

    const [bio, setBio] = useState('');
    const [availableTreks, setAvailableTreks] = useState([]);
    const [treksDone, setTreksDone] = useState([]);
    const [treksWishlist, setTreksWishlist] = useState([]);

    const [isTreksDoneModalOpen, setIsTreksDoneModalOpen] = useState(false);
    const [isTreksWishlistModalOpen, setIsTreksWishlistModalOpen] = useState(false);

    const [modalTreksDone, setModalTreksDone] = useState([]);
    const [modalTreksWishlist, setModalTreksWishlist] = useState([]);

    const [instagramLink, setInstagramLink] = useState('');
    const [linkedinLink, setLinkedinLink] = useState('');
    const [anyOtherLink, setAnyOtherLink] = useState(''); // Changed from facebookLink

    const [profession, setProfession] = useState('');
    const [outdoorGoals, setOutdoorGoals] = useState('');

    const [showConfirmation, setShowConfirmation] = useState(false);

    // State for collapsible sections - REMOVED
    // const [isProfileVisible, setIsProfileVisible] = useState(true);
    // const [isSocialLinksVisible, setIsSocialLinksVisible] = useState(true);
    const [isHikingPlansVisible, setIsHikingPlansVisible] = useState(true); // Changed from Treks Done/Wishlist to Hiking Plans

    const [bloodGroup, setBloodGroup] = useState(''); // State for Blood Group


    // Toggle visibility functions - REMOVED
    // const toggleProfileVisibility = () => setIsProfileVisible(!isProfileVisible);
    // const toggleSocialLinksVisibility = () => setIsSocialLinksVisible(!isSocialLinksVisible);


    useEffect(() => {
        function handleClickOutside(event) {
            if (cityDropdownRef.current && !cityDropdownRef.current.contains(event.target)) {
                setShowCityDropdown(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [cityDropdownRef]);

    // Add this effect to filter cities based on search term
    useEffect(() => {
        if (citySearchTerm) {
            const filtered = indianCities.cities.filter(city =>
                city.toLowerCase().includes(citySearchTerm.toLowerCase())
            );
            setFilteredCities(filtered.slice(0, 10)); // Limit to 10 results for performance
        } else {
            setFilteredCities([]);
        }
    }, [citySearchTerm]);

    // Handle city selection
    const handleCitySelect = (selectedCity) => {
        setCity(selectedCity);
        setCitySearchTerm(selectedCity);
        setShowCityDropdown(false);
        setErrors(prevErrors => ({ ...prevErrors, city: '' }));
    };

    // Handle city search input change
    const handleCitySearchChange = (e) => {
        setCitySearchTerm(e.target.value);
        setCity(e.target.value);
        setShowCityDropdown(true);
        setErrors(prevErrors => ({ ...prevErrors, city: '' }));
    };

    useEffect(() => {
        setLoading(true);
        const formattedCountries = Object.entries(phoneCodes).map(([code, number]) => ({
            code,
            number
        }));
        setCountries(formattedCountries);
    }, []);

    useEffect(() => {
        const fetchTreks = async () => {
            try {
                const treksQuery = query(collection(db, "trek_db"));
                const treksSnapshot = await getDocs(treksQuery);
                const treksList = [];
                treksSnapshot.forEach((doc) => {
                    const data = doc.data();
                    treksList.push({
                        trek_id: doc.id,
                        trek_name: data.trek_name || "Unknown Trek",
                        state: data.state
                    });
                });

                // Sort treks alphabetically by trek_name
                treksList.sort((a, b) => a.trek_name.localeCompare(b.trek_name));

                setAvailableTreks(treksList);
            } catch (error) {
                console.error("Error fetching treks:", error);
            }
        };

        fetchTreks();
    }, [db]);

    const handleAddNewTrek = async (trekName, trekState, modalType) => {
        if (!trekName.trim() || !trekState.trim()) return;

        try {
            setLoading(true);

            // Create a new trek in the trek_db collection
            const newTrekRef = await addDoc(collection(db, "trek_db"), {
                trek_name: trekName,
                state: trekState,
                added_by: `${fullName} (${userEmail})`,
                added_on: new Date(),
                timestamp: new Date()
            });

            // Get the newly created trek ID
            const newTrekId = newTrekRef.id;

            // Create new trek object for UI updates
            const newTrek = {
                trek_id: newTrekId,
                trek_name: trekName,
                state: trekState
            };

            // Update available treks
            setAvailableTreks(prevTreks => {
                const updatedTreks = [...prevTreks, newTrek];
                return updatedTreks.sort((a, b) => a.trek_name.localeCompare(b.trek_name));
            });

            // Create log entry with proper changedFields format
            let changedFields = {};

            if (modalType === 'wishlist') {
                const newWishlist = [...modalTreksWishlist, newTrekRef.id];
                setModalTreksWishlist(newWishlist);

                // Prepare changedFields for the logs
                changedFields = {
                    TrekDbUpdated: {
                        old: 'New trek Added',
                        new: `${trekName} (${trekState})`
                    }
                };
            } else if (modalType === 'done') {
                const newDone = [...modalTreksDone, newTrekRef.id];
                setModalTreksDone(newDone);

                // Prepare changedFields for the logs
                changedFields = {
                    TrekDbUpdated: {
                        old: 'New trek Added',
                        new: `${trekName} (${trekState})`
                    }
                };
            }

            // Log the action in ProfileChangeLogs with proper format
            await addDoc(collection(db, "ProfileChangeLogs"), {
                userId: userId,
                userName: fullName,
                userEmail: userEmail,
                timestamp: new Date(),
                changedFields: changedFields,
                action: "USER_ADDED_TREK"
            });

        } catch (error) {
            console.error("Error adding new trek:", error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        setLoading(true);
        const fetchWhatsAppNumbers = async () => {
            try {
                const q = query(collection(db, "UserTable"));
                const querySnapshot = await getDocs(q);
                const numbers = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.WhatsAppNo) {
                        numbers.push({
                            WhatsAppNo: data.WhatsAppNo,
                            documentId: doc.id
                        });
                    }
                });
                setAlreadyExistingNumberList(numbers);
            } catch (error) {
                console.error("Error fetching WhatsApp numbers:", error);
            }
        };
        fetchWhatsAppNumbers();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const userIdFromLocalStorage = localStorage.getItem('userId');
                const userIdFromCookies = cookies.userId;
                const userId = userIdFromLocalStorage || userIdFromCookies;

                if (userId) {
                    setUserId(userId);
                    const docRef = doc(db, "UserTable", userId);
                    const docSnap = await getDoc(docRef);
                    const dataDoc = docSnap.data();

                    if (docSnap.exists()) {
                        setProfileInfo(dataDoc);
                        setFullName(dataDoc.FullName || '');
                        setFirstName(dataDoc.FirstName || '');
                        setLastName(dataDoc.LastName || '');
                        setUserEmail(dataDoc.Email || '');
                        setUserWhatsAppNo(dataDoc.WhatsAppNo || '');
                        setuserCountryCode(dataDoc.UserCountryCode || '+91');
                        setUserGender(dataDoc.Gender || '');
                        setUserDOB(dataDoc.DateOfBirth || '');
                        setUserEmergencyContact1(dataDoc.EmergencyContact1 || '');
                        setemergencyContact1Name(dataDoc.EmergencyContact1Name || '');
                        setemergencyContact1CountryCode(dataDoc.EmergencyContact1CountryCode || '+91');
                        setemergencyContact2CountryCode(dataDoc.EmergencyContact2CountryCode || '+91');
                        setUserEmergencyContact2(dataDoc.EmergencyContact2 || '');
                        setemergencyContact2Name(dataDoc.EmergencyContact2Name || '');
                        setIsEditButtonEnabled(dataDoc.EmergencyContact1 && dataDoc.EmergencyContact2 || false);

                        setProfilePhotoURL(dataDoc.ProfilePhotoURL || '');
                        setIsMember(dataDoc.isMember || false);
                        setIsDirectoryVisible(dataDoc.isDirectoryVisible !== false);

                        //setCity(dataDoc.City || '');
                        const cityValue = dataDoc.City || '';
                        setCity(cityValue);
                        setCitySearchTerm(cityValue); // Set the city search term too

                        setBio(dataDoc.Bio || '');
                        setProfession(dataDoc.Profession || ''); // Add this
                        setOutdoorGoals(dataDoc.OutdoorGoals || ''); // Add this
                        setTreksDone(dataDoc.TreksDone || []);
                        setTreksWishlist(dataDoc.TreksWishlist || []);
                        setModalTreksDone(dataDoc.TreksDone || []);
                        setModalTreksWishlist(dataDoc.TreksWishlist || []);

                        setInstagramLink(dataDoc.InstagramLink || '');
                        setLinkedinLink(dataDoc.LinkedinLink || '');
                        setAnyOtherLink(dataDoc.FacebookLink || ''); // Changed from facebookLink to AnyOtherLink in DB, keeping facebookLink for backward compatibility
                        setBloodGroup(dataDoc.BloodGroup || ''); // Set Blood Group
                    }
                } else {
                    navigate('/login');
                }
            } catch (error) {
                console.error("Error fetching documents:", error);
            } finally {
                setIsDataLoaded(true);
                setLoading(false);
            }
        };

        fetchData();
    }, [cookies, navigate]);

    // Function to compress image
    const handleHeicFormat = async (file) => {
        // Check if the file is a HEIC format
        if (file.type === "image/heic" || file.type === "image/heif" || file.name.toLowerCase().endsWith('.heic') || file.name.toLowerCase().endsWith('.heif')) {
            try {
                setProcessingHeic(true);
                setCompressionProgress(10);

                // Convert HEIC to JPEG using heic2any
                const jpegBlob = await heic2any({
                    blob: file,
                    toType: "image/jpeg",
                    quality: 0.8
                });

                setCompressionProgress(50);

                // Create a new file from the JPEG blob
                const convertedFile = new File([jpegBlob],
                    file.name.replace(/\.(heic|heif)$/i, '.jpg'),
                    { type: 'image/jpeg', lastModified: Date.now() }
                );

                setProcessingHeic(false);
                console.log(`Converted HEIC to JPEG: ${(convertedFile.size / 1024).toFixed(2)}KB`);
                return convertedFile;
            } catch (error) {
                console.error("Error converting HEIC to JPEG:", error);
                setProcessingHeic(false);
                throw error;
            }
        }
        return file;
    };

    // Function to compress image
    const compressImage = (file) => {
        return new Promise((resolve, reject) => {
            const fileName = file.name;
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;

                setOriginalSize((file.size / 1024).toFixed(2));
                setCompressionProgress(25);

                img.onload = () => {
                    setCompressionProgress(50);
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    // Calculate new dimensions while maintaining aspect ratio
                    const MAX_WIDTH = 1200;
                    const MAX_HEIGHT = 1200;
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;

                    ctx.drawImage(img, 0, 0, width, height);
                    setCompressionProgress(75);

                    // Convert canvas to blob with quality reduction
                    canvas.toBlob((blob) => {
                        setCompressionProgress(100);
                        const compressedFile = new File([blob], fileName, {
                            type: 'image/jpeg',
                            lastModified: Date.now()
                        });

                        setCompressedSize((compressedFile.size / 1024).toFixed(2));
                        resolve(compressedFile);
                    }, 'image/jpeg', 0.7); // Adjust quality (0-1) to get desired file size
                };
            };

            reader.onerror = (error) => {
                console.log("Error: ", error);
                reject(error);
            };
        });
    };

    const handleProfilePhotoChange = async (e) => {
        if (e.target.files[0]) {
            const file = e.target.files[0];

            // Display preview immediately for better UX - only for non-HEIC formats
            if (!file.type.includes('heic') && !file.name.toLowerCase().endsWith('.heic')) {
                const fileReader = new FileReader();
                fileReader.onload = (event) => {
                    setProfilePhotoURL(event.target.result);
                };
                fileReader.readAsDataURL(file);
            }

            try {
                // Handle HEIC conversion first
                setCompressionProgress(5);
                let processedFile = file;

                try {
                    processedFile = await handleHeicFormat(file);

                    // If file was converted from HEIC, update preview
                    if (processedFile !== file) {
                        const fileReader = new FileReader();
                        fileReader.onload = (event) => {
                            setProfilePhotoURL(event.target.result);
                        };
                        fileReader.readAsDataURL(processedFile);
                    }
                } catch (heicError) {
                    console.error("HEIC conversion failed, proceeding with original file:", heicError);
                }

                // Now handle compression
                const compressedImage = await compressImage(processedFile);

                setProfilePhoto(compressedImage);
                console.log(`Original size: ${(file.size / 1024).toFixed(2)}KB, Compressed size: ${(compressedImage.size / 1024).toFixed(2)}KB`);

                // If the compressed size is still too large (>300KB), compress again with lower quality
                if (compressedImage.size > 300 * 1024) {
                    const canvas = document.createElement('canvas');
                    const img = new Image();
                    img.src = URL.createObjectURL(compressedImage);

                    img.onload = () => {
                        const ctx = canvas.getContext('2d');
                        canvas.width = img.width;
                        canvas.height = img.height;
                        ctx.drawImage(img, 0, 0, img.width, img.height);

                        canvas.toBlob((blob) => {
                            const extraCompressedFile = new File([blob], processedFile.name, {
                                type: 'image/jpeg',
                                lastModified: Date.now()
                            });

                            setProfilePhoto(extraCompressedFile);
                            setCompressedSize((extraCompressedFile.size / 1024).toFixed(2));
                            console.log(`Further compressed: ${(extraCompressedFile.size / 1024).toFixed(2)}KB`);
                        }, 'image/jpeg', 0.5); // Further reduce quality
                    };
                }
            } catch (error) {
                console.error("Error processing image:", error);
                // Fallback to original image if compression fails
                setProfilePhoto(file);
            } finally {
                setCompressionProgress(0);
            }
        }
    };

    const uploadProfilePhoto = async () => {
        if (!profilePhoto) return null;

        try {
            setPhotoUploading(true);
            const storageRef = ref(storage, `profile_photos/${userId}/${profilePhoto.name}`);
            await uploadBytes(storageRef, profilePhoto);
            const downloadURL = await getDownloadURL(storageRef);
            setProfilePhotoURL(downloadURL);
            setPhotoUploading(false);
            return downloadURL;
        } catch (error) {
            console.error("Error uploading profile photo:", error);
            setPhotoUploading(false);
            return null;
        }
    };

    useEffect(() => {
        // location.state can be set when navigating
        if (location.state && location.state.fromAlertsRoute) {
            setCameFromAlertsRoute(true);
        }
    }, [location]);


    useEffect(() => {
        if (isDataLoaded) {
            validateForm();
        }
    }, [fullName, userEmail, userWhatsAppNo, userCountryCode, userGender, userDOB,
        userEmergencyContact1, emergencyContact1CountryCode, userEmergencyContact2,
        emergencyContact2CountryCode, emergencyContact1Name, emergencyContact2Name,
        city, bio, treksDone, treksWishlist, instagramLink, linkedinLink, anyOtherLink, isDirectoryVisible, isDataLoaded, bloodGroup, profession, profilePhoto, profilePhotoURL]); // profilePhoto and profilePhotoURL added here


    const validateForm = () => {
        let formErrors = {};
        const validatePhoneNumber = (number) => /^\d{10}$/.test(number);
        const validateURL = (url) => {
            if (!url) return true;
            try {
                new URL(url);
                return true;
            } catch (e) {
                return false;
            }
        };

        if (!profilePhoto && !profilePhotoURL) formErrors.profilePhoto = "Profile Photo is required."; // Profile Photo validation - Conditionally mandatory
        if (!fullName.trim()) formErrors.fullName = "Full Name is required.";
        //if (!emergencyContact1Name.trim()) formErrors.emergencyContact1Name = "Emergency Contact 1 Name is required.";
        //if (!emergencyContact2Name.trim()) formErrors.emergencyContact2Name = "Emergency Contact 2 Name is required.";
        if (!userGender) formErrors.userGender = "Gender is required.";
        if (!userDOB) formErrors.userDOB = "Date of Birth is required.";
        if (!city.trim()) formErrors.city = "City is required.";
        if (!bloodGroup) formErrors.bloodGroup = "Blood Group is required."; // Blood Group validation
        if (!profession.trim()) formErrors.profession = "Profession is required."; // Profession validation

        if (!userEmail.trim()) {
            formErrors.userEmail = "Email is required.";
        } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(userEmail)) {
            formErrors.userEmail = "Invalid email format.";
        }

        if (!userWhatsAppNo.trim()) {
            formErrors.userWhatsAppNo = "WhatsApp number is required.";
        } else if (!validatePhoneNumber(userWhatsAppNo)) {
            formErrors.userWhatsAppNo = "WhatsApp number must be 10 digits.";
        }

        // if (!userEmergencyContact1.trim()) {
        //     formErrors.userEmergencyContact1 = "Emergency Contact Number is required.";
        // } else if (!validatePhoneNumber(userEmergencyContact1)) {
        //     formErrors.userEmergencyContact1 = "Contact Number must be 10 digits.";
        // }

        // if (!userEmergencyContact2.trim()) {
        //     formErrors.userEmergencyContact2 = "Emergency Contact Number is required.";
        // } else if (!validatePhoneNumber(userEmergencyContact2)) {
        //     formErrors.userEmergencyContact2 = "Contact Number must be 10 digits.";
        // }

        if (!userCountryCode) {
            formErrors.userCountryCode = "Country code is required.";
        }
        // if (!emergencyContact1CountryCode) {
        //     formErrors.emergencyContact1CountryCode = "Country code is required.";
        // }
        // if (!emergencyContact2CountryCode) {
        //     formErrors.emergencyContact2CountryCode = "Country code is required.";
        // }

        // if (validatePhoneNumber(userWhatsAppNo) &&
        //     validatePhoneNumber(userEmergencyContact1) &&
        //     validatePhoneNumber(userEmergencyContact2)) {

        //     if (userWhatsAppNo === userEmergencyContact1 &&
        //         userCountryCode === emergencyContact1CountryCode) {
        //         formErrors.userEmergencyContact1 = "Emergency Contact 1 must be different from WhatsApp number.";
        //     }

        //     if (userWhatsAppNo === userEmergencyContact2 &&
        //         userCountryCode === emergencyContact2CountryCode) {
        //         formErrors.userEmergencyContact2 = "Emergency Contact 2 must be different from WhatsApp number.";
        //     }

        //     if (userEmergencyContact1 === userEmergencyContact2 &&
        //         emergencyContact1CountryCode === emergencyContact2CountryCode) {
        //         formErrors.userEmergencyContact2 = "Emergency Contact 2 must be different from Emergency Contact 1.";
        //     }
        // }

        // if (emergencyContact1Name.trim() &&
        //     emergencyContact2Name.trim() &&
        //     emergencyContact1Name.trim() === emergencyContact2Name.trim()) {
        //     formErrors.emergencyContact2Name = "Emergency Contact 2 Name must be different from Emergency Contact 1 Name.";
        // }

        if (userWhatsAppNo && validatePhoneNumber(userWhatsAppNo)) {
            const existingEntry = alreadyExistingNumberList.find(
                entry => entry.WhatsAppNo === userWhatsAppNo && entry.documentId !== userId
            );

            if (existingEntry) {
                formErrors.userWhatsAppNo = "WhatsApp number is already in use.";
            }
        }

        if (userDOB) {
            const today = new Date();
            const dob = new Date(userDOB);
            if (dob >= today) {
                formErrors.userDOB = "Date of Birth must be strictly before today.";
            }
        }

        if (instagramLink && !validateURL(instagramLink)) formErrors.instagramLink = "Invalid Instagram URL.";
        if (linkedinLink && !validateURL(linkedinLink)) formErrors.linkedinLink = "Invalid LinkedIn URL.";
        if (anyOtherLink && !validateURL(anyOtherLink)) formErrors.anyOtherLink = "Invalid URL."; // Changed from facebookLink to anyOtherLink


        setErrors(formErrors);
        const hasErrors = Object.keys(formErrors).length > 0;
        setIsButtonDisabled(hasErrors);

        return formErrors;
    };

    const onBackCreateAlert = () => {
        if (IsEditButtonEnabled) {
            navigate('../products');
        } else {
            navigate('../products');
        }
    };

    const getTrekNamesFromIds = (trekIds, limit = 3, expanded = false) => {
        if (!trekIds.length) return 'No Hikes selected';

        const selectedTreks = availableTreks
            .filter(trek => trekIds.includes(trek.trek_id))
            .map(trek => ({
                name: trek.trek_name,
                state: trek.state
            }));

        if (expanded || selectedTreks.length <= limit) {
            return expanded ? (
                <ul>
                    {selectedTreks.map((trek, index) => (
                        <li key={index}>
                            {trek.name} {trek.state ? `(${trek.state})` : ''}
                        </li>
                    ))}
                </ul>

            ) : selectedTreks.map(trek => `${trek.name} (${trek.state})`).join(', ');
        } else {
            return `${selectedTreks.slice(0, limit).map(trek => `${trek.name} (${trek.state})`).join(', ')} +${selectedTreks.length - limit} more`;
        }
    };


    const handleOpenTreksDoneModal = () => {
        setModalTreksDone([...treksDone]);
        setIsTreksDoneModalOpen(true);
    };

    const handleCloseTreksDoneModal = () => {
        setIsTreksDoneModalOpen(false);
    };

    const handleSaveTreksDoneModal = () => {
        setTreksDone([...modalTreksDone]);
        setIsTreksDoneModalOpen(false);
    };

    const handleOpenTreksWishlistModal = () => {
        setModalTreksWishlist([...treksWishlist]);
        setIsTreksWishlistModalOpen(true);
    };

    const handleCloseTreksWishlistModal = () => {
        setIsTreksWishlistModalOpen(false);
    };

    const handleSaveTreksWishlistModal = () => {
        setTreksWishlist([...modalTreksWishlist]);
        setIsTreksWishlistModalOpen(false);
    };

    const handleModalTrekDoneChange = (trekId, isChecked) => {
        setModalTreksDone(prevTreks => {
            if (isChecked) {
                return [...prevTreks, trekId];
            } else {
                return prevTreks.filter(id => id !== trekId);
            }
        });
    };

    const handleModalTrekWishlistChange = (trekId, isChecked) => {
        setModalTreksWishlist(prevTreks => {
            if (isChecked) {
                return [...prevTreks, trekId];
            } else {
                return prevTreks.filter(id => id !== trekId);
            }
        });
    };

    const handleCityInfoClick = () => {
        alert("If your city is not listed, please reach out to Shubrank and Manav so that we can add your city to the list.");
    };


    const getChangedFields = (oldData, newData) => {
        const changedFields = {};

        // List of fields to track changes for
        const fieldsToTrack = [
            'WhatsAppNo', 'UserCountryCode', 'FullName', 'DateOfBirth', 'Gender',
            'EmergencyContact1Name', 'EmergencyContact2Name', 'EmergencyContact1CountryCode',
            'EmergencyContact2CountryCode', 'EmergencyContact1', 'EmergencyContact2',
            'isMember', 'isDirectoryVisible', 'City', 'Bio', 'Profession',
            'OutdoorGoals', 'InstagramLink', 'LinkedinLink', 'FacebookLink', 'BloodGroup'
        ];

        // Check special cases for arrays
        if (JSON.stringify(oldData.TreksDone || []) !== JSON.stringify(newData.TreksDone || [])) {
            changedFields.TreksDone = {
                old: oldData.TreksDone || [],
                new: newData.TreksDone || []
            };
        }

        if (JSON.stringify(oldData.TreksWishlist || []) !== JSON.stringify(newData.TreksWishlist || [])) {
            changedFields.TreksWishlist = {
                old: oldData.TreksWishlist || [],
                new: newData.TreksWishlist || []
            };
        }

        // Check for changes in tracked fields
        fieldsToTrack.forEach(field => {
            if (oldData[field] !== newData[field] &&
                !(oldData[field] === undefined && newData[field] === '')) {
                changedFields[field] = {
                    old: oldData[field] || '',
                    new: newData[field] || ''
                };
            }
        });

        // Special case for profile photo
        if (oldData.ProfilePhotoURL !== newData.ProfilePhotoURL) {
            changedFields.ProfilePhotoURL = {
                old: oldData.ProfilePhotoURL || '',
                new: newData.ProfilePhotoURL || '',
                changed: true
            };
        }

        return changedFields;
    };

    // Modify the handleSave function to include logging with image changes
    const handleSave = async (event) => {
        event.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            return;
        }

        setLoading(true);
        try {
            let photoURL = profilePhotoURL;
            let photoChanged = false;
            let oldPhotoURL = profilePhotoURL;

            // If there's a new profile photo
            if (profilePhoto) {
                photoChanged = true;
                photoURL = await uploadProfilePhoto() || photoURL;
            }

            // Prepare new data to be saved
            const newData = {
                "WhatsAppNo": userWhatsAppNo,
                "UserCountryCode": userCountryCode,
                "FullName": fullName,
                "DateOfBirth": userDOB,
                "Gender": userGender,
                "UpdatedOn": new Date(),
                "isProfileComplete": true,
                "EmergencyContact1Name": emergencyContact1Name,
                "EmergencyContact2Name": emergencyContact2Name,
                "EmergencyContact1CountryCode": emergencyContact1CountryCode,
                "EmergencyContact2CountryCode": emergencyContact2CountryCode,
                "EmergencyContact1": userEmergencyContact1,
                "EmergencyContact2": userEmergencyContact2,
                "ProfilePhotoURL": photoURL,
                "isMember": isMember,
                "isDirectoryVisible": isDirectoryVisible,
                "City": city,
                "Bio": bio,
                "Profession": profession,
                "OutdoorGoals": outdoorGoals,
                "TreksDone": modalTreksDone,
                "TreksWishlist": modalTreksWishlist,
                "InstagramLink": instagramLink,
                "LinkedinLink": linkedinLink,
                "FacebookLink": anyOtherLink,
                "BloodGroup": bloodGroup,
            };

            // Get the current user data to compare with
            const userDocRef = doc(db, "UserTable", userId);
            const userDoc = await getDoc(userDocRef);
            const oldData = userDoc.exists() ? userDoc.data() : {};

            if (userDoc.exists()) {
                oldPhotoURL = oldData.ProfilePhotoURL || '';
            }

            // Identify changed fields
            const changedFields = getChangedFields(oldData, {
                ...newData,
                TreksDone: modalTreksDone,
                TreksWishlist: modalTreksWishlist
            });

            // Only log if there are actual changes
            if (Object.keys(changedFields).length > 0) {
                // Create a log entry
                const logEntry = {
                    userId: userId,
                    userName: fullName,
                    userEmail: userEmail,
                    timestamp: new Date(),
                    changedFields: changedFields,
                    action: `${fullName} (${userEmail})`,
                };

                // Add image information if photo was changed
                if (photoChanged || oldPhotoURL !== photoURL) {
                    logEntry.imageChanged = true;
                    logEntry.oldImageURL = oldPhotoURL;
                    logEntry.newImageURL = photoURL;

                    // Get additional metadata about the image if available
                    if (profilePhoto) {
                        logEntry.imageMetadata = {
                            fileName: profilePhoto.name,
                            fileType: profilePhoto.type,
                            fileSize: `${(profilePhoto.size / 1024).toFixed(2)}KB`,
                            lastModified: new Date(profilePhoto.lastModified)
                        };
                    }
                }

                // Add log to the ProfileChangeLogs collection
                await addDoc(collection(db, "ProfileChangeLogs"), logEntry);
                console.log("Profile change logged successfully");
            }

            // Update the user document
            await updateDoc(userDocRef, newData);

            //navigate('/products');
            setShowConfirmation(true);

            // Optionally auto-hide after certain time
            setTimeout(() => {
                setShowConfirmation(false);
            }, 5000);
        } catch (error) {
            console.error("Error updating document: ", error);

            // Log the error too
            try {
                await addDoc(collection(db, "ProfileChangeLogs"), {
                    userId: userId,
                    userName: fullName,
                    userEmail: userEmail,
                    timestamp: new Date(),
                    error: error.message,
                    action: "profile_update_error"
                });
            } catch (logError) {
                console.error("Error logging the error:", logError);
            }
        } finally {
            setLoading(false);
        }
    };

    // Optional: Add a function to log when a user views their profile
    const logProfileView = async () => {
        try {
            await addDoc(collection(db, "ProfileChangeLogs"), {
                userId: userId,
                userName: fullName,
                userEmail: userEmail,
                timestamp: new Date(),
                action: "profile_view"
            });
        } catch (error) {
            console.error("Error logging profile view:", error);
        }
    };


    return (
        <div className={loading ? 'overflow-hidden' : ''} style={{ position: 'relative', height: '100vh', backgroundColor: '#f0f1f3', overflowY: loading ? 'hidden' : 'auto' }}>
            <div
                className="container-fluid d-flex flex-column"
                style={{
                    maxWidth: '1200px', // Increased max width for desktop layout
                    backgroundColor: '#f0f1f3',
                    height: '100%',
                    padding: '20px 5%', // Added padding here to mimic 80% zoom effect
                }}
            >
                <div className="col d-flex justify-content-center align-items-center">
                    <h1
                        className="mb-0"
                        style={{
                            fontSize: '1.65rem',
                            textAlign: 'center',
                            fontFamily: "'Dela Gothic One'",
                        }}
                    >
                        EDIT YOUR PROFILE
                    </h1>
                </div>
                <header className="row py-3">
                    <div className="col">
                        <a className="text-blue text-decoration-none" onClick={onBackCreateAlert}>← Back</a>
                    </div>
                </header>
                <main className="row flex-grow-1 mb-2">
                    <div className="col">
                        {!IsEditButtonEnabled && cameFromAlertsRoute && (
                            <div className="alert alert-warning" role="alert" style={{ backgroundColor: '#fffbe6', border: '1px solid #ffe58f', borderRadius: '4px' }}>
                                <strong>Action Required</strong>
                                <p className="mb-0">Please complete your profile and add 2 emergency contacts before you can create an alert.</p>
                            </div>
                        )}
                        <div className="d-md-flex"> {/* Flex container for desktop layout */}
                            <div className="col-md-6 pe-md-3"> {/* Left column - 50% width */}

                                {/* Your Profile Section */}
                                <div className="bg-white p-3 rounded mb-4">
                                    {/* Your Profile Section - REMOVED Collapsible */}
                                    <div className="mb-3">
                                        {/* REMOVED Dropdown Button and Collapse - Always visible now*/}
                                        <h6 className="text-uppercase small fw-bold text-secondary mb-3"></h6>
                                        <div > {/* REMOVED collapse ${isProfileVisible ? 'show' : ''} */}
                                            <div className="mt-0"> {/* Adjusted mt-3 to mt-0 */}
                                                {/*Profile Photo */}
                                                <div className="mb-4 text-center">
                                                    <label htmlFor="profilePhoto" className="form-label text-uppercase small fw-bold text-secondary d-block">PROFILE PHOTO {!profilePhotoURL && <span className="text-danger">*</span>}</label>
                                                    <div className="position-relative d-inline-block">
                                                        <div
                                                            style={{
                                                                width: '100px',
                                                                height: '100px',
                                                                borderRadius: '50%',
                                                                overflow: 'hidden',
                                                                backgroundColor: '#e9ecef',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                margin: '0 auto',
                                                                border: '2px solid #ff6e1e'
                                                            }}
                                                        >
                                                            {profilePhotoURL ? (
                                                                <img
                                                                    src={profilePhotoURL}
                                                                    alt="Profile"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        objectFit: 'cover'
                                                                    }}
                                                                />
                                                            ) : (
                                                                <span className="fs-1 text-secondary">👤</span>
                                                            )}
                                                        </div>
                                                        <label
                                                            htmlFor="profilePhoto"
                                                            className="position-absolute bottom-0 right-0 bg-primary text-white rounded-circle p-1"
                                                            style={{
                                                                cursor: 'pointer',
                                                                right: '15px',
                                                                bottom: '0',
                                                                backgroundColor: '#ff6e1e'
                                                            }}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                            </svg>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id="profilePhoto"
                                                            accept="image/*"
                                                            onChange={handleProfilePhotoChange}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                    {processingHeic && (
                                                        <div className="mt-2">
                                                            <div className="progress" style={{ height: '5px' }}>
                                                                <div
                                                                    className="progress-bar"
                                                                    role="progressbar"
                                                                    style={{ width: `${compressionProgress}%`, backgroundColor: '#ff6e1e' }}
                                                                    aria-valuenow={compressionProgress}
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {photoUploading && <div className="mt-2 text-primary">Uploading...</div>}
                                                </div>
                                                <p className="text-center text-muted small">Please use latest photo without shades, cap or any face covering. Your face should be clearly visible</p> {/* Added line here */}
                                                {errors.profilePhoto && <div className="invalid-feedback d-block text-center">{errors.profilePhoto}</div>} {/* Profile photo error message */}

                                                <div className="mb-3">
                                                    <label htmlFor="fullName" className="form-label text-uppercase small fw-bold text-secondary">FULL NAME <span className="text-danger">*</span></label>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => {
                                                            setFullName(e.target.value);
                                                            setErrors(prevErrors => ({ ...prevErrors, fullName: '' }));
                                                        }}
                                                        onBlur={validateForm}
                                                        className={`form-control ${errors.fullName ? 'is-invalid' : ''}`}
                                                        id="firstName"
                                                        value={fullName}
                                                        maxLength={100}
                                                        min={1}
                                                        pattern="^[a-zA-Z\s]{2,100}$"
                                                    />
                                                    {errors.fullName && <div className="invalid-feedback">{errors.fullName}</div>}
                                                </div>

                                                <div className="mb-3" ref={cityDropdownRef}>
                                                    <label htmlFor="city" className="form-label text-uppercase small fw-bold text-secondary">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <span>CURRENT CITY <span className="text-danger">*</span></span>
                                                            <div
                                                                className="info-icon-container"
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                                title="If your city is not listed, please reach out to Shubrank and Manav so that we can add your city to the list."
                                                                onClick={handleCityInfoClick} // Added onClick for mobile
                                                            >
                                                                <FaInfoCircle
                                                                    style={{ color: '#666', fontSize: '1rem' }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <div className="position-relative">
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                onChange={handleCitySearchChange}
                                                                onFocus={() => setShowCityDropdown(true)}
                                                                className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                                                id="city"
                                                                value={citySearchTerm || city}
                                                                placeholder="Search or enter city"
                                                                autoComplete="off"
                                                            />
                                                            <span className="input-group-text">
                                                                <FaSearch />
                                                            </span>
                                                        </div>
                                                        {errors.city && <div className="invalid-feedback d-block">{errors.city}</div>}

                                                        {showCityDropdown && filteredCities.length > 0 && (
                                                            <div className="position-absolute w-100 bg-white border rounded mt-1 z-index-1000 shadow-sm" style={{ maxHeight: '200px', overflowY: 'auto', zIndex: 1000 }}>
                                                                {filteredCities.map((city, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className="p-2 hover-bg-light cursor-pointer"
                                                                        onClick={() => handleCitySelect(city)}
                                                                        style={{ cursor: 'pointer', borderBottom: '1px solid #f0f0f0' }}
                                                                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f8f9fa'}
                                                                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                                                                    >
                                                                        {city}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}

                                                        {showCityDropdown && citySearchTerm && filteredCities.length === 0 && (
                                                            <div className="position-absolute w-100 bg-white border rounded mt-1 z-index-1000 shadow-sm p-2" style={{ zIndex: 1000 }}>
                                                                <small className="text-muted">No matches found. You can use your custom entry.</small>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="profession" className="form-label text-uppercase small fw-bold text-secondary">PROFESSION <span className="text-danger">*</span></label> {/* Profession made mandatory */}
                                                    <input
                                                        type="text"
                                                        onChange={(e) => {
                                                            setProfession(e.target.value);
                                                            setErrors(prevErrors => ({ ...prevErrors, profession: '' }));
                                                        }}
                                                        onBlur={validateForm}
                                                        className={`form-control ${errors.profession ? 'is-invalid' : ''}`}
                                                        id="profession"
                                                        value={profession}
                                                        maxLength={50}
                                                    />
                                                    {errors.profession && <div className="invalid-feedback">{errors.profession}</div>}
                                                </div>


                                                {/* Blood Group Field */}
                                                <div className="mb-0">
                                                    <label htmlFor="bloodGroup" className="form-label text-uppercase small fw-bold text-secondary">BLOOD GROUP <span className="text-danger">*</span></label>
                                                    <select
                                                        id="bloodGroup"
                                                        onChange={(e) => {
                                                            setBloodGroup(e.target.value);
                                                            setErrors(prevErrors => ({ ...prevErrors, bloodGroup: '' }));
                                                        }}
                                                        onBlur={validateForm}
                                                        className={`form-control ${errors.bloodGroup ? 'is-invalid' : ''}`}
                                                        value={bloodGroup}
                                                    >
                                                        <option value="">Select Blood Group</option>
                                                        <option value="A+">A+</option>
                                                        <option value="A-">A-</option>
                                                        <option value="B+">B+</option>
                                                        <option value="B-">B-</option>
                                                        <option value="AB+">AB+</option>
                                                        <option value="AB-">AB-</option>
                                                        <option value="O+">O+</option>
                                                        <option value="O-">O-</option>
                                                        <option value="Unknown">Unknown</option>
                                                    </select>
                                                    {errors.bloodGroup && <div className="invalid-feedback">{errors.bloodGroup}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* Personal Info Section */}
                                <div className="bg-white p-3 rounded mb-4">
                                    {/* Personal Info Section - Not Collapsible, Heading Removed */}
                                    <div className="mt-3">
                                        <h6 className="text-secondary fst-italic mb-3">Your Personal Information is NOT visible to other community members</h6>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label text-uppercase small fw-bold text-secondary">EMAIL ID <span className="text-danger">*</span></label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                disabled
                                                value={userEmail}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="whatsapp" className="form-label text-uppercase small fw-bold text-secondary">WHATSAPP NUMBER <span className="text-danger">*</span></label>
                                            <div className="d-flex align-items-center">
                                                <select
                                                    id="country"
                                                    name="country"
                                                    className={`form-select form-select-sm me-2 ${errors.userCountryCode ? 'is-invalid' : ''}`}
                                                    onChange={(e) => {
                                                        setuserCountryCode(e.target.value);
                                                        setErrors((prevErrors) => ({ ...prevErrors, userCountryCode: '' }));
                                                    }}
                                                    onBlur={validateForm}
                                                    value={userCountryCode}
                                                    style={{ flexBasis: '35%', height: '36px' }}

                                                >
                                                    <option value="">Select country code</option>
                                                    {countries.map((country) => (
                                                        <option key={country.code} value={country.number}>
                                                            {country.code} {country.number}
                                                        </option>
                                                    ))}
                                                </select>
                                                <input
                                                    type="tel"
                                                    onChange={(e) => {
                                                        setUserWhatsAppNo(e.target.value);
                                                        setErrors((prevErrors) => ({ ...prevErrors, userWhatsAppNo: '' }));
                                                    }}
                                                    onBlur={validateForm}
                                                    className={`form-control ${errors.userWhatsAppNo ? 'is-invalid' : ''}`}
                                                    id="whatsapp"
                                                    value={userWhatsAppNo}
                                                    style={{ flexBasis: '65%' }}

                                                />

                                            </div>
                                            <div className="mt-2">
                                                {errors.userCountryCode && <div className="invalid-feedback d-block">{errors.userCountryCode}</div>}
                                            </div>
                                            <div className="mt-2">
                                                {errors.userWhatsAppNo && <div className="invalid-feedback d-block">{errors.userWhatsAppNo}</div>}
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="dob" className="form-label text-uppercase small fw-bold text-secondary">DATE OF BIRTH <span className="text-danger">*</span></label>
                                            <input
                                                type="date"
                                                onChange={(e) => {
                                                    setUserDOB(e.target.value);
                                                    setErrors(prevErrors => ({ ...prevErrors, userDOB: '' }));
                                                }}
                                                onBlur={validateForm}
                                                className={`form-control ${errors.userDOB ? 'is-invalid' : ''}`}
                                                id="dob"
                                                value={userDOB}
                                            />
                                            {errors.userDOB && <div className="invalid-feedback">{errors.userDOB}</div>}
                                        </div>
                                        
                                        <div className="mb-3">
                                            <label htmlFor="gender" className="form-label text-uppercase small fw-bold text-secondary">GENDER <span className="text-danger">*</span></label>
                                            <select
                                                onChange={(e) => {
                                                    setUserGender(e.target.value);
                                                    setErrors(prevErrors => ({ ...prevErrors, userGender: '' }));
                                                }}
                                                className={`form-select ${errors.userGender ? 'is-invalid' : ''}`}
                                                id="gender"
                                                value={userGender}
                                            >
                                                <option value="">Select Gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                            </select>
                                            {errors.userGender && <div className="invalid-feedback">{errors.userGender}</div>}
                                        </div>
                                    </div>
                                </div>

                                {/* Social Links Section */}
                                <div className="bg-white p-3 rounded mb-4">
                                    {/* Social Links Section - REMOVED Collapsible */}
                                    <div className="mb-3">
                                        {/* REMOVED Dropdown Button and Collapse - Always visible now */}
                                        <h6 className="text-uppercase small fw-bold text-secondary mb-3"></h6>
                                        <div > {/* REMOVED collapse ${isSocialLinksVisible ? 'show' : ''} */}
                                            <div className="mt-0"> {/* Adjusted mt-3 to mt-0 */}
                                                <div className="mb-3">
                                                    <label htmlFor="instagramLink" className="form-label text-uppercase small fw-bold text-secondary">Instagram</label>
                                                    <input
                                                        type="url"
                                                        className={`form-control ${errors.instagramLink ? 'is-invalid' : ''}`}
                                                        id="instagramLink"
                                                        placeholder="https://www.instagram.com/yourprofile"
                                                        value={instagramLink}
                                                        onChange={(e) => {
                                                            setInstagramLink(e.target.value);
                                                            setErrors(prevErrors => ({ ...prevErrors, instagramLink: '' }));
                                                        }}
                                                        onBlur={validateForm}
                                                    />
                                                    {errors.instagramLink && <div className="invalid-feedback">{errors.instagramLink}</div>}
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="linkedinLink" className="form-label text-uppercase small fw-bold text-secondary">LinkedIn</label>
                                                    <input
                                                        type="url"
                                                        className={`form-control ${errors.linkedinLink ? 'is-invalid' : ''}`}
                                                        id="linkedinLink"
                                                        placeholder="https://www.linkedin.com/in/yourprofile"
                                                        value={linkedinLink}
                                                        onChange={(e) => {
                                                            setLinkedinLink(e.target.value);
                                                            setErrors(prevErrors => ({ ...prevErrors, linkedinLink: '' }));
                                                        }}
                                                        onBlur={validateForm}
                                                    />
                                                    {errors.linkedinLink && <div className="invalid-feedback">{errors.linkedinLink}</div>}
                                                </div>

                                                <div className="mb-0">
                                                    <label htmlFor="anyOtherLink" className="form-label text-uppercase small fw-bold text-secondary">Any Other Link (Portfolio ,website etc...)</label>
                                                    <input
                                                        type="url"
                                                        className={`form-control ${errors.anyOtherLink ? 'is-invalid' : ''}`}
                                                        id="anyOtherLink"
                                                        placeholder="https://yourwebsite.com/yourprofile"
                                                        value={anyOtherLink}
                                                        onChange={(e) => {
                                                            setAnyOtherLink(e.target.value);
                                                            setErrors(prevErrors => ({ ...prevErrors, anyOtherLink: '' }));
                                                        }}
                                                        onBlur={validateForm}
                                                    />
                                                    {errors.anyOtherLink && <div className="invalid-feedback">{errors.anyOtherLink}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Emergency Contacts Section */}
                                <div className="bg-white p-3 rounded mb-4">
                                    {/* Emergency Contacts Section - Not Collapsible, Heading Removed */}
                                    <div className="mt-3">
                                        <h6 className="text-secondary fst-italic mb-3">This information is required in order to use the Emergency Alerts tool.</h6>
                                        <div className="emergency-contact p-3 mb-3 border rounded">
                                            <h3 className="h6 mb-3">EMERGENCY CONTACT 1</h3>
                                            {/* Emergency Contact 1 Fields */}
                                            <div className="mb-3">
                                                <label htmlFor="emergencyContact1Name" className="form-label text-uppercase small fw-bold text-secondary">NAME <span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    onChange={(e) => {
                                                        setemergencyContact1Name(e.target.value);
                                                        setErrors(prevErrors => ({ ...prevErrors, emergencyContact1Name: '' }));
                                                    }}
                                                    //onBlur={validateForm}
                                                    className={`form-control ${errors.emergencyContact1Name ? 'is-invalid' : ''}`}
                                                    id="emergencyContact1Name"
                                                    value={emergencyContact1Name}
                                                    maxLength={100}
                                                />
                                                {errors.emergencyContact1Name && <div className="invalid-feedback">{errors.emergencyContact1Name}</div>}
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="emergencyContact1" className="form-label text-uppercase small fw-bold text-secondary">PHONE NUMBER <span className="text-danger">*</span></label>
                                                <div className="d-flex align-items-center">
                                                    <select
                                                        id="emergencyContact1CountryCode"
                                                        name="emergencyContact1CountryCode"
                                                        className={`form-select form-select-sm me-2 ${errors.emergencyContact1CountryCode ? 'is-invalid' : ''}`}
                                                        onChange={(e) => {
                                                            setemergencyContact1CountryCode(e.target.value);
                                                            setErrors((prevErrors) => ({ ...prevErrors, emergencyContact1CountryCode: '' }));
                                                        }}
                                                        //onBlur={validateForm}
                                                        value={emergencyContact1CountryCode}
                                                        style={{ flexBasis: '35%', height: '36px' }}
                                                    >
                                                        <option value="">Select country code</option>
                                                        {countries.map((country) => (
                                                            <option key={country.code} value={country.number}>
                                                                {country.code} {country.number}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <input
                                                        type="tel"
                                                        onChange={(e) => {
                                                            setUserEmergencyContact1(e.target.value);
                                                            setErrors((prevErrors) => ({ ...prevErrors, userEmergencyContact1: '' }));
                                                        }}
                                                        //onBlur={validateForm}
                                                        className={`form-control ${errors.userEmergencyContact1 ? 'is-invalid' : ''}`}
                                                        id="emergencyContact1"
                                                        value={userEmergencyContact1}
                                                        style={{ flexBasis: '65%' }}
                                                    />
                                                </div>
                                                <div className="mt-2">
                                                    {errors.emergencyContact1CountryCode && <div className="invalid-feedback d-block">{errors.emergencyContact1CountryCode}</div>}
                                                </div>
                                                <div className="mt-2">
                                                    {errors.userEmergencyContact1 && <div className="invalid-feedback d-block">{errors.userEmergencyContact1}</div>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="emergency-contact p-3 mb-0 border rounded">
                                            <h3 className="h6 mb-3">EMERGENCY CONTACT 2</h3>
                                            {/* Emergency Contact 2 Fields */}
                                            <div className="mb-3">
                                                <label htmlFor="emergencyContact2Name" className="form-label text-uppercase small fw-bold text-secondary">NAME <span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    onChange={(e) => {
                                                        setemergencyContact2Name(e.target.value);
                                                        setErrors(prevErrors => ({ ...prevErrors, emergencyContact2Name: '' }));
                                                    }}
                                                    //onBlur={validateForm}
                                                    className={`form-control ${errors.emergencyContact2Name ? 'is-invalid' : ''}`}
                                                    id="emergencyContact2Name"
                                                    value={emergencyContact2Name}
                                                    maxLength={100}
                                                />
                                                {errors.emergencyContact2Name && <div className="invalid-feedback">{errors.emergencyContact2Name}</div>}
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="emergencyContact2" className="form-label text-uppercase small fw-bold text-secondary">PHONE NUMBER <span className="text-danger">*</span></label>
                                                <div className="d-flex align-items-center">
                                                    <select
                                                        id="emergencyContact2CountryCode"
                                                        name="emergencyContact2CountryCode"
                                                        className={`form-select form-select-sm me-2 ${errors.emergencyContact2CountryCode ? 'is-invalid' : ''}`}
                                                        onChange={(e) => {
                                                            setemergencyContact2CountryCode(e.target.value);
                                                            setErrors((prevErrors) => ({ ...prevErrors, emergencyContact2CountryCode: '' }));
                                                        }}
                                                        //onBlur={validateForm}
                                                        value={emergencyContact2CountryCode}
                                                        style={{ flexBasis: '35%', height: '36px' }}
                                                    >
                                                        <option value="">Select country code</option>
                                                        {countries.map((country) => (
                                                            <option key={country.code} value={country.number}>
                                                                {country.code} {country.number}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <input
                                                        type="tel"
                                                        onChange={(e) => {
                                                            setUserEmergencyContact2(e.target.value);
                                                            setErrors((prevErrors) => ({ ...prevErrors, userEmergencyContact2: '' }));
                                                        }}
                                                        //onBlur={validateForm}
                                                        className={`form-control ${errors.userEmergencyContact2 ? 'is-invalid' : ''}`}
                                                        id="emergencyContact2"
                                                        value={userEmergencyContact2}
                                                        style={{ flexBasis: '65%' }}
                                                    />
                                                </div>
                                                <div className="mt-2">
                                                    {errors.emergencyContact2CountryCode && <div className="invalid-feedback d-block">{errors.emergencyContact2CountryCode}</div>}
                                                </div>
                                                <div className="mt-2">
                                                    {errors.userEmergencyContact2 && <div className="invalid-feedback d-block">{errors.userEmergencyContact2}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="col-md-6 ps-md-3"  > {/* Right column - 50% width */}

                                {/* Section One: Bio and Outdoor Goals */}
                                <div className="bg-white p-3 rounded mb-4">
                                    <div className="mb-3">
                                        <label htmlFor="bio" className="form-label text-uppercase small fw-bold text-secondary">BIO</label>
                                        <textarea
                                            onChange={(e) => setBio(e.target.value)}
                                            className="form-control"
                                            id="bio"
                                            value={bio}
                                            rows="4"
                                            maxLength={5000}
                                        ></textarea>
                                    </div>

                                    <div className="mb-0">
                                        <label htmlFor="outdoorGoals" className="form-label text-uppercase small fw-bold text-secondary">
                                            {`OUTDOOR GOALS FOR ${new Date().getFullYear()}`}
                                        </label>
                                        <textarea
                                            onChange={(e) => setOutdoorGoals(e.target.value)}
                                            className="form-control"
                                            id="outdoorGoals"
                                            value={outdoorGoals}
                                            rows="3"
                                            maxLength={5000}
                                        ></textarea>
                                    </div>
                                </div>

                                {/* Section Two: Hikes Done and Wishlist */}
                                <div className="bg-white p-3 rounded mb-4">
                                    <div className="mb-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="m-0 fw-bold text-uppercase small text-secondary">HIKES DONE {treksDone.length > 0 ? `(${treksDone.length})` : ''}</h6> {/* Styled title */}
                                            <button
                                                type="button"
                                                className="btn btn-sm py-0 px-2"
                                                onClick={handleOpenTreksDoneModal}
                                                style={{
                                                    fontSize: '0.8rem',
                                                    borderRadius: '4px',
                                                    height: 'auto',
                                                    maxWidth: '50px',
                                                    backgroundColor: 'white',
                                                    color: '#ff6e1e',
                                                    borderColor: '#ff6e1e',
                                                    borderWidth: '1px'
                                                }}
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        {getTrekNamesFromIds(treksDone, 3, true)}
                                    </div>
                                </div>

                                <div className="bg-white p-3 rounded mb-4">
                                    <div className="mb-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="m-0 fw-bold text-uppercase small text-secondary">HIKES WISHLIST {treksWishlist.length > 0 ? `(${treksWishlist.length})` : ''}</h6> {/* Styled title */}
                                            <button
                                                type="button"
                                                className="btn btn-sm py-0 px-2"
                                                onClick={handleOpenTreksWishlistModal}
                                                style={{
                                                    fontSize: '0.8rem',
                                                    borderRadius: '4px',
                                                    height: 'auto',
                                                    maxWidth: '50px',
                                                    backgroundColor: 'white',
                                                    color: '#ff6e1e',
                                                    borderColor: '#ff6e1e',
                                                    borderWidth: '1px'
                                                }}
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    </div>
                                    {/* Blue info message callout with React icon */}
                                    <div className="alert alert-info mt-3 p-2" style={{ fontSize: '0.85rem', backgroundColor: '#e8f4ff', borderColor: '#b8d8ff' }}>
                                        <InfoIcon style={{ marginRight: '8px', fontSize: '1rem' }} />
                                        The hikes you select here will be added to our priority list for mapping and documentation to support you in doing these hikes.
                                    </div>
                                    <div>
                                        {getTrekNamesFromIds(treksWishlist, 3, true)}
                                    </div>
                                </div>



                                <div className="d-flex justify-content-center justify-content-md-start">
                                    <button
                                        type="button"
                                        className="btn btn-primary w-100 py-2 mb-3"
                                        onClick={handleSave}
                                        disabled={isButtonDisabled || photoUploading}
                                        style={{
                                            backgroundColor: '#ff6e1e',
                                            borderColor: '#ff6e1e',
                                            color: 'white',
                                            fontWeight: 'bold',
                                            fontSize: '1rem',
                                            borderRadius: '4px',
                                            maxWidth: '300px' // Optional: Limit button width on desktop
                                        }}
                                    >
                                        {photoUploading ? 'Uploading Photo...' : 'Save Profile'}
                                    </button>
                                </div>

                            </div>
                        </div>


                    </div>
                </main>
            </div >

            {/* Treks Done Modal */}
            < TrekSelectionModal
                isOpen={isTreksDoneModalOpen}
                title="Edit Hikes Done"
                modalType='done'
                selectedTrekIds={modalTreksDone}
                availableTreks={availableTreks}
                onClose={handleCloseTreksDoneModal}
                onSave={handleSaveTreksDoneModal}
                onChange={handleModalTrekDoneChange}
                onAddNewTrek={(trekName, trekState) => handleAddNewTrek(trekName, trekState, 'done')}
            />

            {/* Treks Wishlist Modal */}
            < TrekSelectionModal
                isOpen={isTreksWishlistModalOpen}
                title="Edit Hikes Wishlist"
                modalType='wishlist'
                selectedTrekIds={modalTreksWishlist}
                availableTreks={availableTreks}
                onClose={handleCloseTreksWishlistModal}
                onSave={handleSaveTreksWishlistModal}
                onChange={handleModalTrekWishlistChange}
                onAddNewTrek={(trekName, trekState) => handleAddNewTrek(trekName, trekState, 'wishlist')}
            />

            {showConfirmation && (
                <div className="trek-confirmation-message">
                    <div className="trek-confirmation-content">
                        <span className="trek-confirmation-icon">✓</span>
                        <span className="trek-confirmation-text">Your changes were saved</span>
                    </div>
                    <button
                        className="trek-confirmation-close"
                        onClick={() => setShowConfirmation(false)}
                        aria-label="Close confirmation"
                    >
                        ×
                    </button>
                </div>
            )}

            {loading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                >
                    <div className="text-center">
                        <ClipLoader color="#ff6e1e" size={50} />
                    </div>
                </div>
            )
            }
        </div >
    );
}

export default ProfileForm;