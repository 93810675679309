import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { ClipLoader } from 'react-spinners';
import { Info, ArrowLeft } from 'lucide-react';
import '../Css/Workshop.css';

const Workshop = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(['userId']);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [showInfoIcon, setShowInfoIcon] = useState(true);
  const db = getFirestore();

  useEffect(() => {
    const checkAccess = async () => {
      setLoading(true);
      try {
        const userIdFromLocalStorage = localStorage.getItem('userId');
        const userIdFromCookies = cookies.userId;
        const userId = userIdFromLocalStorage || userIdFromCookies;

        if (!userId) {
          navigate('/login');
          return;
        }

        const docRef = doc(db, "UserTable", userId);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          navigate('/login');
          return;
        }

        const userData = docSnap.data();
        
        if (userData.workshopAccess !== true) {
          navigate('/products');
          return;
        }

        setUserData(userData);
      } catch (error) {
        console.error("Error checking access:", error);
        navigate('/products');
      } finally {
        setLoading(false);
      }
    };

    checkAccess();
  }, [cookies, navigate, db]);

  useEffect(() => {
    // Auto-close info modal and hide icon on desktop after 5 seconds
    if (window.innerWidth >= 768) {
      const timer = setTimeout(() => {
        setShowInfo(false);
        setShowInfoIcon(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <ClipLoader color="#ff6e1e" size={50} />
      </div>
    );
  }

  return (
    <div className="workshop-page">
      <div className="workshop-container">
      <div className="workshop-actions" style={{ position: 'absolute', top: '10px', left: '10px' }}>
          <button
            onClick={() => navigate('/products')}
            className="back-button"
          >
            <ArrowLeft size={10} /> Back
          </button>
        </div>
        <h1 
          className="text-center" 
          style={{
            fontSize: '1.45rem',
            //fontWeight: 'bolder',
            //fontStyle: 'italic',
            textAlign: 'center',
            fontFamily: "'Dela Gothic One'",
          }}
        >
          WORKSHOP: GROWTH BLUE PRINT FOR HIKERS
        </h1>
        <div className="workshop-card">
          <div className="video-container">
            <iframe
              src="https://play.gumlet.io/embed/67679400a080a6ad16009134?preload=false&autoplay=false&loop=false&background=false&disable_player_controls=false"
              className="video-iframe"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen;"
              allowFullScreen
              title="Independent Hiking Workshop"
            ></iframe>
          </div>

          <div className="workshop-content">
            <div className="workshop-header">
             
            </div>
          </div>
        </div>
        {/* New Footer */}
        <div
        className="w-100 text-center py-3"
        style={{
          color: '#6B7280',
          fontSize: '0.875rem',
          marginTop: 'auto',
          backgroundColor: 'transparent'
        }}
      >
        Copyright © Rush Labs
        | <a href="https://docs.google.com/document/d/e/2PACX-1vQ0Jtw8bX3Uc7iPkMQc-494wfPC24FxnE48SAGW61Ld6wskhFngri_SRGzc6_BIfUSTsSSWr__v-vwX/pub"  target='_blank' style={{ color: '#6B7280', textDecoration: 'underline' }}>
          Terms & Member Guidelines
        </a> |
        Support : <a href="mailto:hi@manav.in" style={{ color: '#6B7280', textDecoration: 'underline' }}>
          hi@manav.in
        </a>
      </div>
      </div>
    </div>
  );
};

export default Workshop;