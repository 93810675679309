import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { ClipLoader } from 'react-spinners';
import { ArrowLeft } from 'lucide-react';
import '../Css/TrekPlanningForm.css';

function TrekPlanningForm() {
    const navigate = useNavigate();
    const [cookies] = useCookies(['userId']);
    const [loading, setLoading] = useState(true);
    const [formLoading, setFormLoading] = useState(false); // Separate loading for form submission
    const [errors, setErrors] = useState({});
    const [userData, setUserData] = useState(null);
    const db = getFirestore();

    const [formData, setFormData] = useState({
        name: '', // Will be pre-filled
        email: '', // Will be pre-filled
        trekRegion: '',
        preferredDates: '',
        researchDone: '',
        questions: ['', '', ''], // Array for multiple questions, initialize with 3 empty strings
        agreeToTerms: false
    });

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const userIdFromLocalStorage = localStorage.getItem('userId');
                const userIdFromCookies = cookies.userId;
                const userId = userIdFromLocalStorage || userIdFromCookies;

                if (!userId) {
                    navigate('/login');
                    return;
                }

                const userDocRef = doc(db, "UserTable", userId);
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    const data = userDocSnap.data();
                    setUserData(data);
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        name: data.FullName || 'N/A', // Use displayName or fallback
                        email: data.Email || ''
                    }));
                } else {
                    console.log("No such document!");
                    // Optionally handle user not found, maybe redirect to login or error page
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
                // Handle error appropriately, maybe redirect or show error message
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [cookies, navigate, db]);

    const validateForm = () => {
        let formErrors = {};

        if (!formData.trekRegion.trim()) {
            formErrors.trekRegion = "Trek/Region is required.";
        }
        if (!formData.preferredDates.trim()) {
            formErrors.preferredDates = "Preferred dates are required.";
        }
        if (!formData.researchDone.trim()) {
            formErrors.researchDone = "Please share your research.";
        }
        if (formData.questions.every(q => !q.trim())) { // Check if all questions are empty
            formErrors.questions = "Please list at least one question.";
        }
        if (!formData.agreeToTerms) {
            formErrors.agreeToTerms = "You must agree to the terms.";
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const formatWhatsAppMessage = () => {
        const questionsList = formData.questions
            .filter(q => q.trim() !== '') // Filter out empty questions
            .map((question, index) => `- Question ${index + 1}: ${question.trim()}`)
            .join('\n');

        return `*New Trek Planning Session Request*
👤 Name: ${formData.name}
📧 Email: ${formData.email}

🏔️ Trek/Region of Interest: ${formData.trekRegion}
📅 Preferred Dates: ${formData.preferredDates}

*Research Done:*
${formData.researchDone}

*Questions for Discussion:*
${questionsList}`;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setFormLoading(true); // Use formLoading for submit button loading state

        try {
            // Here you can add API call to save the data to your backend if needed

            // Format phone number for WhatsApp (replace with your admin number)
            const adminPhone = "917878902595";
            const message = encodeURIComponent(formatWhatsAppMessage());
            const whatsappLink = `https://wa.me/${adminPhone}?text=${message}`;

            window.open(whatsappLink, '_blank');
            navigate('/products'); // Redirect to success page or handle as needed
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setFormLoading(false);
        }
    };

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
        if (errors[field]) {
            setErrors(prev => ({
                ...prev,
                [field]: ''
            }));
        }
    };

    const handleQuestionChange = (index, value) => {
        const updatedQuestions = [...formData.questions];
        updatedQuestions[index] = value;
        setFormData(prev => ({
            ...prev,
            questions: updatedQuestions
        }));
        if (errors.questions) {
            setErrors(prev => ({
                ...prev,
                questions: '' // Clear question errors as user types in any question field
            }));
        }
    };


    const onBackClick = () => {
        navigate(-1);
    };

    if (loading) {
        return (
            <div className="loading-container">
                <ClipLoader color="#ff6e1e" size={50} />
            </div>
        );
    }

    return (
        <div className={`form-container ${formLoading ? 'loading' : ''}`}>
            <div className="form-inner-container">
                <div className="form-header">
                    <h1 className="main-title">TREK PLANNING</h1>
                </div>

                <header className="back-header">
                    <div className="back-link">
                        <a onClick={onBackClick}>
                            <ArrowLeft size={16} /> Back
                        </a>
                    </div>
                </header>

                <div className="form-content">
                    <div className="form-section">
                        <h1 className="form-title">Schedule 1-on-1 Trek Planning Session</h1>

                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Full Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    value={formData.name}
                                    readOnly // Make name read-only
                                    className={errors.name ? 'error' : ''}
                                />
                                {errors.name && <div className="error-message">{errors.name}</div>}
                            </div>


                            <div className="form-group">
                                <label htmlFor="email">Email Address</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={formData.email}
                                    readOnly // Make email read-only
                                    className={errors.email ? 'error' : ''}
                                />
                                {errors.email && <div className="error-message">{errors.email}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="trekRegion">Trek/Region of Interest</label>
                                <small className="helper-text">Enter the trek or region you're interested in</small>
                                <input
                                    type="text"
                                    id="trekRegion"
                                    value={formData.trekRegion}
                                    onChange={(e) => handleInputChange('trekRegion', e.target.value)}
                                    className={errors.trekRegion ? 'error' : ''}
                                />
                                {errors.trekRegion && <div className="error-message">{errors.trekRegion}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="preferredDates">Preferred Dates for Call</label>
                                <small className="helper-text">When would you like to schedule the call?</small>
                                <input
                                    type="text"
                                    id="preferredDates"
                                    value={formData.preferredDates}
                                    onChange={(e) => handleInputChange('preferredDates', e.target.value)}
                                    className={errors.preferredDates ? 'error' : ''}
                                    placeholder="e.g., Weekday evenings, Weekend mornings"
                                />
                                {errors.preferredDates && <div className="error-message">{errors.preferredDates}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="researchDone">Research Done So Far</label>
                                <small className="helper-text">Share what you've learned about this trek</small>
                                <textarea
                                    id="researchDone"
                                    value={formData.researchDone}
                                    onChange={(e) => handleInputChange('researchDone', e.target.value)}
                                    className={errors.researchDone ? 'error' : ''}
                                    rows="4"
                                />
                                {errors.researchDone && <div className="error-message">{errors.researchDone}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="questions">Questions for Discussion</label>
                                <small className="helper-text">List your specific questions and concerns</small>
                                {formData.questions.map((question, index) => (
                                    <div key={index} className="question-item">
                                        <label htmlFor={`question${index + 1}`}>Question {index + 1}</label>
                                        <textarea
                                            id={`question${index + 1}`}
                                            value={question}
                                            onChange={(e) => handleQuestionChange(index, e.target.value)}
                                            className={errors.questions ? 'error' : ''}
                                            rows="2" // Reduced rows for each question
                                            placeholder={`Enter question ${index + 1}`}
                                        />
                                    </div>
                                ))}
                                {errors.questions && <div className="error-message">{errors.questions}</div>}
                            </div>


                            <div className="form-group checkbox-group">
                                <input
                                    type="checkbox"
                                    id="agreeToTerms"
                                    checked={formData.agreeToTerms}
                                    onChange={(e) => handleInputChange('agreeToTerms', e.target.checked)}
                                />
                                <label htmlFor="agreeToTerms">
                                    I understand that this will send a WhatsApp message to schedule a planning session.
                                </label>
                                {errors.agreeToTerms && <div className="error-message">{errors.agreeToTerms}</div>}
                            </div>

                            <button
                                type="submit"
                                className="submit-button"
                                disabled={formLoading || !formData.agreeToTerms}
                            >
                                {formLoading ? <ClipLoader color="#ff6e1e" size={50} /> : 'Schedule Planning Session'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            {formLoading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                </div>
            )}
        </div>
    );
}

export default TrekPlanningForm;