import React from 'react';
import { FileText, ExternalLink } from 'lucide-react';

const TrailNotesModal = ({ onClose }) => {
  // Trail notes data with links
  const trailNotes = [
    {
      id: 1,
      title: "EBC",
      description: "Google Sheet by Swarup Potta",
      link: "https://docs.google.com/spreadsheets/d/1p56CdUW6mY_fdFfnY_FoutKJNJWkOwvmrayDdVttb0A/edit?gid=1717038415#gid=1717038415"
    },
    {
      id: 2,
      title: "Interactive Uttarakhand Map",
      description: "by Amit Tiwary",
      link: "https://traveltitan.in/uk.html"
    },
    {
      id: 3,
      title: "ACT",
      description: "Google Sheet by Deepika Ramu",
      link: "https://docs.google.com/spreadsheets/d/1Zn4EyyaBgxZnkpTOWW1Pv-HGXD-oGOYxN1V041UTkts/edit?usp=sharing"
    },
    {
      id: 3,
      title: "Mountains of Things",
      description: "blog on hiking western himalayas by Pete",
      link: "https://mountainsofthings.substack.com/archive"
    },
    {
      id: 4,
      title: "Trails Matter",
      description: "blog by Meera Patel",
      link: "https://pmeeraptl.substack.com/archive"
    },
    {
      id: 5,
      title: "Nomadosauras",
      description: "blog/website by Saurabh Sawant",
      link: "https://nomadosauras.com/escapades-list/"
    },
    {
      id: 6,
      title: "Educational shorts",
      description: "by Manav",
      link: "https://www.youtube.com/@AskManavTv/shorts"
    }
  ];

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
      }}
    >
      <div className="card" style={{
        maxWidth: '500px',
        width: '90%',
        margin: '20px',
        borderRadius: '12px',
        boxShadow: '0 10px 25px rgba(0,0,0,0.1)',
        maxHeight: '80vh',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <div className="card-body p-4 overflow-auto">
          <div className="d-flex align-items-center mb-3">
            <FileText size={32} color="#4A90E2" className="me-3" />
            <h2 className="card-title mb-0" style={{ color: '#333' }}>
              Trail Notes by Members
            </h2>
          </div>
          
          <div className="card-text mb-4">
            <p className="text-muted mb-3">
              Compilation of useful blogs, videos and trail documentation created by community members.
            </p>
            
            <ul className="list-unstyled">
              {trailNotes.map(note => (
                <li key={note.id} className="mb-3 p-2 border-bottom">
                  <a 
                    href={note.link} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="d-flex align-items-start text-decoration-none"
                  >
                    <div>
                      <div style={{ color: '#4A90E2', fontWeight: '600' }}>
                        <strong>{note.title}</strong> <ExternalLink size={14} />
                      </div>
                      <div className="text-muted small">{note.description}</div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
            
            <div className="mt-4 p-3 bg-light rounded">
              <p className="mb-0 text-muted small">
                If you have documented your hikes in some format (video, post, website etc) that others members can find useful in planning / executing their hikes — then please share the link with Shubrank & we'll add it to the list here.
              </p>
            </div>
          </div>
          
          <div className="d-flex justify-content-end mt-3">
            <button
              onClick={onClose}
              className="btn btn-primary"
              style={{
                backgroundColor: '#4A90E2',
                borderColor: '#4A90E2',
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrailNotesModal;