import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, orderBy, query } from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { Copy, User, AlertCircle, CheckCircle, XCircle, ArrowLeft } from 'lucide-react';
import "../Css/dashboard.css";

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const DashboardAlerts = () => {
  const navigate = useNavigate();
  const [usersCount, setUsersCount] = useState(0);
  const [alertsCount, setAlertsCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [completeProfileUsers, setCompleteProfileUsers] = useState([]);
  const [incompleteProfileUsers, setIncompleteProfileUsers] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState("completeUsers");
  const [loading, setLoading] = useState(true);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const usersCollection = collection(db, "UserTable");
        const alertsCollection = collection(db, "AlertTable");

        const [usersSnapshot, alertsSnapshot] = await Promise.all([
          getDocs(usersCollection),
          getDocs(query(alertsCollection, orderBy("CreatedOn", "desc")))
        ]);

        // Create a map of user data for quick lookup
        const usersMap = new Map();
        usersSnapshot.docs.forEach(doc => {
          usersMap.set(doc.id, {
            FullName: doc.data().FullName || '',
            Email: doc.data().Email || '',
            WhatsAppNo: doc.data().WhatsAppNo || '',
            UserCountryCode: doc.data().UserCountryCode || '',
          });
        });

        // Combine alert data with user data
        const alertsData = alertsSnapshot.docs.map((doc) => {
          const userData = usersMap.get(doc.data().UserId) || {};
          return {
            id: doc.id,
            TripName: doc.data().TripName || '',
            Location: doc.data().Location || '',
            CreatedOn: doc.data().CreatedOn,
            ReturnTimestamp: doc.data().ReturnTimestamp,
            IsTripCompleted: doc.data().IsTripCompleted || false,
            RouteDetails: doc.data().RouteDetails || '',
            Activity: doc.data().Activity || '',
            UserId: doc.data().UserId || '',
            // Add user details
            UserName: userData.FullName || 'N/A',
            UserEmail: userData.Email || 'N/A',
            UserWhatsApp: userData.WhatsAppNo ? `${userData.UserCountryCode} ${userData.WhatsAppNo}` : 'N/A',
          };
        });

        setAlerts(alertsData);
        setAlertsCount(alertsSnapshot.size);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "N/A";
    return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).toLocaleString();
  };

  const filteredCompleteUsers = completeProfileUsers.filter(user => 
    user.FullName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.Email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.id?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredIncompleteUsers = incompleteProfileUsers.filter(user => 
    user.FullName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.Email?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredAlerts = alerts.filter(alert => 
    alert.TripName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    alert.Location?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    alert.UserName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    alert.UserEmail?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="dashboard-root">
      <div className="dashboard-container">
        {/* Top Navigation */}
        <div className="top-nav">
          <div className="nav-links">
            <button className="nav-link" onClick={() => navigate('/dashboard')}>Users</button>
            <button className="nav-link" onClick={() => navigate('/dashboard/add-product')}>Products</button>
            <button className="nav-link active" onClick={() => navigate('/dashboard/alerts')}>Alerts ({alertsCount})</button>
            <button className="nav-link" onClick={() => navigate('/dashboard/workshop')}>Payments</button>
          </div>
          <button className="back-button" onClick={() => navigate('/dashboard')}>Back</button>
        </div>

        <div className="dashboard-content">
          {/* Search Bar */}
          <div className="search-container">
            <input
              type="text"
              className="search-input"
              placeholder="Search alerts by trip name, location, or user details..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          {/* Alerts Table */}
          {loading ? (
            <div className="loading">Loading...</div>
          ) : (
            <div className="table-wrapper">
              <div className="table-container">
                <table className="users-table">
                  <thead>
                    <tr>
                      <th>Trip Name</th>
                      <th>User Name</th>
                      <th>Email</th>
                      <th>WhatsApp</th>
                      <th>Created On</th>
                      <th>Return Time</th>
                      <th>Status</th>
                      <th>Activity</th>
                      <th>Location</th>
                      <th>Route Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {alerts
                      .filter(alert => 
                        alert.TripName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        alert.Location?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        alert.UserName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        alert.UserEmail?.toLowerCase().includes(searchQuery.toLowerCase())
                      )
                      .map((alert) => (
                        <tr key={alert.id}>
                          <td>{alert.TripName}</td>
                          <td>{alert.UserName}</td>
                          <td>{alert.UserEmail}</td>
                          <td>{alert.UserWhatsApp}</td>
                          <td>{formatTimestamp(alert.CreatedOn)}</td>
                          <td>{formatTimestamp(alert.ReturnTimestamp)}</td>
                          <td>
                            <span className={`status-badge ${alert.IsTripCompleted ? 'status-active' : 'status-inactive'}`}>
                              {alert.IsTripCompleted ? 'Completed' : 'In Progress'}
                            </span>
                          </td>
                          <td>{alert.Activity}</td>
                          <td>{alert.Location}</td>
                          <td>{alert.RouteDetails}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {alerts.length === 0 && !loading && (
                  <div className="no-results">No alerts found matching your criteria</div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardAlerts;