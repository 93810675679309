import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs, doc, setDoc, updateDoc, addDoc, query, orderBy, deleteDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { Copy, ChevronUp, ChevronDown, ChevronRight, ChevronDown as ExpandIcon, Edit, Trash2, Plus } from 'lucide-react';
import '../Css/DashboardNew.css';

const Dashboard = () => {
  const navigate = useNavigate();
  const db = getFirestore();
  const auth = getAuth();

  const [users, setUsers] = useState([]);
  const [auditLogs, setAuditLogs] = useState([]);
  const [treks, setTreks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [logsLoading, setLogsLoading] = useState(true);
  const [treksLoading, setTreksLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [logSearchQuery, setLogSearchQuery] = useState('');
  const [trekSearchQuery, setTrekSearchQuery] = useState('');
  const [showNewUserForm, setShowNewUserForm] = useState(false);
  const [showNewTrekForm, setShowNewTrekForm] = useState(false);
  const [activeTab, setActiveTab] = useState('all');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [expandedLogId, setExpandedLogId] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: 'CreatedOn',
    direction: 'desc'
  });
  const [logSortConfig, setLogSortConfig] = useState({
    key: 'timestamp',
    direction: 'desc'
  });
  const [trekSortConfig, setTrekSortConfig] = useState({
    key: 'added_on',
    direction: 'desc'
  });

  const initialUserState = {
    email: '',
    fullName: '',
    workshopAccess: false,
    isMember: false
  };
  const initialTrekState = {
    trek_name: '',
    state: ''
  };

  const [newUser, setNewUser] = useState(initialUserState);
  const [newTrek, setNewTrek] = useState(initialTrekState);
  const [trekNameMap, setTrekNameMap] = useState({});
  const [editingTrekId, setEditingTrekId] = useState(null);
  const [editedTrekName, setEditedTrekName] = useState('');
  const [editedTrekState, setEditedTrekState] = useState('');

  useEffect(() => {
    fetchUsers();
    if (activeTab === 'auditLog') {
      fetchAuditLogs();
      fetchTrekNames();
    } else if (activeTab === 'treks') {
      fetchTreks();
    }
  }, [activeTab]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const usersSnapshot = await getDocs(collection(db, 'UserTable'));
      const usersData = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUsers(usersData);
    } catch (error) {
      setError('Error fetching users: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchAuditLogs = async () => {
    try {
      setLogsLoading(true);
      const logsQuery = query(
        collection(db, 'ProfileChangeLogs'),
        orderBy('timestamp', 'desc')
      );
      const logsSnapshot = await getDocs(logsQuery);
      const logsData = logsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        expanded: false
      }));
      setAuditLogs(logsData);
    } catch (error) {
      setError('Error fetching audit logs: ' + error.message);
    } finally {
      setLogsLoading(false);
    }
  };

  const fetchTrekNames = async () => {
    try {
      const treksSnapshot = await getDocs(collection(db, 'trek_db'));
      const trekMap = {};
      treksSnapshot.docs.forEach(doc => {
        trekMap[doc.id] = doc.data().trek_name || 'Unknown Trek';
      });
      setTrekNameMap(trekMap);
    } catch (error) {
      console.error('Error fetching trek names:', error);
    }
  };

  const fetchTreks = async () => {
    try {
      setTreksLoading(true);
      const treksSnapshot = await getDocs(collection(db, 'trek_db'));
      const treksData = treksSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setTreks(treksData);
    } catch (error) {
      setError('Error fetching treks: ' + error.message);
    } finally {
      setTreksLoading(false);
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      setLoading(true);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        newUser.email,
        Math.random().toString(36).slice(-8)
      );

      const userDoc = {
        Email: newUser.email,
        FullName: newUser.fullName,
        workshopAccess: newUser.workshopAccess,
        isMember: newUser.isMember,
        CreatedOn: new Date(),
        isProfileComplete: false
      };

      await setDoc(doc(db, 'UserTable', userCredential.user.uid), userDoc);
      await sendPasswordResetEmail(auth, newUser.email);
      setSuccess('User created successfully! Password reset email sent.');
      setShowNewUserForm(false);
      setNewUser(initialUserState);
      fetchUsers();
    } catch (error) {
      setError('Error creating user: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateTrek = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      setTreksLoading(true);
      const trekDoc = {
        trek_name: newTrek.trek_name,
        state: newTrek.state,
        added_by: 'admin', // Assuming admin adds treks from dashboard
        added_on: new Date(),
        timestamp: new Date()
      };
      await addDoc(collection(db, 'trek_db'), trekDoc);
      setSuccess('Trek created successfully!');
      setShowNewTrekForm(false);
      setNewTrek(initialTrekState);
      fetchTreks();
      fetchTrekNames(); // Refresh trek names for maps
    } catch (error) {
      setError('Error creating trek: ' + error.message);
    } finally {
      setTreksLoading(false);
    }
  };

  const handleUpdateTrekName = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      setTreksLoading(true);
      await updateDoc(doc(db, 'trek_db', editingTrekId), {
        trek_name: editedTrekName,
        state: editedTrekState,
        timestamp: new Date()
      });
      setSuccess('Trek updated successfully!');
      setEditingTrekId(null);
      setEditedTrekName('');
      setEditedTrekState('');
      fetchTreks();
      fetchTrekNames();
    } catch (error) {
      setError('Error updating trek: ' + error.message);
    } finally {
      setTreksLoading(false);
    }
  };

  const handleDeleteTrek = async (trekId) => {
    if (window.confirm('Are you sure you want to delete this trek?')) {
      try {
        setTreksLoading(true);
        await deleteDoc(doc(db, 'trek_db', trekId));
        setSuccess('Trek deleted successfully!');
        fetchTreks();
        fetchTrekNames();
      } catch (error) {
        setError('Error deleting trek: ' + error.message);
      } finally {
        setTreksLoading(false);
      }
    }
  };


  const toggleWorkshopAccess = async (userId, currentAccess) => {
    try {
      await updateDoc(doc(db, 'UserTable', userId), {
        workshopAccess: !currentAccess
      });
      setSuccess('Workshop access updated successfully!');
      fetchUsers();
    } catch (error) {
      setError('Error updating workshop access: ' + error.message);
    }
  };

  const toggleMemberAccess = async (userId, currentAccess) => {
    try {
      await updateDoc(doc(db, 'UserTable', userId), {
        isMember: !currentAccess
      });
      setSuccess('Member access updated successfully!');
      fetchUsers();
    } catch (error) {
      setError('Error updating member access: ' + error.message);
    }
  };

  const toggleMemberDirectoryVisible = async (userId, currentAccess) => {
    try {
      await updateDoc(doc(db, 'UserTable', userId), {
        isDirectoryVisible: !currentAccess
      });
      setSuccess('Member access updated successfully!');
      fetchUsers();
    } catch (error) {
      setError('Error updating member access: ' + error.message);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setSuccess(`Copied to clipboard!`);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "N/A";
    if (timestamp instanceof Date) {
      return timestamp.toLocaleString();
    }
    return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).toLocaleString();
  };

  const handleSort = (key) => {
    setSortConfig({
      key,
      direction: sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc'
    });
  };

  const handleLogSort = (key) => {
    setLogSortConfig({
      key,
      direction: logSortConfig.key === key && logSortConfig.direction === 'asc' ? 'desc' : 'asc'
    });
  };

  const handleTrekSort = (key) => {
    setTrekSortConfig({
      key,
      direction: trekSortConfig.key === key && trekSortConfig.direction === 'asc' ? 'desc' : 'asc'
    });
  };

  const getSortedUsers = (users) => {
    const sortedUsers = [...users].sort((a, b) => {
      let aValue, bValue;

      if (sortConfig.key === 'FullName') {
        aValue = (a.FullName || a.Name || '').toString().toLowerCase();
        bValue = (b.FullName || b.Name || '').toString().toLowerCase();
      } else if (sortConfig.key === 'CreatedOn') {
        aValue = a[sortConfig.key]?.seconds || 0;
        bValue = b[sortConfig.key]?.seconds || 0;
      } else {
        aValue = (a[sortConfig.key] || '').toString().toLowerCase();
        bValue = (b[sortConfig.key] || '').toString().toLowerCase();
      }

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
    return sortedUsers;
  };

  const getSortedLogs = (logs) => {
    const sortedLogs = [...logs].sort((a, b) => {
      let aValue, bValue;

      if (logSortConfig.key === 'timestamp') {
        aValue = a[logSortConfig.key]?.seconds || (a[logSortConfig.key] instanceof Date ? a[logSortConfig.key].getTime() : 0);
        bValue = b[logSortConfig.key]?.seconds || (b[logSortConfig.key] instanceof Date ? b[logSortConfig.key].getTime() : 0);
      } else if (logSortConfig.key === 'userName') {
        aValue = (a[logSortConfig.key] || '').toString().toLowerCase();
        bValue = (b[logSortConfig.key] || '').toString().toLowerCase();
      } else {
        aValue = (a[logSortConfig.key] || '').toString().toLowerCase();
        bValue = (b[logSortConfig.key] || '').toString().toLowerCase();
      }

      if (aValue < bValue) return logSortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return logSortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
    return sortedLogs;
  };

  const getSortedTreks = (treks) => {
    const sortedTreks = [...treks].sort((a, b) => {
      let aValue, bValue;

      if (trekSortConfig.key === 'trek_name') {
        aValue = (a.trek_name || '').toString().toLowerCase();
        bValue = (b.trek_name || '').toString().toLowerCase();
      } else if (trekSortConfig.key === 'added_on') {
        aValue = a.added_on?.seconds || 0;
        bValue = b.added_on?.seconds || 0;
      } else {
        aValue = (a[trekSortConfig.key] || '').toString().toLowerCase();
        bValue = (b[trekSortConfig.key] || '').toString().toLowerCase();
      }

      if (aValue < bValue) return trekSortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return trekSortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
    return sortedTreks;
  };


  const filteredUsers = () => {
    let filtered = users.filter(user => {
      const matchesSearch = user.Email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.FullName?.toLowerCase().includes(searchQuery.toLowerCase());

      switch (activeTab) {
        case 'members':
          return matchesSearch && user.isMember;
        case 'nonMembers':
          return matchesSearch && !user.isMember;
        default:
          return matchesSearch;
      }
    });

    return getSortedUsers(filtered);
  };

  const filteredLogs = () => {
    if (!auditLogs || auditLogs.length === 0) return [];

    let filtered = auditLogs.filter(log => {
      const matchesSearch =
        log.userName?.toLowerCase().includes(logSearchQuery.toLowerCase()) ||
        log.userEmail?.toLowerCase().includes(logSearchQuery.toLowerCase()) ||
        log.userId?.toLowerCase().includes(logSearchQuery.toLowerCase()) ||
        log.action?.toLowerCase().includes(logSearchQuery.toLowerCase());

      return matchesSearch;
    });

    return getSortedLogs(filtered);
  };

  const filteredTreks = () => {
    let filtered = treks.filter(trek => {
      const matchesSearch = trek.trek_name?.toLowerCase().includes(trekSearchQuery.toLowerCase()) || trek.state?.toLowerCase().includes(trekSearchQuery.toLowerCase());
      return matchesSearch;
    });
    return getSortedTreks(filtered);
  };


  const toggleLogExpansion = (logId) => {
    setExpandedLogId(expandedLogId === logId ? null : logId);
  };

  const SortIcon = ({ columnKey }) => {
    if (sortConfig.key !== columnKey) return null;
    return sortConfig.direction === 'asc' ? <ChevronUp size={16} /> : <ChevronDown size={16} />;
  };

  const LogSortIcon = ({ columnKey }) => {
    if (logSortConfig.key !== columnKey) return null;
    return logSortConfig.direction === 'asc' ? <ChevronUp size={16} /> : <ChevronDown size={16} />;
  };

  const TrekSortIcon = ({ columnKey }) => {
    if (trekSortConfig.key !== columnKey) return null;
    return trekSortConfig.direction === 'asc' ? <ChevronUp size={16} /> : <ChevronDown size={16} />;
  };

  const getTrekName = useCallback((trekId) => {
    return trekNameMap[trekId] || `Trek ${trekId}`;
  }, [trekNameMap]);

  const displayTrekArray = useCallback((trekArray) => {
    if (!trekArray || !Array.isArray(trekArray) || trekArray.length === 0) {
      return 'None';
    }

    return trekArray.map(id => getTrekName(id)).join(', ');
  }, [getTrekName]);

  const renderChangedFieldsDetails = useCallback((log) => {
    if (!log.changedFields) return <p>No change details available</p>;

    return (
      <div className="changed-fields-details">
        <h4>Changed Fields</h4>
        <table className="changes-table">
          <thead>
            <tr>
              <th>Field</th>
              <th>Old Value</th>
              <th>New Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(log.changedFields).map(([field, values]) => (
              <tr key={field}>
                <td>{field}</td>
                <td>
                  {field === 'ProfilePhotoURL' ? (
                    values.old ? (
                      <div className="image-preview">
                        <img src={values.old} alt="Old profile" width="100" />
                        <a href={values.old} target="_blank" rel="noopener noreferrer">View</a>
                      </div>
                    ) : 'No image'
                  ) : field === 'TreksDone' || field === 'Wishlist' || field === 'TreksWishlist' ? ( // Added TreksWishlist here
                    Array.isArray(values.old) ? displayTrekArray(values.old) : 'None'
                  ) : Array.isArray(values.old) ? (
                    values.old.length > 0 ? values.old.join(', ') : 'None'
                  ) : (
                    String(values.old || '')
                  )}
                </td>
                <td>
                  {field === 'ProfilePhotoURL' ? (
                    values.new ? (
                      <div className="image-preview">
                        <img src={values.new} alt="New profile" width="100" />
                        <a href={values.new} target="_blank" rel="noopener noreferrer">View</a>
                      </div>
                    ) : 'No image'
                  ) : field === 'TreksDone' || field === 'Wishlist' || field === 'TreksWishlist' ? ( // Added TreksWishlist here
                    Array.isArray(values.new) ? displayTrekArray(values.new) : 'None'
                  ) : Array.isArray(values.new) ? (
                    values.new.length > 0 ? values.new.join(', ') : 'None'
                  ) : (
                    String(values.new || '')
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {log.imageChanged && (
          <div className="image-metadata">
            <h4>Image Metadata</h4>
            {log.imageMetadata ? (
              <ul>
                <li>File Name: {log.imageMetadata.fileName}</li>
                <li>File Type: {log.imageMetadata.fileType}</li>
                <li>File Size: {log.imageMetadata.fileSize}</li>
                <li>Last Modified: {new Date(log.imageMetadata.lastModified).toLocaleString()}</li>
              </ul>
            ) : (
              <p>Image changed but no metadata available</p>
            )}
          </div>
        )}
      </div>
    );
  }, [displayTrekArray]);

  const getChangeSummary = (log) => {
    if (!log.changedFields) return 'No changes recorded';

    const fieldCount = Object.keys(log.changedFields).length;
    if (fieldCount === 0) return 'No fields changed';

    const fields = Object.keys(log.changedFields);
    if (fieldCount === 1) return `Changed: ${fields[0]}`;

    return `Changed ${fieldCount} fields: ${fields.slice(0, 2).join(', ')}${fieldCount > 2 ? '...' : ''}`;
  };

  const getTrekCounts = (trekId) => {
    let doneCount = 0;
    let wishlistCount = 0;
    users.forEach(user => {
      if (user.TreksDone && user.TreksDone.includes(trekId)) {
        doneCount++;
      }
      if (user.TreksWishlist && user.TreksWishlist.includes(trekId)) {
        wishlistCount++;
      }
    });
    return { doneCount, wishlistCount };
  };

  const showUsersForTrek = (trekId, listType) => {
    const userList = users.filter(user => {
      if (listType === 'done') {
        return user.TreksDone && user.TreksDone.includes(trekId);
      } else if (listType === 'wishlist') {
        return user.TreksWishlist && user.TreksWishlist.includes(trekId);
      }
      return false;
    }).map(user => `${user.FullName} (${user.Email})`).join('\n');

    if (userList) {
      alert(`Users who ${listType === 'done' ? 'have done' : 'wishlist'} trek "${getTrekName(trekId)}":\n\n${userList}`);
    } else {
      alert(`No users found who ${listType === 'done' ? 'have done' : 'wishlist'} trek "${getTrekName(trekId)}"`);
    }
  };


  return (
    <div className="dashboard-root">
      <div className="dashboard-container">
        {/* Top Navigation */}
        <div className="top-nav">
          <div className="nav-links">
            <button className="nav-link active" onClick={() => navigate('/dashboard')}>Users</button>
            <button className="nav-link" onClick={() => navigate('/dashboard/add-product')}>Products</button>
            <button className="nav-link" onClick={() => navigate('/dashboard/alerts')}>Alerts</button>
            <button className="nav-link" onClick={() => navigate('/dashboard/workshop')}>Payments</button>
          </div>
          <button className="back-button" onClick={() => navigate('/products')}>Back</button>
        </div>

        <div className="dashboard-content">
          {/* Tab Buttons and Add User */}
          <div className="tab-container">
            <div className="tab-buttons">
              <button
                className={`tab-button ${activeTab === 'all' ? 'active' : ''}`}
                onClick={() => setActiveTab('all')}
              >
                All Users ({users.length})
              </button>
              <button
                className={`tab-button ${activeTab === 'members' ? 'active' : ''}`}
                onClick={() => setActiveTab('members')}
              >
                Members ({users.filter(u => u.isMember).length})
              </button>
              <button
                className={`tab-button ${activeTab === 'nonMembers' ? 'active' : ''}`}
                onClick={() => setActiveTab('nonMembers')}
              >
                Non-Members ({users.filter(u => !u.isMember).length})
              </button>
              <button
                className={`tab-button ${activeTab === 'auditLog' ? 'active' : ''}`}
                onClick={() => setActiveTab('auditLog')}
              >
                Audit Logs
              </button>
              <button
                className={`tab-button ${activeTab === 'treks' ? 'active' : ''}`}
                onClick={() => setActiveTab('treks')}
              >
                Treks ({treks.length})
              </button>
            </div>
            {activeTab !== 'auditLog' && activeTab !== 'treks' && (
              <button
                className="add-user-button"
                onClick={() => setShowNewUserForm(!showNewUserForm)}
              >
                + Add User
              </button>
            )}
            {activeTab === 'treks' && (
              <button
                className="add-user-button add-trek-button"
                onClick={() => setShowNewTrekForm(!showNewTrekForm)}
              >
                + Add Trek
              </button>
            )}
          </div>

          {/* Search Bar */}
          <div className="search-container">
            <input
              type="text"
              className="search-input"
              placeholder={
                activeTab === 'auditLog' ? "Search logs by user name, email or action..." :
                  activeTab === 'treks' ? "Search treks by name or state..." :
                    "Search users by email or name..."
              }
              value={
                activeTab === 'auditLog' ? logSearchQuery :
                  activeTab === 'treks' ? trekSearchQuery : searchQuery
              }
              onChange={(e) => {
                if (activeTab === 'auditLog') setLogSearchQuery(e.target.value);
                else if (activeTab === 'treks') setTrekSearchQuery(e.target.value);
                else setSearchQuery(e.target.value);
              }}
            />
          </div>

          {/* Messages */}
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}

          {/* New User Form */}
          {showNewUserForm && activeTab !== 'auditLog' && activeTab !== 'treks' && (
            <div className="new-user-form">
              <form onSubmit={handleCreateUser}>
                <div className="form-grid">
                  <div className="form-group">
                    <label>Email*</label>
                    <input
                      type="email"
                      value={newUser.email}
                      onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Full Name*</label>
                    <input
                      type="text"
                      value={newUser.fullName}
                      onChange={(e) => setNewUser({ ...newUser, fullName: e.target.value })}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={newUser.isMember}
                        onChange={(e) => setNewUser({ ...newUser, isMember: e.target.checked })}
                      />
                      Member
                    </label>
                  </div>
                </div>
                <div className="form-actions">
                  <button type="submit" className="create-button">Create User</button>
                </div>
              </form>
            </div>
          )}

          {/* New Trek Form */}
          {showNewTrekForm && activeTab === 'treks' && (
            <div className="new-trek-form">
              <form onSubmit={handleCreateTrek}>
                <div className="form-grid">
                  <div className="form-group">
                    <label>Trek Name*</label>
                    <input
                      type="text"
                      value={newTrek.trek_name}
                      onChange={(e) => setNewTrek({ ...newTrek, trek_name: e.target.value })}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>State</label>
                    <input
                      type="text"
                      value={newTrek.state}
                      onChange={(e) => setNewTrek({ ...newTrek, state: e.target.value })}
                    />
                  </div>
                </div>
                <div className="form-actions">
                  <button type="submit" className="create-button">Create Trek</button>
                </div>
              </form>
            </div>
          )}

          {/* Users Table */}
          {activeTab !== 'auditLog' && activeTab !== 'treks' && (
            loading ? (
              <div className="loading">Loading...</div>
            ) : (
              <div className="table-wrapper">
                <div className="table-container">
                  <table className="users-table">
                    <thead>
                      <tr>
                        <th>
                          <div className={`sortable-header ${sortConfig.key === 'FullName' ? 'active' : ''}`} onClick={() => handleSort('FullName')}>
                            Name
                            <span className="sort-icon"><SortIcon columnKey="FullName" /></span>
                          </div>
                        </th>
                        <th>ID</th>
                        <th>
                          <div className={`sortable-header ${sortConfig.key === 'Email' ? 'active' : ''}`} onClick={() => handleSort('Email')}>
                            Email
                            <span className="sort-icon"><SortIcon columnKey="Email" /></span>
                          </div>
                        </th>
                        <th>
                          <div className={`sortable-header ${sortConfig.key === 'WhatsAppNo' ? 'active' : ''}`} onClick={() => handleSort('WhatsAppNo')}>
                            WhatsApp
                            <span className="sort-icon"><SortIcon columnKey="WhatsAppNo" /></span>
                          </div>
                        </th>
                        <th>
                          <div className={`sortable-header ${sortConfig.key === 'CreatedOn' ? 'active' : ''}`} onClick={() => handleSort('CreatedOn')}>
                            Created On
                            <span className="sort-icon"><SortIcon columnKey="CreatedOn" /></span>
                          </div>
                        </th>
                        <th>EC-1</th>
                        <th>EC-2</th>
                        <th>
                          <div className={`sortable-header ${sortConfig.key === 'isProfileComplete' ? 'active' : ''}`} onClick={() => handleSort('isProfileComplete')}>
                            Profile
                            <span className="sort-icon"><SortIcon columnKey="isProfileComplete" /></span>
                          </div>
                        </th>
                        <th>
                          <div className={`sortable-header ${sortConfig.key === 'isDirectoryVisible' ? 'active' : ''}`} onClick={() => handleSort('isDirectoryVisible')}>
                            Visibility
                            <span className="sort-icon"><SortIcon columnKey="isDirectoryVisible" /></span>
                          </div>
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUsers().map((user) => (
                        <tr key={user.id}>
                          <td>{user.FullName || 'N/A'}</td>
                          <td>
                            <button className="copy-button" onClick={() => copyToClipboard(user.id)}>
                              <Copy size={16} />
                            </button>
                          </td>
                          <td>{user.Email}</td>
                          <td>{user.WhatsAppNo || 'N/A'}</td>
                          <td>{formatTimestamp(user.CreatedOn)}</td>
                          <td>
                            {user.EmergencyContact1Name ? (
                              <>
                                <div>{user.EmergencyContact1Name}</div>
                                <small>{user.EmergencyContact1CountryCode} {user.EmergencyContact1}</small>
                              </>
                            ) : 'N/A'}
                          </td>
                          <td>
                            {user.EmergencyContact2Name ? (
                              <>
                                <div>{user.EmergencyContact2Name}</div>
                                <small>{user.EmergencyContact2CountryCode} {user.EmergencyContact2}</small>
                              </>
                            ) : 'N/A'}
                          </td>
                          <td>
                            <span className={`status-badge ${user.isProfileComplete ? 'status-active' : 'status-inactive'}`}>
                              {user.isProfileComplete ? 'Complete' : 'Incomplete'}
                            </span>
                          </td>
                          <td>
                            <span className={`status-badge ${user.isDirectoryVisible ? 'status-active' : 'status-inactive'}`}>
                              {user.isDirectoryVisible ? 'Visible' : 'Hidden'}
                            </span>
                          </td>
                          <td>
                            <div className="action-buttons">
                              <button
                                className="action-button edit"
                                onClick={() => navigate(`/dashboard/edit-user/${user.id}`)}
                              >
                                Edit
                              </button>
                              <button
                                className={`action-button ${user.isDirectoryVisible ? 'revoke' : 'grant'}`}
                                onClick={() => toggleMemberDirectoryVisible(user.id, user.isDirectoryVisible)}
                              >
                                {user.isDirectoryVisible ? 'Revoke Visiblity' : 'Make Visible'}
                              </button>
                              <button
                                className={`action-button ${user.workshopAccess ? 'revoke' : 'grant'}`}
                                onClick={() => toggleWorkshopAccess(user.id, user.workshopAccess)}
                              >
                                {user.workshopAccess ? 'Revoke Workshop' : 'Grant Workshop'}
                              </button>
                              <button
                                className={`action-button ${user.isMember ? 'revoke' : 'grant'}`}
                                onClick={() => toggleMemberAccess(user.id, user.isMember)}
                              >
                                {user.isMember ? 'Revoke Member' : 'Grant Member'}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {filteredUsers().length === 0 && !loading && (
                    <div className="no-results">No users found matching your criteria</div>
                  )}
                </div>
              </div>
            )
          )}

          {/* Audit Logs Table */}
          {activeTab === 'auditLog' && (
            logsLoading ? (
              <div className="loading">Loading audit logs...</div>
            ) : (
              <div className="table-wrapper">
                <div className="table-container">
                  <table className="logs-table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <div className={`sortable-header ${logSortConfig.key === 'timestamp' ? 'active' : ''}`} onClick={() => handleLogSort('timestamp')}>
                            Date & Time
                            <span className="sort-icon"><LogSortIcon columnKey="timestamp" /></span>
                          </div>
                        </th>
                        <th>Affected User</th>
                        <th>
                          <div className={`sortable-header ${logSortConfig.key === 'action' ? 'active' : ''}`} onClick={() => handleLogSort('action')}>
                            Edited By
                            <span className="sort-icon"><LogSortIcon columnKey="action" /></span>
                          </div>
                        </th>
                        <th>Changes</th>
                        <th>UserID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredLogs().map((log) => (
                        <React.Fragment key={log.id}>
                          <tr className={expandedLogId === log.id ? 'expanded-row' : ''}>
                            <td>
                              <button
                                className="expand-button"
                                onClick={() => toggleLogExpansion(log.id)}
                              >
                                {expandedLogId === log.id ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
                              </button>
                            </td>
                            <td>{formatTimestamp(log.timestamp)}</td>
                            <td>{log.userName ? `${log.userName} (${log.userEmail})` : log.userEmail || 'N/A'}</td>
                            <td>{log.action === 'profile_update' ? 'Profile Update' : log.action}</td>
                            <td>{getChangeSummary(log)}</td>
                            <td>
                              <button className="copy-button" onClick={() => copyToClipboard(log.userId)}>
                                <Copy size={16} />
                              </button>
                            </td>
                          </tr>
                          {expandedLogId === log.id && (
                            <tr className="expanded-details-row">
                              <td colSpan="7">
                                <div className="expanded-details">
                                  {renderChangedFieldsDetails(log)}
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                  {filteredLogs().length === 0 && !logsLoading && (
                    <div className="no-results">No audit logs found matching your criteria</div>
                  )}
                </div>
              </div>
            )
          )}

          {/* Treks Table */}
          {activeTab === 'treks' && (
            treksLoading ? (
              <div className="loading">Loading treks...</div>
            ) : (
              <div className="table-wrapper">
                <div className="table-container">
                  <table className="treks-table">
                    <thead>
                      <tr>
                        <th>
                          <div className={`sortable-header ${trekSortConfig.key === 'trek_name' ? 'active' : ''}`} onClick={() => handleTrekSort('trek_name')}>
                            Trek Name
                            <span className="sort-icon"><TrekSortIcon columnKey="trek_name" /></span>
                          </div>
                        </th>
                        <th>State</th>
                        <th>Added By</th>
                        <th>
                          <div className={`sortable-header ${trekSortConfig.key === 'added_on' ? 'active' : ''}`} onClick={() => handleTrekSort('added_on')}>
                            Added On
                            <span className="sort-icon"><TrekSortIcon columnKey="added_on" /></span>
                          </div>
                        </th>
                        <th>Users Done</th>
                        <th>Users Wishlist</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTreks().map((trek) => {
                        const { doneCount, wishlistCount } = getTrekCounts(trek.id);
                        return (
                          <tr key={trek.id}>
                            <td>
                              {editingTrekId === trek.id ? (
                                <form onSubmit={handleUpdateTrekName}>
                                  <input
                                    type="text"
                                    value={editedTrekName}
                                    onChange={(e) => setEditedTrekName(e.target.value)}
                                    placeholder="Trek Name"
                                    required
                                  />
                                  <input
                                    type="text"
                                    value={editedTrekState}
                                    onChange={(e) => setEditedTrekState(e.target.value)}
                                    placeholder="State"
                                    style={{ marginTop: '5px' }}
                                  />
                                  <button type="submit">Update</button>
                                  <button type="button" onClick={() => setEditingTrekId(null)}>Cancel</button>
                                </form>
                              ) : (
                                `${trek.trek_name}`
                              )}
                            </td>
                            <td>{trek.state || 'N/A'}</td>
                            <td>{trek.added_by || 'N/A'}</td>
                            <td>{formatTimestamp(trek.added_on)}</td>
                            <td>
                              <button className="count-link" onClick={() => showUsersForTrek(trek.id, 'done')}>{doneCount}</button>
                            </td>
                            <td>
                              <button className="count-link" onClick={() => showUsersForTrek(trek.id, 'wishlist')}>{wishlistCount}</button>
                            </td>
                            <td>
                              <div className="action-buttons">
                                {editingTrekId !== trek.id && (
                                  <button
                                    className="action-button edit"
                                    style={{ backgroundColor: '#4a90e2', color: 'white' }} // Inline styles for Edit button
                                    onClick={() => { setEditingTrekId(trek.id); setEditedTrekName(trek.trek_name); setEditedTrekState(trek.state); }}
                                  >
                                    <Edit size={16} />
                                  </button>
                                )}
                                <button
                                  className="action-button delete"
                                  style={{ backgroundColor: '#f44336', color: 'white' }} // Inline styles for Delete button
                                  onClick={() => handleDeleteTrek(trek.id)}
                                >
                                  <Trash2 size={16} />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {filteredTreks().length === 0 && !treksLoading && (
                    <div className="no-results">No treks found matching your criteria</div>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;